import React, { useState } from "react";
import "../PNR/PnrSorguContainer.css";
import { Button, notification } from "antd";
import { Ticket } from "../../services/Ticket";

const PnrSorguContainer = ({
  ticketData,
  setTicketData,
  pnrIsChecking, setPnrIsChecking,
  pnrInput, setPnrInput
}) => {
  const [ePosta, setePosta] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const checkPnr = () => {
    setPnrIsChecking(true)
    if (!pnrInput.trim() || !ePosta.trim()) {
      notification.error({ message: 'Hata', description: 'Lütfen boş alanları doldurun!' })
      setPnrIsChecking(false)
      setTicketData([])
      return;
    }

    Ticket().check({
      email: ePosta,
      pnr_no: pnrInput
    }).then(({ data }) => {
      if (data?.statusCode == 200) {
        setTicketData(data?.data)
      } else {
        if (data?.message) {
          notification.error({ message: 'Hata', description: data?.message })
          setTicketData([])
        } else {
          notification.error({ message: 'Hata', description: 'PNR sorgulanırken bir hata oluştu. Lütfen daha sonra tekrar deneyin!' })
          setTicketData([])
        }
      }
      setPnrIsChecking(false)
    }).catch(error => {
      console.log("hata", error)
      setErrorMsg(error.response.data.message)
      setPnrIsChecking(false)
      setTicketData([])
    })
  };

  const handlePnrChange = (event) => {
    setPnrInput(event.target.value);
  };

  const handleEpostaChange = (event) => {
    setePosta(event.target.value);
  };

  return (
    <>
      <div className="pnr-container">
        <input
          type="text"
          placeholder="PNR"
          value={pnrInput}
          onChange={handlePnrChange}
        />
        <input
          type="text"
          placeholder="E-Posta"
          value={ePosta}
          onChange={handleEpostaChange}
        />
        <Button loading={pnrIsChecking} className="pnr-sorgula-btn" onClick={checkPnr} disabled={pnrIsChecking}>
          <span>{pnrIsChecking ? 'Sorgulanıyor' : 'PNR Sorgula'}</span>
        </Button>
      </div>
    </>
  );
};

export default PnrSorguContainer;

const ticketDataRaw = {
  "passengerTicket": [
    {
      "passengerTicketId": 11,
      "isDeleted": false,
      "citizenshipID": "15986395286",
      "customerID": 2311430,
      "id": 3699290,
      "expeditionID": 471846,
      "salesID": 1297847,
      "serviceDate": "2024-07-29T00:00:00",
      "salesDate": "2024-07-23T00:00:00",
      "salesAmount": 32.50,
      "totalSalesAmount": 32.50,
      "totalSalesAmountTL": 32.5000,
      "commissionAmount": 0.00,
      "currencyID": 1071805,
      "currencyTypeID": 2,
      "portTax": 0.00,
      "salesItemID": 0,
      "arrivalTime": "10:15:00",
      "departureTime": "08:25:00",
      "ferryName": "SEA STAR LİNDOS",
      "ferryCompanyName": "Tilos Travel\t",
      "ferryID": 106,
      "routeID": 292,
      "departureRegionName": "FETHİYE - LIMAN",
      "arrivalRegionName": "RODOS - TOURISTIKO PORT",
      "name": "TURKIZ MELIH",
      "passportNo": "",
      "surName": "OZSECGIN",
      "gsm": "05437317318",
      "openWay": false,
      "currencyTypeCode": "EUR",
      "currencyTypeSymbol": "€",
      "returnTicketID": 3699291,
      "birthDate": "2002-03-04T00:00:00",
      "routeType": 2,
      "nationality": "TURKIYE",
      "passengerTypeID": 1,
      "passengerTypeName": "Adult",
      "departureRegionID": 300,
      "arrivalRegionID": 965,
      "countryName": "TURKIYE",
      "passportExpireDate": null,
      "countryID": 1,
      "gender": 0,
      "genderName": "Erkek",
      "visaTypeID": 0,
      "visaExpireDate": null,
      "visaBeginDate": null,
      "visaClassName": null,
      "taxAmount": 0,
      "termAmount": 0,
      "isBranchesCanChange": false
    },
    {
      "passengerTicketId": 12,
      "isDeleted": false,
      "citizenshipID": "15986395286",
      "customerID": 2311430,
      "id": 3699291,
      "expeditionID": 472669,
      "salesID": 1297847,
      "serviceDate": "2024-07-29T00:00:00",
      "salesDate": "2024-07-23T00:00:00",
      "salesAmount": 32.50,
      "totalSalesAmount": 32.50,
      "totalSalesAmountTL": 32.5000,
      "commissionAmount": 0.00,
      "currencyID": 1071805,
      "currencyTypeID": 2,
      "portTax": 0.00,
      "salesItemID": 0,
      "arrivalTime": "18:20:00",
      "departureTime": "16:30:00",
      "ferryName": "SEA STAR LİNDOS",
      "ferryCompanyName": "Tilos Travel\t",
      "ferryID": 106,
      "routeID": 293,
      "departureRegionName": "RODOS - TOURISTIKO PORT",
      "arrivalRegionName": "FETHİYE - LIMAN",
      "name": "TURKIZ MELIH",
      "passportNo": "",
      "surName": "OZSECGIN",
      "gsm": "05437317318",
      "openWay": false,
      "currencyTypeCode": "EUR",
      "currencyTypeSymbol": "€",
      "returnTicketID": 3699290,
      "birthDate": "2002-03-04T00:00:00",
      "routeType": 2,
      "nationality": "TURKIYE",
      "passengerTypeID": 1,
      "passengerTypeName": "Adult",
      "departureRegionID": 965,
      "arrivalRegionID": 300,
      "countryName": "TURKIYE",
      "passportExpireDate": null,
      "countryID": 1,
      "gender": 0,
      "genderName": "Erkek",
      "visaTypeID": 0,
      "visaExpireDate": null,
      "visaBeginDate": null,
      "visaClassName": null,
      "taxAmount": 0,
      "termAmount": 0,
      "isBranchesCanChange": false
    }
  ],
  "vehicleTicket": [],
  "extraService": [],
  "hotel": [],
  "salesCurrency": [
    {
      "currencyID": 1066315,
      "name": "Türk Lirası",
      "symbol": "₺",
      "code": "TRL",
      "typeID": 1,
      "amount": 0,
      "currencyAmount": 1.00
    },
    {
      "currencyID": 1071805,
      "name": "Euro",
      "symbol": "€",
      "code": "EUR",
      "typeID": 2,
      "amount": 0,
      "currencyAmount": 35.93
    },
    {
      "currencyID": 1071804,
      "name": "Dolar",
      "symbol": "$",
      "code": "USD",
      "typeID": 3,
      "amount": 0,
      "currencyAmount": 33.12
    },
    {
      "currencyID": 1071806,
      "name": "Pound",
      "symbol": "£",
      "code": "GBP",
      "typeID": 4,
      "amount": 0,
      "currencyAmount": 42.68
    }
  ],
  "salesSource": {
    "companyID": 0,
    "id": 0,
    "status": 0,
    "utmCampaing": null,
    "utmMedium": null,
    "utmSource": null,
    "utmTerm": null,
    "utmContent": null,
    "urlReferrer": null,
    "salesID": 0
  },
  "id": 1297847,
  "pnrNo": "GL5J3E6JUH",
  "salesDate": "2024-07-23T20:39:58.37",
  "totalAmount": 0.00,
  "totalAmountTL": 2335.4500,
  "totalCommisonAmount": 0.00,
  "totalRemainingAmount": 0.00,
  "branchID": 7031,
  "step": 1,
  "userID": 7146,
  "userName": "uygunferibot api",
  "branchName": "uygunferibot.com / Emre Fırtına",
  "reservationStatus": 1,
  "forthCrsReservation": {
    "forthCrsReserveIssueRequest": [],
    "contact": null,
    "langCode": null
  },
  "note": null,
  "guid": "14512669-a63e-44a6-82df-dc645f01fc34"
}
