import { Button, Space, Table, Tag, Form, Input, notification, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { Reservations } from '../../../services/Reservations';
import { getItem } from '../../../utils/storage';
import dayjs from 'dayjs';
import { genderToTR } from '../../../utils/gender';
import { Dropdown, Flex } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons'
import { IoEyeSharp } from "react-icons/io5";
import { IoPencilSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { Divider } from "antd";
import { HeaderLayout } from '../HeaderLayout';

export const Passengers = () => {
    const [dataSource, setDataSource] = useState([]);
    const [TourmodalOpen, setTourModalOpen] = useState(false);
    const [DeletemodalOpen, setDeleteModalOpen] = useState(false);
    const [UpdatemodalOpen, setUpdateModalOpen] = useState(false);
    const [ShowmodalOpen, setShowModalOpen] = useState(false);
    const [PassengerSearchmodalOpen, setPassengerSearchModalOpen] =
        useState(false);

    const [from, setFrom] = useState("BODRUM");
    const [to, setTo] = useState("RODOS");
    const [varisLimanlari] = useState({
        FETHIYE: ["RODOS"],
        BODRUM: ["RODOS", "KOS", "SYMI", "PATMOS"],
        KUSADASI: ["SAMOS", "PATMOS"],
        CESME: ["CHIOS-SAKIZ"],
        AYVALIK: ["MİDİLLİ"],
        KAS: ["MEIS"],
        KOS: ["BODRUM"],
        CHIOS_SAKIZ: ["ÇEŞME"],
        MIDILLI: ["AYVALIK"],
        MEIS: ["KAS"],
        SAMOS: ["KUŞADASI"],
        SYMI: ["BODRUM"],
        PATMOS: ["BODRUM", "KUŞADASI"],
        RODOS: ["BODRUM", "FETHiYE"],
        SEFERIHISAR: ["SAMOS"],
        // KALKISsetSelectedGuestId LİMANLARİ
    });

    const [GidisTarihi, setGidisTarihi] = useState("");
    const [DonusTarihi, setDonusTarihi] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("male");
    const [phone, setPhone] = useState("");
    const [nationality, setNationality] = useState("Turkey");
    const [nationalId, setNationalId] = useState("");
    const [passportExpiryDate, setPassportExpiryDate] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [pnrNo, setPnrNo] = useState("");
    const [selectedGuestId, setSelectedGuestId] = useState()
    const [donusTarihiAktif, setDonusTarihiAktif] = useState(true);

    const [updateModalForm, setUpdateModalForm] = useState({})

    useEffect(() => {
        // Günün tarihini al
        const bugun = new Date().toISOString().split("T")[0];
        setGidisTarihi(bugun);
        if (donusTarihiAktif) {
            setDonusTarihi(bugun);
        } else {
            setDonusTarihi("");
        }
    }, [donusTarihiAktif]);

    const PassengerSearchOpen = () => {
        setPassengerSearchModalOpen(true);
    };
    const PassengerSearchOff = () => {
        setPassengerSearchModalOpen(false);
    };

    const TourModalOpen = () => {
        setTourModalOpen(true);
        setName();
        setSurname();
        setEmail();
        setPhone();
        setNationality("Turkey");
        setNationalId();
        setPassportExpiryDate();
        setBirthDate();
        setPnrNo()
        setSelectedGuestId()
    };

    const TourModalOff = () => setTourModalOpen(false)

    const DeleteModalOpen = () => {
        setDeleteModalOpen(true);
    };
    const DeleteModalOff = () => {
        setDeleteModalOpen(false);
    };

    const UpdateModalOpen = (record) => {
        setUpdateModalOpen(true);
        setName(record.name);
        setSurname(record.surname);
        setEmail(record.email);
        setPhone(record.phone);
        setNationality(record.nationality);
        setNationalId(record.national_id);
        setPassportExpiryDate(record.passport_expiry_date);
        setBirthDate(record.birth_date);
        setPnrNo(record.pnr_no)
        setSelectedGuestId(record.id)
    };
    const UpdateModalOff = () => {
        setUpdateModalOpen(false);
    };

    const ShowModalOpen = () => {
        setShowModalOpen(true);
    };
    const ShowModalOff = () => {
        setShowModalOpen(false);
    };

    const fetchData = () => {
        Reservations().guests({
            token: userData.token
        }).then((response) => {
            const { data } = response;
            if (data?.status == "success") {
                const guests = data.data;
                setDataSource(guests)
                console.log("reservations", data);
            } else {
                console.log("hata", data?.message);
            }
        }).catch((error) => {
            console.log("err", error);
        });
    }

    const userData = getItem("adminUser")
    useEffect(() => {
        fetchData()
    }, [])

    const deleteGuest = (userId) => {
        Modal.confirm({
            title: userId + ' - Silmek istediğinize emin misiniz?',
            footer: (_, { OkBtn, CancelBtn }) => (
                <>
                    <OkBtn />
                    <CancelBtn />
                </>
            ),
            okText: 'Sil',
            onOk: () => {
                Reservations().deleteGuest(userId).then((res) => {
                    console.log("res", res.data)
                    if (res.data.status == "success") {
                        notification.success({
                            message: 'Başarılı',
                            description: res.data?.message,
                            status: 'success'
                        })
                        fetchData()
                    } else {
                        notification.error({
                            message: 'Hata',
                            description: 'Hata oluştu',
                            status: 'error'
                        })
                    }
                }).catch(error => {
                    notification.error({
                        message: 'Hata',
                        description: error?.response?.data?.message,
                        status: 'error'
                    })
                    console.log("hata", error)
                })
            },
            cancelText: 'İptal',
        })
        console.log("userId", userId);

    }

    const editGuest = () => {
        const guestData = {
            name,
            surname,
            email,
            phone,
            gender,
            national_id: nationalId,
            nationality,
            passport_expiry_date: passportExpiryDate,
            birth_date: birthDate,
            pnr_no: pnrNo
        }
        Reservations().updateGuest(selectedGuestId, guestData).then((res) => {
            if (res.data.status == "success") {
                notification.success({
                    message: 'Başarılı',
                    description: 'Yolcu bilgileri kaydedildi',
                    status: 'success'
                })
                UpdateModalOff()
                fetchData()
            } else {
                notification.error({
                    message: 'Hata',
                    description: 'Hata oluştu',
                    status: 'error'
                })
            }
        }).catch((error) => {
            console.log("sldkfj", error.response.data)
            notification.error({
                message: 'Hata',
                description: error?.response?.data?.message,
                status: 'error'
            })
        })
    }

    const saveGuest = () => {
        const guestData = {
            name,
            surname,
            email,
            phone,
            gender,
            national_id: nationalId,
            nationality,
            passport_expiry_date: passportExpiryDate,
            birth_date: birthDate,
            pnr_no: pnrNo
        }
        Reservations().createGuest(guestData).then((res) => {
            if (res.data.status == "success") {
                notification.success({
                    message: 'Başarılı',
                    description: 'Yolcu eklendi',
                    status: 'success'
                })
                TourModalOff()
                fetchData()
            } else {
                notification.error({
                    message: 'Hata',
                    description: 'Hata oluştu',
                    status: 'error'
                })
            }
        }).catch((error) => {
            console.log("sldkfj", error.response.data)
            notification.error({
                message: 'Hata',
                description: error?.response?.data?.message,
                status: 'error'
            })
        })
    }

    // <th>ID</th>

    // <th>NEREDEN</th>
    // <th>NEREYE</th>
    // <th>İşlemler</th>

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'İsim',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Soyisim',
            dataIndex: 'surname',
            key: 'surname',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Telefon',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Uyruk',
            dataIndex: 'nationality',
            key: 'nationality',
        },
        {
            title: 'Pasaport',
            dataIndex: 'national_id',
            key: 'national_id',
        },
        {
            title: 'Yaş',
            dataIndex: 'age',
            key: 'age',
            sorter: (a, b) => a.age - b.age,
            render: (text, record, index) => {
                const birthday = dayjs(record.birth_date);
                const today = dayjs()

                const age = today.diff(birthday, 'year');
                console.log("record", record)
                return (
                    <>{age}</>
                )
            }
        },
        {
            title: 'Cinsiyet',
            dataIndex: 'gender',
            key: 'gender',
            sorter: true,
            render: (text, record, index) => {
                const gender = genderToTR[record.gender]
                return (
                    <>{gender}</>
                )
            }
        },
        {
            title: 'PNR',
            dataIndex: 'pnr_no',
            key: 'pnr_no',
        },
        {
            title: 'İşlemler',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record, index) => {
                return (
                    <Flex align="flex-start" gap="small">
                        <Button type='primary' ghost icon={<EyeOutlined />}></Button>
                        <Button onClick={() => {
                            setUpdateModalForm(record)
                            UpdateModalOpen(record)
                        }} icon={<EditOutlined />}></Button>
                        <Button danger icon={<DeleteOutlined />} onClick={() => deleteGuest(record.id)}></Button>
                    </Flex>
                )
            }
        },

    ];

    return (
        <>
            <HeaderLayout
                title='Yolcular'
                Table={<Table dataSource={dataSource} columns={columns} />}
                onClick={TourModalOpen}
                btnTitle='Yolcu Ekle'
            />

            {/* Add Guest */}
            {TourmodalOpen && (
                <div className="show-modal">
                    <div className="show-modal-content">
                        <span className="close" onClick={TourModalOff}>
                            &times;
                        </span>
                        <p>Yolcu Bilgileri</p>
                        <div className="update-modal-location" style={{ display: 'inline-block', width: '50%' }}>
                            <label>Ad:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="update-modal-location" style={{ display: 'inline-block', width: '50%' }}>
                            <label>Soyad:</label>
                            <input
                                type="text"
                                id="surname"
                                name="surname"
                                value={surname}
                                onChange={(e) => setSurname(e.target.value)}
                            />
                        </div>
                        <div className="update-modal-location" style={{ display: 'inline-block', width: '50%' }}>
                            <label>Email:</label>
                            <input
                                type="text"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="update-modal-location" style={{ display: 'inline-block', width: '50%' }}>
                            <label>Pnr No:</label>
                            <input
                                type="text"
                                id="pnrNo"
                                name="pnrNo"
                                value={pnrNo}
                                onChange={(e) => setPnrNo(e.target.value)}
                            />
                        </div>
                        <div className='update-modal-location' style={{ display: 'inline-block', width: '50%' }}>
                            <label htmlFor="">Cinsiyet</label>
                            <select
                                className="select-way"
                                id="gender"
                                name="gender"
                                value={gender}
                                onChange={(e) => {
                                    setGender(e.target.value);
                                }}
                            >
                                <option value="male">Erkek</option>
                                <option value="female">Kadın</option>
                            </select >
                        </div>
                        <div className="update-modal-location" style={{ display: 'inline-block', width: '50%' }}>
                            <label>Uyruk:</label>
                            <select
                                className="select-way"
                                id="nationality"
                                name="nationality"
                                value={nationality}
                                onChange={(e) => {
                                    setNationality(e.target.value);
                                }}
                            >
                                <option value="Turkey">Türkiye</option>
                                <option value="Diğer">Diğer</option>
                            </select >
                        </div>
                        <div className="update-modal-location" style={{ display: 'inline-block', width: '50%' }}>
                            <label>Pasaport:</label>
                            <input
                                type="text"
                                id="nationalId"
                                name="nationalId"
                                value={nationalId}
                                onChange={(e) => setNationalId(e.target.value)}
                            />
                        </div>
                        <div className="update-modal-location" style={{ display: 'inline-block', width: '50%' }}>
                            <label>Pasaport bitiş tarihi:</label>
                            <input
                                type="date"
                                id="passportExpiryDate"
                                name="passportExpiryDate"
                                value={passportExpiryDate}
                                onChange={(e) => setPassportExpiryDate(e.target.value)}
                            />
                        </div>
                        <div className="update-modal-location" style={{ display: 'inline-block', width: '50%' }}>
                            <label>Doğum tarihi:</label>
                            <input
                                type="date"
                                id="birthDate"
                                name="birthDate"
                                value={birthDate}
                                onChange={(e) => setBirthDate(e.target.value)}
                            />
                        </div>
                        <div className="update-modal-location" style={{ display: 'inline-block', width: '50%' }}>
                            <label>Telefon:</label>
                            <input
                                type="text"
                                id="phone"
                                name="phone"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                        <Divider />
                        <Button onClick={saveGuest} size='large' type='primary'>Ekle</Button>
                    </div>
                </div>
            )}

            {/* Show Modal */}
            {ShowmodalOpen && (
                <div className="show-modal">
                    <div className="show-modal-content">
                        <span className="close" onClick={ShowModalOff}>
                            &times;
                        </span>
                        <p>Sefer Bilgileri</p>
                        <div className="modal-sub-title">
                            <h1>PNR: </h1>
                            <h1>lPJC9G9OFX6w</h1>
                        </div>
                        <div className="round-trip-cont">
                            <p className="round-trip-item">Ad:</p>
                            <p className="round-trip-item">Soyad:</p>
                        </div>
                        <div className="round-trip-cont">
                            <p className="round-trip-item">Tckn:</p>
                        </div>
                        <div className="round-trip-cont">
                            <p className="round-trip-item">Doğum Tarihi:</p>
                            <p className="round-trip-item">Uyruk:</p>
                        </div>
                        <div className="round-trip-cont">
                            <p className="round-trip-item">Nerden:</p>
                            <p className="round-trip-item">Nereye:</p>
                        </div>
                        <div className="round-trip-cont">
                            <p className="round-trip-item">Gidiş Tarihi:</p>
                            <p className="round-trip-item">Dönüş Tarihi:</p>
                        </div>
                        <div className="round-trip-cont">
                            <p className="round-trip-item">Tek Yön:</p>
                            <p className="round-trip-item">Gidiş Dönüş:</p>
                        </div>
                        <div className="round-trip-cont">
                            <p className="round-trip-item">Doğum Tarihi:</p>
                        </div>
                        <div className="round-trip-cont">
                            <p className="round-trip-item">Pasaport No::</p>
                            <p className="round-trip-item">Pasaport Bitiş:</p>
                        </div>
                        <Divider />
                    </div>
                </div>
            )}

            {/* Update Modal */}
            {UpdatemodalOpen && (
                <div className="update-modal">
                    <div className="update-modal-content">
                        <span className="close" onClick={UpdateModalOff}>
                            &times;
                        </span>
                        <p>Yolcu Düzenle</p>
                        <div className="update-modal-location" style={{ display: 'inline-block', width: '50%' }}>
                            <label>Ad:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="update-modal-location" style={{ display: 'inline-block', width: '50%' }}>
                            <label>Soyad:</label>
                            <input
                                type="text"
                                id="surname"
                                name="surname"
                                value={surname}
                                onChange={(e) => setSurname(e.target.value)}
                            />
                        </div>
                        <div className="update-modal-location" style={{ display: 'inline-block', width: '50%' }}>
                            <label>Email:</label>
                            <input
                                type="text"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="update-modal-location" style={{ display: 'inline-block', width: '50%' }}>
                            <label>Pnr No:</label>
                            <input
                                type="text"
                                id="pnrNo"
                                name="pnrNo"
                                value={pnrNo}
                                onChange={(e) => setPnrNo(e.target.value)}
                            />
                        </div>
                        <div className='update-modal-location' style={{ display: 'inline-block', width: '50%' }}>
                            <label htmlFor="">Cinsiyet</label>
                            <select
                                className="select-way"
                                id="gender"
                                name="gender"
                                value={gender}
                                onChange={(e) => {
                                    setGender(e.target.value);
                                }}
                            >
                                <option value="male">Erkek</option>
                                <option value="female">Kadın</option>
                            </select >
                        </div>
                        <div className="update-modal-location" style={{ display: 'inline-block', width: '50%' }}>
                            <label>Uyruk:</label>
                            <select
                                className="select-way"
                                id="nationality"
                                name="nationality"
                                value={nationality}
                                onChange={(e) => {
                                    setNationality(e.target.value);
                                }}
                            >
                                <option value="Turkey">Türkiye</option>
                                <option value="Diğer">Diğer</option>
                            </select >
                        </div>
                        <div className="update-modal-location" style={{ display: 'inline-block', width: '50%' }}>
                            <label>Pasaport:</label>
                            <input
                                type="text"
                                id="nationalId"
                                name="nationalId"
                                value={nationalId}
                                onChange={(e) => setNationalId(e.target.value)}
                            />
                        </div>
                        <div className="update-modal-location" style={{ display: 'inline-block', width: '50%' }}>
                            <label>Pasaport bitiş tarihi:</label>
                            <input
                                type="date"
                                id="passportExpiryDate"
                                name="passportExpiryDate"
                                value={passportExpiryDate}
                                onChange={(e) => setPassportExpiryDate(e.target.value)}
                            />
                        </div>
                        <div className="update-modal-location" style={{ display: 'inline-block', width: '50%' }}>
                            <label>Doğum tarihi:</label>
                            <input
                                type="date"
                                id="birthDate"
                                name="birthDate"
                                value={birthDate}
                                onChange={(e) => setBirthDate(e.target.value)}
                            />
                        </div>
                        <div className="update-modal-location" style={{ display: 'inline-block', width: '50%' }}>
                            <label>Telefon:</label>
                            <input
                                type="text"
                                id="phone"
                                name="phone"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                        <button onClick={editGuest} className="update-modal-btn">
                            <p>Güncelle</p>
                        </button>
                    </div>
                </div>
            )}

            {/* Delete Modal */}
            {DeletemodalOpen && (
                <div className="delete-modal">
                    <div className="delete-modal-content">
                        <span className="close" onClick={DeleteModalOff}>
                            &times;
                        </span>
                        <p>Seçili Kişiyi Silmek İstediğinize Emin misiniz ?</p>
                        <Divider />
                        <div className="delete-modal-button-container">
                            <button
                                onClick={DeleteModalOff}
                                className="delete-modal-btn"
                                id="vazgec-btn"
                            >
                                <p>Vazgeç</p>
                            </button>
                            <button className="delete-modal-btn" id="sil-btn">
                                <p>Sil</p>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}