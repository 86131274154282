import React from "react";
import "../Admin/Css/Seferler.css";
import FerriesTable from "./Table/Ferries/FerriesTable";

const Ferries = () => {

  return (
    <>
      <FerriesTable />
    </>
  );
};

export default Ferries;