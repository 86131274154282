import { Routes, Route } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/HomePage";
import HakkimizdaPage from "./pages/HakkimizdaPage";
import AydinlatmaMetni from "./pages/AydinlatmaMetni";
import { Login } from "./components/Login/Login";
import PnrSorgula from "./pages/PnrSorgula";
import { Sidebar } from "./components/Sidebar";
import Seferler from "./pages/Admin/Seferler";
import { MainLayout } from "./components/Layouts/MainLayout";
import { Admin } from "./pages/Admin";
import Yolcular from "./pages/Admin/Yolcular";
import BlogAdd from "./pages/Admin/BlogAdd";
import BlogEdit from "./pages/Admin/BlogEdit";
import Payments from "./pages/Admin/Payments";
import Messages from "./pages/Admin/Messages";
import FirsatAdd from "./pages/Admin/FirsatAdd";
import FirsatEdit from "./pages/Admin/FirsatEdit";
import Agents from "./pages/Admin/Agents";
import Ferries from "./pages/Admin/Ferries";
import Reservations from "./pages/Admin/Reservations";
import { SearchTours } from "./pages/SearchTours";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import FrequentlyAskedQuestions from "./pages/FrequentlyAskedQuestions";
import { useEffect, useState } from "react";
import { Token } from "./services/Token";
import { getItem } from "./utils/storage";
import { ConfigProvider } from "antd";
import locale from "antd/es/locale/tr_TR";
import "dayjs/locale/tr";
import dayjs from "dayjs";
import { PaymentPage } from "./pages/PaymentPage";
import { PaymentSuccess } from "./pages/PaymentPage/PaymentSuccess";
import { PaymentFail } from "./pages/PaymentPage/PaymentFail";
import ContactPage from "./pages/ContactPage";
import localizedFormat from "dayjs/plugin/localizedFormat";
import SalesContract from "./pages/SalesContract";

dayjs.locale("tr");

function App() {
  dayjs.extend(localizedFormat);
  const [logged, setLogged] = useState(false);
  const userData = getItem("adminUser");

  useEffect(() => {
    if (userData) {
      Token()
        .check({
          token: userData?.token,
        })
        .then((response) => {
          // başarılıysa oturum açık demektir.
          if (response.data.status === "success") {
            setLogged(true);
          } else {
            setLogged(false);
          }
        })
        .catch((error) => {
          setLogged(false);
        });
    } else {
      setLogged(false);
    }
  }, []);

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          // colorPrimary: "#114af3",
          // colorSecondary: "#06213d",
        },
      }}
    >
      <div>
        <Routes>
          <Route element={<MainLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/hakkimizda" element={<HakkimizdaPage />} />
            <Route path="/aydinlatma-metni" element={<AydinlatmaMetni />} />
            <Route path="/gizlilik-sozlesmesi" element={<PrivacyPolicy />} />
            <Route
              path="/mesafeli-satis-sozlesmesi"
              element={<SalesContract />}
            />
            <Route
              path="/sikca-sorulan-sorular"
              element={<FrequentlyAskedQuestions />}
            />
            <Route path="/pnr-sorgula" element={<PnrSorgula />} />
            <Route path="/odemeyap" element={<PaymentPage />} />
            <Route
              path="/bilet-al/odeme-basarili"
              element={<PaymentSuccess />}
            />
            <Route path="/bilet-al/odeme-basarisiz" element={<PaymentFail />} />
            <Route path="/iletisim" element={<ContactPage />} />
            <Route path="*" element={<HomePage />} /> {/*Sayfa Bulunamadı*/}
            <Route path="/bilet-al" element={<SearchTours />} />
          </Route>

          {logged ? (
            <Route path="/admin/*" element={<Sidebar />}>
              <Route index element={<Admin />} />
              <Route path="seferler" element={<Seferler />} />
              <Route path="feribotlar" element={<Ferries />} />
              <Route path="rezervasyonlar" element={<Reservations />} />
              <Route path="acenteler" element={<Agents />} />
              <Route path="yolcular" element={<Yolcular />} />
              <Route path="blog-add" element={<BlogAdd />} />
              <Route path="blog-edit" element={<BlogEdit />} />
              <Route path="payments" element={<Payments />} />
              <Route path="messages" element={<Messages />} />
              <Route path="firsat-add" element={<FirsatAdd />} />
              <Route path="firsat-edit" element={<FirsatEdit />} />
              {/* Diğer rotalar buraya gelecek */}
            </Route>
          ) : (
            <Route path="/admin/*" element={<Login />} />
          )}
        </Routes>
      </div>
    </ConfigProvider>
  );
}

export default App;
