import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CommonHeader } from '../../components/CommonHeader';
import { MainContainer } from "../../components/Layouts/MainContainer";
import { Payment } from "../../services/Payment";

export const PaymentPage = () => {
    const location = useLocation();
    const paymentData = location?.state;

    useEffect(() => {
        Payment().getPaymentToken({
            "user_ip": "string",
            "email": "test@email.com",
            "payment_amount": 0,
            "currency": "string",
            "user_basket": "string",
            "no_installment": 0,
            "max_installment": 0,
            "paytr_token": "string",
            "user_name": "string",
            "user_address": "string",
            "user_phone": "string",
            "merchant_ok_url": "string",
            "merchant_fail_url": "string",
            "test_mode": 0,
            "debug_on": 0,
            "timeout_limit": 0,
            "lang": "string"
        }).then((response) => {
            console.log("response", response.data);
            document.querySelector("#paytriframe").src = response?.data
        })
        console.log("paymentData", paymentData);
    }, [])

    return (
        <div>
            <CommonHeader title='Güvenli Ödeme Ekranı' />

            <MainContainer>
                <script src="https://www.paytr.com/js/iframeResizer.min.js"></script>
                <iframe src="https://www.paytr.com/odeme/guvenli/iframe_token" id="paytriframe" frameborder="0"
                    scrolling="no" style={{ width: '100%' }}></iframe>
                <script>iFrameResize({ },'#paytriframe');</script>
            </MainContainer>
        </div>
    )
}