export const genders = [
  {
    id: 1,
    name: "Erkek",
  },
  {
    id: 2,
    name: "Kadın",
  },
];
