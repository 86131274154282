export const initialGuestData = {
  name: "",
  surName: "",
  email: "",
  gsm: "",
  countryID: 1,
  countryCode: "TR",
  countryName: "TURKIYE",
  national_id: "",
  passportNo: "",
  citizenshipID: "",
  customerTypeID: 1,
  customerTypeName: "Yetişkin",
  gender: 1,
  genderName: "Erkek",
  birthDate: "",
  passportExpireDate: "",
  invoice_type: "individual",
  tax_number: "",
  tax_office: "",
  company_name: "",
};
