import { Button } from "antd"
import { Header } from "antd/es/layout/layout"

export const HeaderLayout = ({ title, Table, onClick, btnTitle, btnDisabled = false }) => {

    return (
        <div style={{ marginLeft: 30, marginRight: 30, marginTop: 10 }}>
            <Header style={{ background: 'transparent', display: 'flex', justifyContent: 'space-between', paddingInline: 0, marginBottom: 10 }}>
                <div style={{ fontSize: 30 }}>{title}</div>

                {
                    !btnDisabled &&
                    <div>
                        <Button type="primary" onClick={onClick}>{btnTitle}</Button>
                    </div>
                }
            </Header>

            {Table}
        </div>
    )
}