import React from 'react'
import '../pages/Css/HakkimizdaPage.css'
import { TabTitle } from "../tools/GeneralFunction";
import { CommonHeader } from '../components/CommonHeader';

const hakkimizda = () => {

  TabTitle('Hakkımızda - Uygun Feribot | Yunan Adaları Feribot Bileti Satın Al');

  return (
    <>
      <CommonHeader title='Hakkımızda' />
      <div className='MetinContainerHakkimizda'>
        <p>
          UygunFeribot.com olarak Yunan adalarına feribot bileti satışında uzmanlaşmış bir platformuz. Misyonumuz, size en uygun fiyatlarla, en konforlu ve güvenli seyahat deneyimini sunmaktır.
        </p>
        <p>
          Yunan adalarına sefer yapan tüm feribot firmalarıyla iş birliği yaparak, geniş bir rezervasyon ağı sunuyoruz. Bu sayede, farklı firmaların sunduğu seferleri karşılaştırarak sizin için en uygun seçenekleri bulmanızı sağlıyoruz. Hedefimiz, seyahat planlarınızı kolaylaştırmak ve tatilinizi en keyifli hale getirmektir.
        </p>
        Geniş sefer ağımız ve kullanıcı dostu platformumuz ile, hayalinizdeki Yunan adası tatiline ulaşmak artık çok daha kolay. İster tatilinizi planlıyor olun, ister son dakika kaçamağı yapmayı düşünün, UygunFeribot.com her zaman yanınızda.

        <p>
          Yunan adalarına feribot bileti almak için doğru yerdesiniz. Bizimle yolculuğunuz, tatilinizin en güzel başlangıcı olacak.
        </p>

        {/*<div style={{ width: '100%', height: '400px' }}>
          <iframe
        title="Google Maps"
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: 0,
        borderRadius: "15px" }}
        src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d100175.50528861032!2d27.066742564848052!3d38.31460887186547!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bbdfe83b08bd8f%3A0xf8881ad570ba143f!2zR2F6aWVtaXIvxLB6bWly!5e0!3m2!1str!2str!4v1698218147532!5m2!1str!2str`}
        allowFullScreen></iframe>
        </div>*/}
      </div>
    </>
  )
}

export default hakkimizda