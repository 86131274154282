import React from "react";
import "../Admin/Css/Yolcular.css";
import { Passengers } from "./Passengers";

const Yolcular = () => {
  return (
    <Passengers />
  );
};

export default Yolcular;
