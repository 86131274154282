import React, { useState, useEffect } from "react";
import { IoEyeSharp } from "react-icons/io5";
import { IoPencilSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import ImgDrop from "../ImgDrop"
import { Avatar, Badge, Button, Divider, Form, Input, List, Modal, Table, Tabs, Tooltip } from "antd";
import "../Reservations/ReservationsTable.css"
import { AgentService } from '../../../../services/AgentService';
import { Dropdown, Flex } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { RiShipLine } from "react-icons/ri";
import { PiHandshakeLight } from "react-icons/pi";
import { notification } from "antd";
import { Reservations } from "../../../../services/Reservations";
import { HeaderLayout } from "../../HeaderLayout";
import { PiTicketLight, PiTicketFill } from "react-icons/pi";
import { Tours } from "../../../../services/Tours";
import { getItem } from "../../../../utils/storage";
import { genderToTR } from '../../../../utils/gender';

const ReservationsTable = () => {
  const [TourmodalOpen, setTourModalOpen] = useState(false);
  const [DeletemodalOpen, setDeleteModalOpen] = useState(false);
  const [UpdatemodalOpen, setUpdateModalOpen] = useState(false);
  const [ShowmodalOpen, setShowModalOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [reservationGuestData, setReservationGuestData] = useState([]);
  const [tours, setTours] = useState([])

  const [adultCount, setAdultCount] = useState(0)
  const [childCount, setChildCount] = useState(0)
  const [pnrNumber, setPnrNumber] = useState("")
  const [resID, setResID] = useState("")
  const [ticketSelled, setTicketSelled] = useState(false)

  const [departureTourID, setDepartureTourID] = useState()
  const [departureTourName, setDepartureTourName] = useState()
  const [arrivalTourID, setArrivalTourID] = useState()
  const [arrivalTourName, setArrivalTourName] = useState()
  const [departureDate, setDepartureDate] = useState()
  const [arrivalDate, setArrivalDate] = useState()
  const [guestInfo, setGuestInfo] = useState([])

  const [selectedReservationID, setSelectedReservationID] = useState()


  const updateGuest = (id, updatedData) => {
    const updatedGuests = guestInfo.map(guest => {
      if (guest.id === id) {
        return { ...guest, ...updatedData };
      }
      return guest;
    });
    setGuestInfo(updatedGuests);
    console.log("√updatedGuests", updatedGuests);
  };

  const getGuestById = (id) => {
    return guestInfo.find(guest => guest.id === id);
  };

  const userData = getItem("adminUser")

  const tabItemsUpdate = [
    {
      key: '1',
      label: 'Detay',
      children: <div>
        <div className="name-modal">
          <label>PNR No</label>
          <input
            type="text"
            id="pnr_no"
            name="pnr_no"
            onChange={(e) => setPnrNumber(e.target.value)}
            required
            value={pnrNumber}
            placeholder="PNR Numarası"
          />
        </div>

        {/* <div className="name-modal update-modal-company" style={{ display: 'inline-block', width: '50%' }}>
              <label>Bilet Durumu</label>
              <select className="select" onChange={(e) => setTicketSelled(e.target.value == 1 ? true : false)}>
                <option value="1" selected={ticketSelled}>Bileti Kes</option>
                <option value="0" selected={ticketSelled}>Bilet Kesimini İptal Et</option>
              </select>
            </div> */}

        <div className="name-modal" style={{ display: 'inline-block', width: '50%' }}>
          <label>Yetişkin</label>
          <input
            type="number"
            id="adult_count"
            name="adult_count"
            onChange={(e) => setAdultCount(e.target.value)}
            required
            value={adultCount}
            placeholder="Yetişkin sayısı"
          />
        </div>
        <div className="name-modal" style={{ display: 'inline-block', width: '50%' }}>
          <label>Çocuk</label>
          <input
            type="number"
            id="child_count"
            name="child_count"
            onChange={(e) => setChildCount(e.target.value)}
            required
            value={childCount}
            placeholder="Çocuk sayısı"
          />
        </div>

        <div className="name-modal update-modal-company" style={{ display: 'inline-block', width: '50%' }}>
          <label>Kalkış Turu</label>
          <select className="select" onChange={(e) => setDepartureTourID(e.target.value)}>
            <option key='0' value='0' selected={!departureTourID}>Seçilmedi</option>
            {tours.filter(tour => tour.direction == 'departure').map(tour => (
              <option key={tour.tour_id} value={tour.tour_id} selected={departureTourID == tour.tour_id}>{tour.name}</option>
            ))}
          </select>
        </div>

        <div className="name-modal update-modal-company" style={{ display: 'inline-block', width: '50%' }}>
          <label>Varış Turu</label>
          <select className="select" onChange={(e) => setArrivalTourID(e.target.value)}>
            {tours.filter(tour => tour.direction == 'arrival').map(tour => (
              <option key={tour.tour_id} value={tour.tour_id} selected={arrivalTourID == tour.tour_id}>{tour.name}</option>
            ))}
          </select>
        </div>
      </div>,
    },
    {
      key: '2',
      label: 'Yolcular',
      children: <List
        itemLayout="horizontal"
        dataSource={reservationGuestData}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              style={{ textAlign: 'left' }}
              avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${item.gender == 'male' ? 1 : 0}`} />}
              title={<a href="https://ant.design">{(index + 1) + '. Yolcu'}</a>}
              description={
                <div style={{ textAlign: 'left' }}>
                  <div className="name-modal" style={{ display: 'inline-block', width: '50%' }}>
                    <label>İsim</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      onChange={(e) => {
                        updateGuest(item.id, { name: e.target.value })
                      }}
                      required
                      value={getGuestById(item.id)?.name}
                      placeholder="İsim girin"
                    />
                  </div>

                  <div className="name-modal" style={{ display: 'inline-block', width: '50%' }}>
                    <label>Soyisim</label>
                    <input
                      type="text"
                      id="surname"
                      name="surname"
                      onChange={(e) => {
                        updateGuest(item.index, { surname: e.target.value })
                      }}
                      required
                      value={getGuestById(index)?.surname}
                      placeholder="Soyisim girin"
                    />
                  </div>

                  <div>
                    <span className="guest_detail_label">Eposta: </span>
                    <span>{item.email}</span>
                  </div>

                  <div>
                    <span className="guest_detail_label">Telefon: </span>
                    <span>{item.phone}</span>
                  </div>

                  <div>
                    <span className="guest_detail_label">Uyruk: </span>
                    <span>{item.nationality}</span>
                  </div>

                  <div>
                    <span className="guest_detail_label">Cinsiyet: </span>
                    <span>{genderToTR[item.gender]}</span>
                  </div>

                  <div>
                    <span className="guest_detail_label">Pasaport numarası: </span>
                    <span>{item.national_id}</span>
                  </div>

                  <div>
                    <span className="guest_detail_label">Pasaport bitiş tarihi: </span>
                    <span>{item.passport_expiry_date}</span>
                  </div>

                  <div>
                    <span className="guest_detail_label">Doğum tarihi: </span>
                    <span>{item.birth_date}</span>
                  </div>
                </div>
              }
            />
          </List.Item>
        )}
      />,
    }
  ]

  const tabItems = [
    {
      key: '1',
      label: 'Detay',
      children: <div>
        <div className="round-trip-cont">
          <p className="round-trip-item">PNR: {pnrNumber}</p>
          <p className="round-trip-item">Bilet Durumu: {ticketSelled ? 'Kesildi' : 'Kesilmedi'}</p>
        </div>
        <div className="round-trip-cont">
          <p className="round-trip-item">Nerden: {departureTourName}</p>
          <p className="round-trip-item">Nereye: {arrivalTourName}</p>
        </div>
        <div className="round-trip-cont">
          <p className="round-trip-item">Gidiş Tarihi: {departureDate}</p>
          <p className="round-trip-item">Dönüş Tarihi: {arrivalDate}</p>
        </div>
        <div className="round-trip-cont">
          <p className="round-trip-item">Yetişkin: {adultCount}</p>
          <p className="round-trip-item">Çocuk: {childCount}</p>
        </div>
      </div>,
    },
    {
      key: '2',
      label: 'Yolcular',
      children: <List
        itemLayout="horizontal"
        dataSource={reservationGuestData}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              style={{ textAlign: 'left' }}
              avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${item.gender == 'male' ? 1 : 0}`} />}
              title={<a href="https://ant.design">{(index + 1) + '. Yolcu'}</a>}
              description={
                <div style={{ textAlign: 'left' }}>
                  <div>
                    <span className="guest_detail_label">İsim Soyisim: </span>
                    <span>{item.name + ' ' + item.surname}</span>
                  </div>
                  <div>
                    <span className="guest_detail_label">Eposta: </span>
                    <span>{item.email}</span>
                  </div>

                  <div>
                    <span className="guest_detail_label">Telefon: </span>
                    <span>{item.phone}</span>
                  </div>

                  <div>
                    <span className="guest_detail_label">Uyruk: </span>
                    <span>{item.nationality}</span>
                  </div>

                  <div>
                    <span className="guest_detail_label">Cinsiyet: </span>
                    <span>{genderToTR[item.gender]}</span>
                  </div>

                  <div>
                    <span className="guest_detail_label">Pasaport numarası: </span>
                    <span>{item.national_id}</span>
                  </div>

                  <div>
                    <span className="guest_detail_label">Pasaport bitiş tarihi: </span>
                    <span>{item.passport_expiry_date}</span>
                  </div>

                  <div>
                    <span className="guest_detail_label">Doğum tarihi: </span>
                    <span>{item.birth_date}</span>
                  </div>
                </div>
              }
            />
          </List.Item>
        )}
      />,
    }
  ];

  const onChangeTabItem = (key) => {
    console.log(key);
  };

  const fetchTours = () => {
    Tours().list({
      token: userData.token
    }).then((response) => {
      const { data } = response;
      if (data?.status == "success") {
        const tours = data.data;
        setTours(tours)
        console.log("reservations", data);
      } else {
        console.log("hata", data?.message);
      }
    }).catch((error) => {
      console.log("err", error);
    });
  }

  const TourModalOpen = () => {
    setTourModalOpen(true);
  };
  const TourModalOff = () => {
    setTourModalOpen(false);
  };

  const DeleteModalOpen = () => {
    setDeleteModalOpen(true);
  };
  const DeleteModalOff = () => {
    setDeleteModalOpen(false);
  };

  const UpdateModalOpen = (record) => {
    setUpdateModalOpen(true);
    setAdultCount(record.adult_count)
    setChildCount(record.child_count)
    setPnrNumber(record.pnr_no)
    setTicketSelled(record.sell_ticket == 1 ? true : false)
    setDepartureTourID(Number(record.departure_tour_id))
    setArrivalTourID(Number(record.arrival_tour_id))
    setResID(record.id)
    console.log("record", record);

    fetchReservationGuestData(record.id)
  };
  const UpdateModalOff = () => {
    setUpdateModalOpen(false);
  };

  const saveReservation = () => {
    console.log("guestInfo", guestInfo);
    Reservations().update(resID, {
      adult_count: adultCount,
      child_count: childCount,
      departure_tour_id: departureTourID,
      arrival_tour_id: arrivalTourID,
      pnr_no: pnrNumber,
      sell_ticket: ticketSelled,
      reservation_guests: guestInfo,
    }).then((res) => {
      console.log("res", res.data)
      if (res.data.status == "success") {
        notification.success({
          message: 'Başarılı',
          description: 'Rezervasyon detayı güncellendi!',
          status: 'success'
        })
        fetchData()
        UpdateModalOff()
      } else {
        notification.error({
          message: 'Hata',
          description: 'Rezervasyon güncellenemedi!',
          status: 'error'
        })
      }
    }).catch((error) => {
      notification.error({
        message: 'Hata',
        description: 'Hata oluştu!',
        status: 'error'
      })
    })
  }

  const ShowModalOpen = (record) => {
    setShowModalOpen(true);
    setSelectedReservationID(record.id)
    setAdultCount(record.adult_count)
    setChildCount(record.child_count)
    setPnrNumber(record.pnr_no)
    setTicketSelled(record.sell_ticket == 1 ? true : false)
    setDepartureTourID(Number(record.departure_tour_id))
    setArrivalTourID(Number(record.arrival_tour_id))
    setArrivalTourName(record.arrival_tour_name)
    setDepartureTourName(record.departure_tour_name)
    setArrivalDate(record.arrival_date)
    setDepartureDate(record.departure_date)
    setResID(record.id)

    fetchReservationGuestData(record.id)
  };
  const ShowModalOff = () => {
    setShowModalOpen(false);
  };

  const [number, setNumber] = useState("");

  const { create, remove: removeReservation } = Reservations()

  const handleChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setNumber(event.target.value);
    }
  };

  const sellTicket = (id) => {
    Reservations().update(id, {
      sell_ticket: 1
    }).then((res) => {
      console.log("res", res.data)
      if (res.data.status == "success") {
        notification.success({
          message: 'Başarılı',
          description: 'Rezervasyon bileti kesildi!',
          status: 'success'
        })
        fetchData()
      } else {
        notification.error({
          message: 'Hata',
          description: 'Rezervasyon bileti kesilemedi!',
          status: 'error'
        })
      }
    }).catch((error) => {
      notification.error({
        message: 'Hata',
        description: 'Rezervasyon bileti kesilemedi!',
        status: 'error'
      })
    })
  }

  const unSellTicket = (id) => {
    // alert 
    Reservations().update(id, {
      sell_ticket: 0
    }).then((res) => {
      console.log("res", res.data)
      if (res.data.status == "success") {
        notification.success({
          message: 'Başarılı',
          description: 'Rezervasyon bilet kesimi iptal edil!',
          status: 'success'
        })
        fetchData()
      } else {
        notification.error({
          message: 'Hata',
          description: 'Rezervasyon bileti kesimi iptal edilemedi!',
          status: 'error'
        })
      }
    }).catch((error) => {
      notification.error({
        message: 'Hata',
        description: 'Hata oluştu!',
        status: 'error'
      })
    })
  }

  const fetchData = () => {
    Reservations().list().then((response) => {
      const { data } = response;
      if (data?.status == "success") {
        const agents = data.data;
        setDataSource(agents)
        console.log("reservations", data);
      } else {
        console.log("hata", data?.message);
      }
    }).catch((error) => {
      console.log("err", error);
    });
  }

  const fetchReservationGuestData = (id) => {
    Reservations().guestsByReservation(id, {
      token: userData.token
    }).then((response) => {
      const { data } = response;
      if (data?.status == "success") {
        const guests = data.data;
        setReservationGuestData(guests)
        setGuestInfo(guests)
        console.log("reservation guests", data);
      } else {
        console.log("hata", data?.message);
      }
    }).catch((error) => {
      setReservationGuestData([])
      console.log("err", error);
    });
  }

  const deleteReservation = (id) => {
    removeReservation(id).then((response) => {
      if (response.data.status == "success") {
        fetchData()
        notification.success({
          message: 'Başarılı',
          description: 'Rezervasyon başarıyla silindi',
          status: 'success'
        })
      }
    }).catch(error => notification.error({
      message: 'Hata',
      description: 'Hata oluştu',
      status: 'error'
    }))
  }

  const createReservation = () => {
    const agentName = document.querySelector("input[name=name").value;
    const agentDesc = document.querySelector("input[name=description").value;
    const agentVisible = document.querySelector("select[name=visible").value;
    AgentService().create({
      name: agentName,
      description: agentDesc,
      visible: agentVisible
    }).then((response) => {
      const { data } = response;
      if (data?.status == "success") {
        console.log("reservations", data);

        notification.success({
          message: 'Başarılı',
          description: 'Başarıyla rezervasyon eklendi!',
          placement: 'topRight'
        })
        TourModalOff()
        fetchData()
      } else {
        notification.error({
          message: 'Hata',
          description: 'Rezervasyon eklenemedi!',
          placement: 'topRight'
        })
      }
    }).catch((error) => {
      notification.error({
        message: 'Hata',
        description: 'Rezervasyon eklenemedi!',
        placement: 'topRight'
      })
    });
  }

  useEffect(() => {
    fetchData()
    fetchTours()
  }, [])

  const columns = [
    {
      title: 'Bilet',
      dataIndex: 'sell_ticket',
      key: 'sell_ticket',
      sorter: (a, b) => a.sell_ticket - b.sell_ticket,
      render: (text, record, index) => {
        const ticketStatusColors = {
          'selled': 'forestgreen',
          'waiting': '#faad14'
        }
        if (record.sell_ticket == 1) {
          return (
            <Badge style={{ color: ticketStatusColors['selled'] }} text='Bileti Kesildi' color={ticketStatusColors['selled']} />
          )
        } else {
          return (
            <Badge style={{ color: ticketStatusColors['waiting'] }} text='Beklemede' color={ticketStatusColors['waiting']} />
          )
        }
      }
    },
    {
      title: 'PNR',
      dataIndex: 'pnr_no',
      key: 'pnr_no',
    },
    {
      title: 'Kalkış Turu',
      dataIndex: 'departure_tour_name',
      key: 'departure_tour_name',
    },
    {
      title: 'Varış Turu',
      dataIndex: 'arrival_tour_name',
      key: 'arrival_tour_name',
    },
    {
      title: 'Rezervasyon Tarihi',
      dataIndex: 'reservation_date',
      key: 'reservation_date',
    },
    {
      title: 'Yetişkin',
      dataIndex: 'adult_count',
      key: 'adult_count',
    },
    {
      title: 'Çocuk',
      dataIndex: 'child_count',
      key: 'child_count',
    },
    {
      title: 'Ödenen fiyat',
      dataIndex: 'total_price',
      key: 'total_price',
      render: (text, record, index) => {
        return (
          <>
            <span>€{text}</span>
          </>
        )
      }
    },
    {
      title: 'İşlemler',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record, index) => {
        const ticketIsSelled = record.sell_ticket == 1
        return (
          <Flex align="flex-start" gap="small">
            <Tooltip title={ticketIsSelled ? 'Bileti Kesimini İptal Et' : 'Bileti kes'}>
              <Button
                icon={ticketIsSelled ? <PiTicketFill /> : <PiTicketLight />}
                onClick={() => {
                  if (ticketIsSelled) {
                    Modal.confirm({
                      title: record.pnr_no + ' - Bilet kesimi iptal edilecek emin misiniz??',
                      footer: (_, { OkBtn, CancelBtn }) => (
                        <>
                          <OkBtn />
                          <CancelBtn />
                        </>
                      ),
                      okText: 'Onaylıyorum',
                      onOk: () => {
                        unSellTicket(record.id)
                      },
                      cancelText: 'Vazgeç',
                    })
                  } else {
                    sellTicket(record.id)
                  }
                }}
              />
            </Tooltip>
            <Button
              type='primary'
              ghost
              icon={<EyeOutlined />}
              onClick={() => ShowModalOpen(record)}
            />
            <Button onClick={() => {
              // setUpdateModalForm(record)
              UpdateModalOpen(record)
            }} icon={<EditOutlined />}></Button>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => Modal.confirm({
                title: record.id + ' - Silmek istediğinize emin misiniz?',
                footer: (_, { OkBtn, CancelBtn }) => (
                  <>
                    <OkBtn />
                    <CancelBtn />
                  </>
                ),
                okText: 'Sil',
                onOk: () => {
                  console.log("drecord", record);
                  deleteReservation(Number(record.id))
                },
                cancelText: 'İptal',
              })}
            ></Button>
          </Flex>
        )
      }
    },

  ];

  return (
    <>
      <HeaderLayout
        title='Rezervasyonlar'
        btnDisabled={true}
        Table={<Table dataSource={dataSource} columns={columns} />}
      />
      {/* Table */}
      <div className="table-container-tours">
        {/* <table className="ui-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nerden</th>
              <th>Nereye</th>
              <th>Gidiş Tarihi</th>
              <th>Dönüş Tarihi</th>
              <th>Firma</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>İstanbul</td>
              <td>Ankara</td>
              <td>2024-03-25</td>
              <td>2024-03-27</td>
              <td>Tilos</td>
              <td className="button-container">
                <button onClick={ShowModalOpen} id="show-icon">
                  <IoEyeSharp className="table-icon" />
                </button>
                <button onClick={UpdateModalOpen} id="edit-icon">
                  <IoPencilSharp className="table-icon" />
                </button>
                <button onClick={DeleteModalOpen} id="delete-icon">
                  <MdDelete className="table-icon" />
                </button>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Ankara</td>
              <td>İzmir</td>
              <td>2024-04-01</td>
              <td>2024-04-05</td>
              <td>Turyol</td>
              <td className="button-container">
                <button onClick={ShowModalOpen} id="show-icon">
                  <IoEyeSharp className="table-icon" />
                </button>
                <button onClick={UpdateModalOpen} id="edit-icon">
                  <IoPencilSharp className="table-icon" />
                </button>
                <button onClick={DeleteModalOpen} id="delete-icon">
                  <MdDelete className="table-icon" />
                </button>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>İzmir</td>
              <td>Antalya</td>
              <td>2024-04-10</td>
              <td>2024-04-15</td>
              <td>Ertürk Lines</td>
              <td className="button-container">
                <button onClick={ShowModalOpen} id="show-icon">
                  <IoEyeSharp className="table-icon" />
                </button>
                <button onClick={UpdateModalOpen} id="edit-icon">
                  <IoPencilSharp className="table-icon" />
                </button>
                <button onClick={DeleteModalOpen} id="delete-icon">
                  <MdDelete className="table-icon" />
                </button>
              </td>
            </tr>
          </tbody>
        </table> */}
      </div>

      {/*Tour Add*/}
      {TourmodalOpen && (
        <div className="tour-add">
          <div className="tour-add-modal-content">
            <span className="close" onClick={TourModalOff}>
              &times;
            </span>
            <p>Acente Ekle</p>
            <Divider />
            <div className="name-modal">
              <label>İsim</label>
              <input
                type="text"
                id="name"
                name="name"
                onChange={handleChange}
                required
                placeholder="Lütfen acente ismi girin"
              />
            </div>

            <div className="description-modal">
              <label>Açıklama</label>
              <input
                type="text"
                id="description"
                name="description"
                onChange={handleChange}
                required
                placeholder="Lütfen açıklaması girin"
              />
            </div>
            <div className="company-select-modal">
              <label>Görünürlük</label>
              <select className="select" name="visible" id="visible">
                <option value="true">Aktif</option>
                <option value="false">Pasif</option>
              </select>
            </div>
            <button className="add-trip-btn" onClick={createReservation}>Tamam</button>
          </div>
        </div>
      )}

      {/* Show Modal */}
      {ShowmodalOpen && (
        <div className="show-modal">
          <div className="show-modal-content">
            <span className="close" onClick={ShowModalOff}>
              &times;
            </span>
            <p>Rezervasyon Bilgileri</p>

            <Tabs defaultActiveKey="1" items={tabItems} onChange={onChangeTabItem} />
            <button className="show-payment-oky-btn" onClick={ShowModalOff}>
              Tamam
            </button>
          </div>
        </div>
      )}

      {/* Update Modal */}
      {UpdatemodalOpen && (
        <div className="update-modal">
          <div className="update-modal-content">
            <span className="close" onClick={UpdateModalOff}>
              &times;
            </span>
            <p>Rezervasyon Düzenle ({pnrNumber})</p>
            <Tabs defaultActiveKey="1" items={tabItemsUpdate} />
            {/* <div className="form-group-select-ticket">
              <div className="one-way-two-way-container">
                <label
                  htmlFor="toggle"
                  className="toggleContainer"
                  style={{
                    fontWeight: "500",
                  }}
                >
                  <div>Tek Yön</div>
                  <div>Gidiş Dönüş</div>
                </label>
              </div>
            </div> */}

            <button onClick={saveReservation} className="update-modal-btn">
              <p>Güncelle</p>
            </button>
          </div>
        </div>
      )}

      {/* Delete Modal */}
      {DeletemodalOpen && (
        <div className="delete-modal">
          <div className="delete-modal-content">
            <span className="close" onClick={DeleteModalOff}>
              &times;
            </span>
            <p>Seçili Seferi Silmek İstediğinize Emin misiniz ?</p>
            <Divider />
            <div className="delete-modal-button-container">
              <button
                onClick={DeleteModalOff}
                className="delete-modal-btn"
                id="vazgec-btn"
              >
                <p>Vazgeç</p>
              </button>
              <button className="delete-modal-btn" id="sil-btn">
                <p>Sil</p>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReservationsTable;
