export const customerTypes = [
  {
    id: 1,
    name: "Yetişkin",
  },
  {
    id: 2,
    name: "Çocuk",
  },
  {
    id: 3,
    name: "Bebek",
  },
];

export const passengerTypes = {
  Adult: "Yetişkin",
  Child: "Çocuk",
  Infant: "Bebek",
};
