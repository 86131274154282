import { Reservations } from "./Reservations";

export const SERVICE_URL = "https://api.uygunferibot.com/api";
export const SITE_URL = process.env.REACT_APP_SITE_URL;
export const TILOS_SERVICE_URL = "https://api.backoffice.web.tr/api";

export const Services = () => {
  return {
    Reservations,
  };
};
