import axios from "axios";
import { SERVICE_URL } from "..";

export const Auth = () => {
  // admin login
  const login = (params) => {
    return axios.post(`${SERVICE_URL}/auth/login`, params);
  };

  // PNR Check
  const checkPNR = (params) =>
    axios.get(`${SERVICE_URL}/reservations/check`, { params });

  return {
    login,
    checkPNR,
  };
};
