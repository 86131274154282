import { Menu } from "antd";
import { RiShip2Line } from "react-icons/ri";
import { TbMessage2Star } from "react-icons/tb";
import { BsBank } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FcHome } from "react-icons/fc";
import { FcSettings } from "react-icons/fc";
import { FcMenu } from "react-icons/fc";
import { FcSms } from "react-icons/fc";
import { FcMoneyTransfer } from "react-icons/fc";
import { FcStackOfPhotos } from "react-icons/fc";
import { FcAdvertising } from "react-icons/fc";
import { FcConferenceCall } from "react-icons/fc";
import { FcCancel } from "react-icons/fc";
import { FcBrokenLink } from "react-icons/fc";
import { FcAddImage } from "react-icons/fc";
import { FcEditImage } from "react-icons/fc";
import { FcCameraAddon } from "react-icons/fc";
import { FcSwitchCamera } from "react-icons/fc";
import { SocialIcon } from "react-social-icons";

const MenuList = ({ darkTheme }) => {
  return (
    <Menu
      theme={darkTheme ? "light" : "dark"}
      mode="inline"
      className="menu-bar"
    >
      <Menu.Item key="AnaSayfa" icon={<FcHome />}>
        <Link to={"/admin"}>Ana Sayfa</Link>
      </Menu.Item>
      <Menu.Item key="Reservations" icon={<RiShip2Line />}>
        <Link to={"/admin/rezervasyonlar"}>Rezervasyonlar</Link>
      </Menu.Item>
      <Menu.Item key="Seferler" icon={<RiShip2Line />}>
        <Link to={"/admin/seferler"}>Seferler</Link>
      </Menu.Item>
      <Menu.Item key="Yolcular" icon={<FcConferenceCall />}>
        <Link to={"/admin/yolcular"}>Yolcular</Link>
      </Menu.Item>
      <Menu.Item key="Ferries" icon={<FcMoneyTransfer />}>
        <Link to={"/admin/feribotlar"}>Feribotlar</Link>
      </Menu.Item>
      <Menu.Item key="Blogs" icon={<FcStackOfPhotos />}>
        <Link to={"/admin/blog"}>Blog</Link>
      </Menu.Item>
      {/* <Menu.SubMenu key="blogs" icon={<FcStackOfPhotos />} title="Blog"> */}
      {/* <Link to={"/admin/blog-add"}>
          <Menu.Item key="blogadd" icon={<FcAddImage />}>
            Blog Ekle
          </Menu.Item>
        </Link>
        <Link to={"/admin/blog-edit"}>
          <Menu.Item key="blogedit" icon={<FcEditImage />}>
            Blog Düzenle
          </Menu.Item>
        </Link> */}
      {/* </Menu.SubMenu> */}
      {/* <Menu.Item key="Payments" icon={<FcMoneyTransfer />}>
        <Link to={"/admin/payments"}>Ödemeler</Link>
      </Menu.Item> */}
      {/* <Menu.Item key="Mesajlar" icon={<FcSms />}>
        <Link to={"/admin/messages"}>Mesajlar</Link>
      </Menu.Item> */}
      {/* <Menu.SubMenu key="daha-fazla" icon={<FcMenu />} title="Daha Fazla">
        <Link to={"https://uygunferibot.com"} target="_blank">
          <Menu.Item key="websitesi" icon={<FcBrokenLink />}>
            Web Sitesi
          </Menu.Item>
        </Link>
        <Link to={"https://www.instagram.com/feribotyakala/"} target="_blank">
          <Menu.Item
            key="instagram"
            icon={
              <SocialIcon
                url="www.instagram.com"
                style={{
                  height: "20px",
                }}
              />
            }
          >
            Instagram
          </Menu.Item>
        </Link>
      </Menu.SubMenu> */}
      {/* <Menu.Item key="setting" icon={<FcSettings />}>
        Ayarlar
      </Menu.Item> */}
      {/* <Menu.Item key="Çıkış" icon={<FcCancel />}>
        <Link to={"/"}>Çıkış</Link>
      </Menu.Item> */}
    </Menu>
  );
};

export default MenuList;
