import React, { useState, useEffect } from "react";
import { IoEyeSharp } from "react-icons/io5";
import { IoPencilSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import ImgDrop from "../ImgDrop"
import { Button, Divider, Flex, Table, Layout, Modal, notification } from "antd";
import "../Seferler/SeferlerTable.css"
import { Tours } from "../../../../services/Tours";
import { Ferries } from "../../../../services/Ferries";
import { getItem } from "../../../../utils/storage";
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons'
import { Ports } from "../../../../services/Ports";
import { AgentService } from "../../../../services/AgentService";
import { HeaderLayout } from "../../HeaderLayout";
import dayjs from "dayjs";

const { Header, Content } = Layout;

const SeferlerTable = () => {
  const userData = getItem("adminUser")
  const [dataSource, setDataSource] = useState([]);
  const [TourmodalOpen, setTourModalOpen] = useState(false);
  const [DeletemodalOpen, setDeleteModalOpen] = useState(false);
  const [UpdatemodalOpen, setUpdateModalOpen] = useState(false);
  const [ShowmodalOpen, setShowModalOpen] = useState(false);

  const [from, setFrom] = useState("BODRUM");
  const [to, setTo] = useState("RODOS");
  const [varisLimanlari] = useState({
    FETHIYE: ["RODOS"],
    BODRUM: ["RODOS", "KOS", "SYMI", "PATMOS"],
    KUSADASI: ["SAMOS", "PATMOS"],
    CESME: ["CHIOS-SAKIZ"],
    AYVALIK: ["MİDİLLİ"],
    KAS: ["MEIS"],
    KOS: ["BODRUM"],
    CHIOS_SAKIZ: ["ÇEŞME"],
    MIDILLI: ["AYVALIK"],
    MEIS: ["KAS"],
    SAMOS: ["KUŞADASI"],
    SYMI: ["BODRUM"],
    PATMOS: ["BODRUM", "KUŞADASI"],
    RODOS: ["BODRUM", "FETHiYE"],
    SEFERIHISAR: ["SAMOS"],
    // KALKIS LİMANLARİ
  });

  const [GidisTarihi, setGidisTarihi] = useState("");
  const [DonusTarihi, setDonusTarihi] = useState("");
  const [donusTarihiAktif, setDonusTarihiAktif] = useState(true);
  const [tourName, setTourName] = useState('');
  const [tourType, setTourType] = useState('departure');
  const [agent, setAgent] = useState("");
  const [ferry, setFerry] = useState("");
  const [price, setPrice] = useState("0.00");

  const handleChangeTourName = (event) => {
    setTourName(event.target.value)
  }
  const handleChangeTourType = (event) => {
    setTourType(event.target.value)
  }

  const handleChangeAgent = (event) => {
    const value = event.target.value;
    setAgent(value)
    setFerry(ferries.filter(ferry => ferry.agent_id == value)?.[0]?.id)
  }
  const handleChangeFerry = (event) => {
    setFerry(event.target.value)
  }

  const { create: createTour, remove: removeTour } = Tours()
  const { list: portList } = Ports()
  const { list: agentList } = AgentService()
  const { list: ferriesList } = Ferries()

  const [ports, setPorts] = useState([])
  const [agents, setAgentList] = useState([])
  const [ferries, setFerriesList] = useState([])

  useEffect(() => {
    // Günün tarihini al
    const bugun = new Date().toISOString().split("T")[0];
    // setGidisTarihi(bugun);
    if (donusTarihiAktif) {
      // setDonusTarihi(bugun);
    } else {
      setDonusTarihi("");
    }
  }, [donusTarihiAktif]);

  const fetchData = () => {
    Tours().list({
      token: userData.token
    }).then((response) => {
      const { data } = response;
      if (data?.status == "success") {
        const guests = data.data;
        setDataSource(guests)
        console.log("reservations", data);
      } else {
        console.log("hata", data?.message);
      }
    }).catch((error) => {
      console.log("err", error);
    });
  }

  useEffect(() => {
    fetchData()
    portList().then((res) => {
      if (res.data.status == "success") {
        const nameIdMap = res.data.data.reduce((acc, port) => {
          acc[port.name.toUpperCase()] = port.id;
          return acc;
        }, {});
        setPorts(nameIdMap)
        console.log("portsss", nameIdMap);
      }
    })

    agentList().then((res) => {
      if (res.data.status == "success") {
        setAgentList(res.data.data)
        console.log("portsss", res.data.data);
      }
    })

    agentList().then((res) => {
      if (res.data.status == "success") {
        setAgentList(res.data.data)
        console.log("portsss", res.data.data);
      }
    })

    ferriesList().then((res) => {
      if (res.data.status == "success") {
        setFerriesList(res.data.data)
        console.log("portsss", res.data.data);
      }
    })
  }, [])

  const TourModalOpen = () => {
    setTourModalOpen(true);
    setDonusTarihi('')
    setGidisTarihi('')
    setAgent(agents[0]?.id)
    setFerry(ferries.filter(ferry => ferry.agent_id == agents?.[0]?.id)?.[0]?.id)
    setTourName('')
    setPrice('')
  };
  const TourModalOff = () => {
    setTourModalOpen(false);
  };

  const DeleteModalOpen = () => {
    setDeleteModalOpen(true);
  };
  const DeleteModalOff = () => {
    setDeleteModalOpen(false);
  };

  const UpdateModalOpen = (record) => {
    console.log("gidiş", record);
    setUpdateModalOpen(true);
    setDonusTarihi(record.arrival_time)
    setGidisTarihi(record.departure_time)
    setAgent(record.agent_id)
    setTourName(record.name)
    setPrice(record.price)
  };
  const UpdateModalOff = () => {
    setUpdateModalOpen(false);
  };

  const ShowModalOpen = () => {
    setShowModalOpen(true);
  };
  const ShowModalOff = () => {
    setShowModalOpen(false);
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const openDeleteModal = () => setShowDeleteModal(true)
  const closeDeleteModal = () => setShowDeleteModal(false)

  const handleDeleteTour = () => {
    openDeleteModal()
  }

  const [number, setNumber] = useState("");

  const handleChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setNumber(event.target.value);
    }
  };

  const deleteTour = (id) => {
    removeTour(id).then((response) => {
      if (response.data.status == "success") {
        fetchData()
        notification.success({
          message: 'Başarılı',
          description: 'Sefer başarıyla silindi',
          status: 'success'
        })
      }
    }).catch(error => notification.error({
      message: 'Hata',
      description: 'Hata oluştu',
      status: 'error'
    }))
  }

  const addTour = () => {
    console.log("ferry", ferry);
    const data = {
      departure_port_id: ports[from],
      arrival_port_id: ports[to],
      departure_time: GidisTarihi,
      arrival_time: DonusTarihi,
      ferry_id: ferry,
      price: number,
      name: tourName,
      direction: tourType
    }

    console.log("tour", data);

    createTour(data).then((response) => {
      if (response.data.status == "success") {
        TourModalOff()
        fetchData()
        notification.success({
          message: 'Başarılı',
          description: 'Sefer başarıyla oluşturuldu',
          status: 'success'
        })
      }
    }).catch(error => TourModalOff())
  }

  const columns = [
    {
      dataIndex: 'tour_id',
      key: 'tour_id'
    },
    {
      title: 'Sefer Adı',
      dataIndex: "name",
      key: "name"
    },
    {
      title: 'Nereden',
      dataIndex: 'departure_port_name',
      key: 'departure_port_name',
    },
    {
      title: 'Nereye',
      dataIndex: 'arrival_port_name',
      key: 'arrival_port_name',
    },
    {
      title: 'Gidiş Tarihi',
      dataIndex: 'departure_time',
      key: 'departure_time',
    },
    {
      title: 'Dönüş Tarihi',
      dataIndex: 'arrival_time',
      key: 'arrival_time',
    },
    {
      title: 'Firma',
      dataIndex: 'agent_name',
      key: 'agent_name'
    },
    {
      title: 'Fiyat',
      dataIndex: 'price',
      key: 'price',
      render: (text, record, index) => {
        return '€' + text
      }
    },
    {
      title: 'İşlemler',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record, index) => {
        return (
          <Flex align="flex-start" gap="small">
            {/* <Button type='primary' ghost icon={<EyeOutlined />}></Button> */}
            <Button
              type="primary"
              ghost
              onClick={() => {
                // setUpdateModalForm(record)
                UpdateModalOpen(record)
              }}
              icon={<EditOutlined />}
            ></Button>

            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => Modal.confirm({
                title: record.name + ' - Silmek istediğinize emin misiniz?',
                footer: (_, { OkBtn, CancelBtn }) => (
                  <>
                    <OkBtn />
                    <CancelBtn />
                  </>
                ),
                okText: 'Sil',
                onOk: () => {
                  console.log("drecord", record);
                  deleteTour(Number(record.tour_id))
                },
                cancelText: 'İptal',
              })}
            ></Button>
          </Flex>
        )
      }
    },

  ];

  return (
    <>
      <HeaderLayout
        title='Seferler'
        Table={<Table dataSource={dataSource} columns={columns} />}
        onClick={TourModalOpen}
        btnTitle='Sefer Ekle'
      />

      <Modal
        title="Silmek istediğinize emin misiniz?"
        open={showDeleteModal}
        onOk={closeDeleteModal}
        onCancel={closeDeleteModal}
        okText="Sil"
        cancelText="İptal"
      >
        <p>Bla bla ...</p>
        <p>Bla bla ...</p>
        <p>Bla bla ...</p>
      </Modal>

      <div className="table-container-tours">
        {/* <div className="table-top-container">
          <select className="select">
            <option value="1">Gelişmiş Sıralama</option>
            <option value="2">En Yeni</option>
            <option value="3">En Eski</option>
          </select>
          <button onClick={TourModalOpen} className="tour-add-btn">
            <p>Sefer Ekle</p>
          </button>
        </div> */}
        {/* <table className="ui-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nerden</th>
              <th>Nereye</th>
              <th>Gidiş Tarihi</th>
              <th>Dönüş Tarihi</th>
              <th>Firma</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>İstanbul</td>
              <td>Ankara</td>
              <td>2024-03-25</td>
              <td>2024-03-27</td>
              <td>Tilos</td>
              <td className="button-container">
                <button onClick={ShowModalOpen} id="show-icon">
                  <IoEyeSharp className="table-icon" />
                </button>
                <button onClick={UpdateModalOpen} id="edit-icon">
                  <IoPencilSharp className="table-icon" />
                </button>
                <button onClick={DeleteModalOpen} id="delete-icon">
                  <MdDelete className="table-icon" />
                </button>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Ankara</td>
              <td>İzmir</td>
              <td>2024-04-01</td>
              <td>2024-04-05</td>
              <td>Turyol</td>
              <td className="button-container">
                <button onClick={ShowModalOpen} id="show-icon">
                  <IoEyeSharp className="table-icon" />
                </button>
                <button onClick={UpdateModalOpen} id="edit-icon">
                  <IoPencilSharp className="table-icon" />
                </button>
                <button onClick={DeleteModalOpen} id="delete-icon">
                  <MdDelete className="table-icon" />
                </button>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>İzmir</td>
              <td>Antalya</td>
              <td>2024-04-10</td>
              <td>2024-04-15</td>
              <td>Ertürk Lines</td>
              <td className="button-container">
                <button onClick={ShowModalOpen} id="show-icon">
                  <IoEyeSharp className="table-icon" />
                </button>
                <button onClick={UpdateModalOpen} id="edit-icon">
                  <IoPencilSharp className="table-icon" />
                </button>
                <button onClick={DeleteModalOpen} id="delete-icon">
                  <MdDelete className="table-icon" />
                </button>
              </td>
            </tr>
          </tbody>
        </table> */}
      </div>

      {/*Tour Add*/}
      {TourmodalOpen && (
        <div className="tour-add">
          <div className="tour-add-modal-content">
            <span className="close" onClick={TourModalOff}>
              &times;
            </span>
            <p>Sefer Ekle</p>
            <Divider />
            <div className="price-container-modal">
              <label>Sefer ismi</label>
              <input
                type="tel"
                id="name"
                name="name"
                value={tourName}
                onChange={handleChangeTourName}
                required
                placeholder="Lütfen sefer ismi girin"
              />
            </div>

            <div className="company-select-modal">
              <label>Yön</label>
              <select className="select" onChange={handleChangeTourType}>
                <option value="departure" selected>Gidiş</option>
                <option value="arrival">Dönüş</option>
              </select>
            </div>
            {/* <div className="form-group-select-ticket">
              <div className="one-way-two-way-container">
                <input
                  type="checkbox"
                  id="toggle"
                  className="toggleCheckbox"
                  checked={donusTarihiAktif}
                  onChange={() => setDonusTarihiAktif(!donusTarihiAktif)}
                />
                <label
                  htmlFor="toggle"
                  className="toggleContainer"
                  style={{
                    fontWeight: "500",
                  }}
                >
                  <div>Tek Yön</div>
                  <div>Gidiş Dönüş</div>
                </label>
              </div>
            </div> */}
            <div className="update-modal-location">
              <div className="form-group-select-ticket">
                <div className="label-container">
                  <label htmlFor="from">Nereden</label>
                </div>
                <select
                  className="select-way"
                  id="from"
                  name="from"
                  value={from}
                  onChange={(e) => {
                    setFrom(e.target.value);
                    setTo(varisLimanlari[e.target.value][0]);
                  }}
                >
                  <option value="FETHIYE">FETHİYE</option>
                  <option value="BODRUM">BODRUM</option>
                  <option value="KUSADASI">KUŞADASI</option>
                  <option value="CESME">ÇEŞME</option>
                  <option value="AYVALIK">AYVALIK</option>
                  <option value="KAS">KAŞ</option>
                  <option value="KOS">KOS</option>
                  <option value="CHIOS_SAKIZ">CHIOS-SAKIZ</option>
                  <option value="MIDILLI">MİDİLLİ</option>
                  <option value="MEIS">MEIS</option>
                  <option value="SAMOS">SAMOS</option>
                  <option value="SYMI">SYMI</option>
                  <option value="PATMOS">PATMOS</option>
                  <option value="RODOS">RODOS</option>
                  <option value="SEFERIHISAR">SEFERİHİSAR</option>
                </select>
              </div>
              <div className="form-group-select-ticket">
                <div className="label-container">
                  <label htmlFor="to">Nereye</label>
                </div>
                <select
                  className="select-way"
                  id="to"
                  name="to"
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                >
                  {/* Varış limanları */}
                  {varisLimanlari[from] &&
                    varisLimanlari[from].map((liman) => (
                      <option key={liman} value={liman}>
                        {liman}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <label htmlFor="">Sefer Saatleri</label>
            <div className="update-modal-time">
              <input
                className={`departure-date ${donusTarihiAktif ? "aktif" : ""}`}
                type="time"
                id="GidisTarihi"
                name="GidisTarihi"
                value={GidisTarihi}
                onChange={(e) => setGidisTarihi(e.target.value)}
              />
              <input
                className={`return-date ${donusTarihiAktif ? "aktif" : "pasif"
                  }`}
                type="time"
                id="DonusTarihi"
                name="DonusTarihi"
                value={DonusTarihi}
                onChange={(e) => setDonusTarihi(e.target.value)}
                disabled={!donusTarihiAktif}
              />
            </div>
            <div className="company-select-modal">
              <label>Firma Seçin</label>
              <select className="select" onChange={handleChangeAgent}>
                {agents.map((item, index) => (
                  <option key={index} value={item.id}>{item.name}</option>
                ))}
              </select>
            </div>

            <div className="company-select-modal">
              <label>Feribot Seçin</label>
              <select className="select" onChange={handleChangeFerry}>
                {ferries.filter(ferry => ferry.agent_id == agent).map((item, index) => (
                  <option key={index} value={item.id}>{item.name}</option>
                ))}
              </select>
            </div>
            <div className="price-container-modal">
              <label>Fiyat</label>
              <input
                type="tel"
                id="number"
                name="number"
                value={number}
                onChange={handleChange}
                required
                placeholder="Lütfen fiyat Girin"
              />
            </div>
            {/* <ImgDrop /> */}
            <button className="add-trip-btn" style={{ marginTop: 20 }} onClick={addTour}>Tamam</button>
          </div>
        </div>
      )}

      {/* Show Modal */}
      {ShowmodalOpen && (
        <div className="show-modal">
          <div className="show-modal-content">
            <span className="close" onClick={ShowModalOff}>
              &times;
            </span>
            <p>Sefer Bilgileri</p>
            <div className="round-trip-cont">
              <p className="round-trip-item">ID:</p>
              <p className="round-trip-item">Firma:</p>
            </div>
            <div className="round-trip-cont">
              <p className="round-trip-item">Nerden:</p>
              <p className="round-trip-item">Nereye:</p>
            </div>
            <div className="round-trip-cont">
              <p className="round-trip-item">Gidiş Tarihi:</p>
              <p className="round-trip-item">Dönüş Tarihi:</p>
            </div>
            <button className="show-payment-oky-btn" onClick={ShowModalOff}>
              Tamam
            </button>
          </div>
        </div>
      )}

      {/* Update Modal */}
      {UpdatemodalOpen && (
        <div className="update-modal">
          <div className="update-modal-content">
            <span className="close" onClick={UpdateModalOff}>
              &times;
            </span>
            <p>Sefer Düzenle</p>
            <div className="update-modal-company">
              <label>Sefer Adı</label>
              <input
                type="input"
                id="name"
                name="name"
                value={tourName}
                onChange={(e) => setTourName(e.target.value)}
              />
            </div>
            <div className="update-modal-company" style={{ display: 'inline-block', width: '50%' }}>
              <label>Fiyat</label>
              <input
                type="input"
                id="price"
                name="price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
            <div className="update-modal-company" style={{ display: 'inline-block', width: '50%' }}>
              <select className="select" onChange={(e) => setAgent(e.target.value)}>
                {agents.map((item, index) => (
                  <option key={index} value={item.id} selected={agent == item.id}>{item.name}</option>
                ))}
              </select>
            </div>
            <div className="form-group-select-ticket">
              <label>Yön</label>
              <div className="one-way-two-way-container">
                <input
                  type="checkbox"
                  id="toggle"
                  className="toggleCheckbox"
                  checked={donusTarihiAktif}
                  onChange={() => setDonusTarihiAktif(!donusTarihiAktif)}
                />
                <label
                  htmlFor="toggle"
                  className="toggleContainer"
                  style={{
                    fontWeight: "500",
                  }}
                >
                  <div>Tek Yön</div>
                  <div>Gidiş Dönüş</div>
                </label>
              </div>
            </div>
            <div className="update-modal-location">
              <div className="form-group-select-ticket">
                <div className="label-container">
                  <label htmlFor="from">Nereden</label>
                </div>
                <select
                  className="select-way"
                  id="from"
                  name="from"
                  value={from}
                  onChange={(e) => {
                    setFrom(e.target.value);
                    setTo(varisLimanlari[e.target.value][0]);
                  }}
                >
                  <option value="FETHIYE">FETHİYE</option>
                  <option value="BODRUM">BODRUM</option>
                  <option value="KUSADASI">KUŞADASI</option>
                  <option value="CESME">ÇEŞME</option>
                  <option value="AYVALIK">AYVALIK</option>
                  <option value="KAS">KAŞ</option>
                  <option value="KOS">KOS</option>
                  <option value="CHIOS_SAKIZ">CHIOS-SAKIZ</option>
                  <option value="MIDILLI">MİDİLLİ</option>
                  <option value="MEIS">MEIS</option>
                  <option value="SAMOS">SAMOS</option>
                  <option value="SYMI">SYMI</option>
                  <option value="PATMOS">PATMOS</option>
                  <option value="RODOS">RODOS</option>
                  <option value="SEFERIHISAR">SEFERİHİSAR</option>
                </select>
              </div>
              <div className="form-group-select-ticket">
                <div className="label-container">
                  <label htmlFor="to">Nereye</label>
                </div>
                <select
                  className="select-way"
                  id="to"
                  name="to"
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                >
                  {/* Varış limanları */}
                  {varisLimanlari[from] &&
                    varisLimanlari[from].map((liman) => (
                      <option key={liman} value={liman}>
                        {liman}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <label>Kalkış ve Varış Saati</label>
            <div className="update-modal-time">
              <input
                className={`departure-date ${donusTarihiAktif ? "aktif" : ""}`}
                type="time"
                id="GidisTarihi"
                data-date={GidisTarihi}
                name="GidisTarihi"
                value={GidisTarihi}
                onChange={(e) => setGidisTarihi(e.target.value)}
              />
              <input
                className={`return-date ${donusTarihiAktif ? "aktif" : "pasif"
                  }`}
                type="time"
                id="DonusTarihi"
                name="DonusTarihi"
                value={DonusTarihi}
                onChange={(e) => setDonusTarihi(e.target.value)}
                disabled={!donusTarihiAktif}
              />
            </div>
            <button onClick={UpdateModalOff} className="update-modal-btn">
              <p>Güncelle</p>
            </button>
          </div>
        </div>
      )}

      {/* Delete Modal */}
      {DeletemodalOpen && (
        <div className="delete-modal">
          <div className="delete-modal-content">
            <span className="close" onClick={DeleteModalOff}>
              &times;
            </span>
            <p>Seçili Seferi Silmek İstediğinize Emin misiniz ?</p>
            <Divider />
            <div className="delete-modal-button-container">
              <button
                onClick={DeleteModalOff}
                className="delete-modal-btn"
                id="vazgec-btn"
              >
                <p>Vazgeç</p>
              </button>
              <button className="delete-modal-btn" id="sil-btn">
                <p>Sil</p>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SeferlerTable;
