import axios from "axios";
import { SERVICE_URL } from "..";
import { getItem } from "../../utils/storage";

export const Reservations = () => {
  const userData = getItem("adminUser");
  // limanların listesini getirir
  const create = (params) => {
    return axios.post(`${SERVICE_URL}/reservations`, params);
  };

  const update = (id, params) => {
    return axios.put(`${SERVICE_URL}/reservations/${id}`, params, {
      headers: {
        token: userData.token,
      },
    });
  };

  const list = () => {
    return axios.get(`${SERVICE_URL}/reservations`, {
      headers: {
        token: userData.token,
      },
    });
  };

  const details = (pnrNo) => {
    return axios.get(`${SERVICE_URL}/reservations/details/${pnrNo}`);
  };

  const createGuest = (params) => {
    return axios.post(`${SERVICE_URL}/reservationguests`, params, {
      headers: {
        token: userData.token,
      },
    });
  };
  const guests = (params) => {
    console.log("params", params);
    // return axios.get(`${SERVICE_URL}/reservation_guests`, { params });

    return axios.get(`${SERVICE_URL}/reservationguests`, {
      headers: {
        token: userData.token,
      },
    });
  };
  const updateGuest = (id, params) => {
    return axios.put(`${SERVICE_URL}/reservationguests/${id}`, params, {
      headers: {
        token: userData.token,
      },
    });
  };
  const guestsByReservation = (reservatioId, params) => {
    console.log("params", params);
    // return axios.get(`${SERVICE_URL}/reservation_guests`, { params });

    return axios.get(
      `${SERVICE_URL}/reservationguests/guests/${reservatioId}`,
      {
        headers: {
          token: userData.token,
        },
      }
    );
  };
  const deleteGuest = (guestId) => {
    console.log("guestId", guestId);
    // return axios.get(`${SERVICE_URL}/reservation_guests`, { params });

    return axios.delete(`${SERVICE_URL}/reservationguests/${guestId}`, {
      headers: {
        token: userData.token,
      },
    });
  };

  // PNR Check
  const checkPNR = (params) =>
    axios.get(`${SERVICE_URL}/reservations/check`, { params });

  const remove = (id) => {
    return axios.delete(`${SERVICE_URL}/reservations/${id}`, {
      headers: {
        token: userData.token,
      },
    });
  };

  return {
    list,
    checkPNR,
    guests,
    guestsByReservation,
    deleteGuest,
    updateGuest,
    createGuest,
    create,
    remove,
    update,
    details,
  };
};
