import { Badge, Button, Card, Col, Divider, message, Result, Row, Table, Tooltip, Typography } from "antd"
import { MainContainer } from "../../components/Layouts/MainContainer"
import { formatTourTime } from "../../utils/formatTourTime"
import { convertTourTime } from "../../utils/convertTourTime"
import dayjs from "dayjs"
import { useEffect, useRef, useState } from "react"
import { getItem, removeItem } from "../../utils/storage"
import _ from "lodash"
import { useNavigate } from "react-router-dom"
import { Ticket } from "../../services/Ticket"
import { CloseCircleOutlined, CopyOutlined } from "@ant-design/icons"

const { Paragraph, Text } = Typography;

export const PaymentSuccess = () => {
    const navigate = useNavigate()
    const ticketData = getItem("ticketData")
    const contactData = ticketData?.contactData

    const [ticketIsbooking, setTicketIsBooking] = useState(true)
    const [passengerTicket, setPassengerTicket] = useState([])
    const [errorMessage, setErrorMessage] = useState()

    const effectRan = useRef(false);

    useEffect(() => {
        if (effectRan.current === false) {
            setTicketIsBooking(true);
            // console.log("ticketData", getItem("ticketData"));
            // removeItem("ticketData");

            if (_.isEmpty(ticketData) || ticketData == null) {
                navigate('/');
            }

            Ticket().add(ticketData?.contactData, ticketData?.ticketData, ticketData?.couponData).then((response) => {
                const data = response?.data;
                // console.log("dataaa,", data);
                if (data?.data?.success && !_.isEmpty(data?.data?.result)) {
                    // console.log("bilet kesildi", data?.data);
                    setPassengerTicket(data?.data?.result);
                } else {
                    // console.log("bilet kesilemedi", data);
                    setErrorMessage(data?.message)
                }
                setTicketIsBooking(false);
            }).catch((error) => {
                console.log("error", error);
                setErrorMessage(error?.message)
                setTicketIsBooking(false);
            });

            effectRan.current = true;
        }
    }, []);

    const selectedDepartureTour = ticketData?.selectedDepartureTour
    const selectedArrivalTour = ticketData?.selectedArrivalTour

    if (ticketIsbooking) {
        return (
            <div style={{ marginTop: 65, minHeight: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <MainContainer>
                    <Result
                        status="info"
                        title="Biletiniz kesiliyor"
                        subTitle="Ödemeniz alındı. Biletiniz kesiliyor, lütfen bekleyin.."
                    />
                </MainContainer>
            </div>
        )
    }

    if (errorMessage) {
        return (
            <div style={{ marginTop: 65, minHeight: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <MainContainer>
                    <Result
                        status="error"
                        title="Biletiniz kesilemedi"
                        subTitle={errorMessage}
                    >
                        <div className="desc">
                            <Paragraph>
                                <CloseCircleOutlined className="site-result-demo-error-icon" /> Lütfen bizimle iletişime geçin
                            </Paragraph>
                        </div>
                    </Result>
                </MainContainer>
            </div>
        )
    }

    const passengerTableColumns = [
        {
            title: 'İsim',
            dataIndex: 'name',
            render: (value, record) => (
                <span style={{
                    whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'
                }}>
                    {value}
                </span>
            ),
        },
        {
            title: 'Soyisim',
            dataIndex: 'surName',
            sorter: {
                compare: (a, b) => a.chinese - b.chinese,
                multiple: 3,
            },
        },
        {
            title: 'Kalkış',
            dataIndex: 'departureRegionName',
            sorter: {
                compare: (a, b) => a.math - b.math,
                multiple: 2,
            },
        },
        {
            title: 'Varış',
            dataIndex: 'arrivalRegionName',
            sorter: {
                compare: (a, b) => a.english - b.english,
                multiple: 1,
            },
        },
        {
            title: 'Feribot',
            dataIndex: 'ferryName',
        },
        {
            title: 'Firma',
            dataIndex: 'ferryCompanyName',
        }
    ];

    const handleCopy = () => {
        navigator.clipboard.writeText(passengerTicket?.pnrNo).then(() => {
            message.success('PNR numarası kopyalandı!');
        }).catch(err => {
            message.error('Kopyalama başarısız oldu.');
        });
    };

    return (
        <div style={{ paddingTop: 65, paddingBottom: 65, backgroundColor: '#f7f7f7' }}>
            <MainContainer>
                <div style={{ marginTop: 50, minHeight: 500 }}>
                    <Row gutter={[16, 16]}>
                        <Col md={24}>
                            <Result
                                status="success"
                                title="Rezervasyonunuz başarıyla oluşturuldu!"
                                subTitle={<div>Bilet bilgileriniz e-posta adresinize gönderilecektir ({contactData?.email})</div>}
                                extra={[
                                    <div>
                                        <div style={{ padding: 20, backgroundColor: '#f8f8f8', border: '3px dotted #ddd', borderRadius: 8, display: 'inline-block' }}>
                                            <Tooltip title="Kopyala">
                                                <span
                                                    style={{ fontSize: 20, cursor: 'pointer' }}
                                                    onClick={handleCopy}
                                                >
                                                    Bilet numaranız: <b>{passengerTicket?.pnrNo}</b>
                                                </span>
                                                <Button
                                                    type="link"
                                                    icon={<CopyOutlined />}
                                                    onClick={handleCopy}
                                                    style={{ padding: 0 }}
                                                />
                                            </Tooltip>
                                        </div>
                                        <div style={{ marginTop: 32, fontSize: 16, color: '#333' }}>
                                            Bilet kesim tarihi <b>{dayjs(passengerTicket.salesDate).format('L LT')}</b>
                                        </div>
                                    </div>
                                ]}
                            />
                        </Col>
                        <Col md={24}>
                            <Card
                                title="Yolcular"
                                bordered={false}
                            >
                                <Table
                                    bordered
                                    size='small'
                                    columns={passengerTableColumns}
                                    dataSource={passengerTicket?.passengerTicket}
                                    scroll={{ x: true, scrollToFirstRowOnChange: true }}
                                    pagination={{ defaultPageSize: 20 }}
                                    expandable={{
                                        expandedRowRender: (record) => (
                                            <div>
                                                <span>
                                                    Hareket tarihi: <span style={{ fontWeight: '600' }}>{dayjs(record.serviceDate).format('L')}</span>
                                                </span>
                                                <span style={{ marginLeft: 8 }}>
                                                    Kalkış saati: <span style={{ fontWeight: '600' }}>{record.departureTime}</span>
                                                </span>
                                                <span style={{ marginLeft: 8 }}>
                                                    Varış saati: <span style={{ fontWeight: '600' }}>{record.arrivalTime}</span>
                                                </span>
                                            </div>
                                        ),
                                        rowExpandable: (record) => record.name !== 'Not Expandable',
                                    }}
                                />
                            </Card>
                        </Col>
                        {/* <Col md={24}>
                            <Card title={'Sefer Bilgileri'} style={{ marginBottom: 16 }}>
                                <Row gutter={[16, 16]}>
                                    <Col md={12}>
                                        <TourInfo wayLabel="Gidiş Seferi" />
                                    </Col>
                                    <Col md={12}>
                                        <TourInfo wayLabel="Dönüş Seferi" />
                                    </Col>
                                </Row>
                            </Card>
                            <Card title={'Rezervasyon Özeti'}>
                                <Divider />
                                <div style={{ marginBottom: 8 }}>
                                    (Gidiş)
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            1 Yetişkin
                                        </div>

                                        <div>
                                            $60
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginBottom: 16 }}>
                                    (Dönüş)
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            1 Yetişkin
                                        </div>

                                        <div>
                                            $60
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 16, fontWeight: '500' }}>
                                    <div>
                                        Ödenen tutar
                                    </div>

                                    <div>
                                        $120
                                    </div>
                                </div>
                            </Card>
                        </Col> */}
                    </Row>
                </div>
            </MainContainer>
        </div>
    )

    return (
        <div style={{ marginTop: 65 }}>
            <MainContainer>
                <div style={{ marginTop: 100, minHeight: 500 }}>
                    <Row gutter={[16, 16]}>
                        <Col md={16}>
                            <Result
                                status="success"
                                title="Rezervasyonunuz başarıyla oluşturuldu!"
                                subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
                                extra={[
                                    <Button type="primary" key="console">
                                        Go Console
                                    </Button>,
                                    <Button key="buy">Buy Again</Button>,
                                ]}
                            />
                        </Col>
                        <Col md={8}>
                            <Card title={'Sefer Bilgileri'} style={{ marginBottom: 16 }}>
                                <Row gutter={[16, 16]}>
                                    <Col md={24} style={{ textAlign: 'center' }}>
                                        <Badge
                                            className="site-badge-count-109"
                                            count={'Gidiş Seferi'}
                                            style={{ backgroundColor: '#1777ff29', color: '#1677ff', fontWeight: '600', fontSize: 12 }}
                                        />
                                    </Col>
                                    <Col md={24} xs={24} style={{ textAlign: 'center' }}>
                                        <div>
                                            {
                                                selectedDepartureTour?.ferryCompanyName
                                            }
                                            ({selectedDepartureTour?.ferryName})
                                        </div>

                                        <div className="travelTimeLabel">
                                            Sefer süresi: {formatTourTime(selectedDepartureTour?.travelTime)}
                                        </div>
                                    </Col>

                                    <Col md={12} xs={12} style={{ textAlign: 'center' }}>
                                        <div><b>Kalkış</b></div>
                                        <div className="tourTimeLabel">
                                            {dayjs(selectedDepartureTour?.serviceDate).format('DD MMMM dddd')} {convertTourTime(selectedDepartureTour?.departureTime)}
                                        </div>
                                        <div>
                                            {
                                                selectedDepartureTour?.departureRegionName
                                            }
                                        </div>
                                    </Col>

                                    <Col md={12} xs={12} style={{ textAlign: 'center' }}>
                                        <div><b>Varış</b></div>
                                        <div className="tourTimeLabel">
                                            {dayjs(selectedDepartureTour?.serviceDate).format('DD MMMM dddd')} {convertTourTime(selectedDepartureTour?.arrivalTime)}
                                        </div>
                                        <div>
                                            {
                                                selectedDepartureTour?.arrivalRegionName
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                            <Card title={'Rezervasyon Özeti'}>
                                <Divider />
                                <div style={{ marginBottom: 8 }}>
                                    (Gidiş)
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            1 Yetişkin
                                        </div>

                                        <div>
                                            $60
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginBottom: 16 }}>
                                    (Dönüş)
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            1 Yetişkin
                                        </div>

                                        <div>
                                            $60
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 16, fontWeight: '500' }}>
                                    <div>
                                        Ödenen tutar
                                    </div>

                                    <div>
                                        $120
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </MainContainer>

        </div>
    )

}