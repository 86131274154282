import React, { useState } from "react";
import { IoEyeSharp } from "react-icons/io5";
import { IoPencilSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";

const BlogEditTable = () => {
  const [deleteBlogModal, setDeleteBlogModalOpen] = useState(false);
  const DeleteBlogModalOpen = () => {
    setDeleteBlogModalOpen(true);
  };
  const DeleteBlogModalOff = () => {
    setDeleteBlogModalOpen(false);
  };

  const [editBlogModal, setEditBlogModalOpen] = useState(false);
  const EditBlogModalOpen = () => {
    setEditBlogModalOpen(true);
  };
  const EditBlogModalOff = () => {
    setEditBlogModalOpen(false);
  };
  return (
    <>
      <div className="blogedit-admin">
        <h1>Son Bloglar</h1>
      </div>
      {/*BLOG EDIT TABLE */}
      <div className="table-container-blogedit">
        <div className="table-top-container">
          <select className="select">
            <option value="1">Gelişmiş Sıralama</option>
            <option value="2">En Yeni</option>
            <option value="3">En Eski</option>
          </select>
          <Link to={"/admin/blog-add"}>
            <button className="blogedit-add-btn">
              <p>Blog Ekle</p>
            </button>
          </Link>
        </div>
        <table className="ui-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Başlık</th>
              <th>Yükleme Tarihi</th>
              <th>Güncelleme Tarihi</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Uyguna Bilet Al !</td>
              <td>2024-03-25</td>
              <td>2024-03-27</td>
              <td className="button-container">
                <button id="show-icon">
                  <IoEyeSharp className="table-icon" />
                </button>
                <button onClick={EditBlogModalOpen} id="edit-icon">
                  <IoPencilSharp className="table-icon" />
                </button>
                <button onClick={DeleteBlogModalOpen} id="delete-icon">
                  <MdDelete className="table-icon" />
                </button>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>RODOS'a çekiliş !</td>
              <td>2024-04-01</td>
              <td>2024-04-05</td>
              <td className="button-container">
                <button id="show-icon">
                  <IoEyeSharp className="table-icon" />
                </button>
                <button id="edit-icon">
                  <IoPencilSharp className="table-icon" />
                </button>
                <button id="delete-icon">
                  <MdDelete className="table-icon" />
                </button>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Nisana özel Seferler</td>
              <td>2024-04-10</td>
              <td>2024-04-15</td>
              <td className="button-container">
                <button id="show-icon">
                  <IoEyeSharp className="table-icon" />
                </button>
                <button id="edit-icon">
                  <IoPencilSharp className="table-icon" />
                </button>
                <button id="delete-icon">
                  <MdDelete className="table-icon" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Delete Blog Modal */}
      {editBlogModal && (
        <div className="show-modal">
          <div className="show-modal-content">
            <span className="close" onClick={EditBlogModalOff}>
              &times;
            </span>
            <p>Blog Başlığı</p>
            <div className="delete-modal-button-container">
              <input
                type="text"
                placeholder="Postunuzun Yeni Başlığı..."
              ></input>
            </div>
            <textarea
              className="text-area-blog"
              placeholder="Postunuzun Yeni Açıklaması..."
              style={{
                width: "100%",
                border: "1px solid #ccc",
                borderRadius: "5px",
                fontFamily: "Poppins",
              }}
            ></textarea>
            <button className="upload-blog-btn">
              <p>Güncelle</p>
            </button>
          </div>
        </div>
      )}

      {/* Delete Blog Modal */}
      {deleteBlogModal && (
        <div className="show-modal">
          <div className="show-modal-content">
            <span className="close" onClick={DeleteBlogModalOpen}>
              &times;
            </span>
            <p>Blogu Silmek İstediğinize Eminmisiniz ?</p>
            <div className="delete-modal-button-container">
              <button
                onClick={DeleteBlogModalOff}
                className="delete-modal-btn"
                id="vazgec-btn"
              >
                <p>Vazgeç</p>
              </button>
              <button className="delete-modal-btn" id="sil-btn">
                <p>Sil</p>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BlogEditTable;
