import axios from "axios";
import { SERVICE_URL } from "..";

export const Ticket = () => {
  const add = (contactData, params, couponData) => {
    const phone = contactData?.phone;
    const email = contactData?.email;
    const couponId = couponData?.id;
    const couponIdQuery = couponData?.id ? `&CouponId=${couponData?.id}` : "";
    return axios.post(
      `${SERVICE_URL}/Ferries/AddFerriesTicket?Gsm=${phone}&email=${email}${couponIdQuery}`,
      params
    );
  };

  const check = (params) =>
    axios.get(`${SERVICE_URL}/Ferries/CheckTicketWithPnrAndEmail`, { params });

  return {
    add,
    check,
  };
};
