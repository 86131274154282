import axios from "axios";
import { SERVICE_URL } from "..";

export const PriceCalculate = () => {
  const calculate = (params) => {
    console.log("params", params);
    return axios.get(`${SERVICE_URL}/Common/CalculateTicketPrice`, { params });
  };

  return {
    calculate,
  };
};
