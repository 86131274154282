import { Card, Col, DatePicker, Form, Input, Row, Select } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import { useState } from "react";
import { countries } from "../../../../../../utils/countries";
import { genders } from "../../../../../../utils/genders";
import locale from "antd/es/locale/tr_TR";

export const useFormHelper = (form) => {
  const countryOptions = countries.map((country) => ({
    label: country.name,
    value: country.code.toLowerCase(),
  }));

  const genderOptions = genders.map((gender) => ({
    label: gender.name,
    value: gender.id,
  }));

  const PassengerForm = ({ passengers, customerTypeName, formName }) => {
    const initialBirthDate = dayjs("1990-01-01");
    const [formData, setFormData] = useState(
      Array.from({ length: passengers.length }, () => ({
        name: "",
        surname: "",
        nationality: "Türk",
        national_id: "",
        passport_number: "",
        gender: "male",
        birth_date: initialBirthDate,
        passport_expiry_date: "",
        invoice_type: "individual",
        tax_number: "",
        tax_office: "",
        company_name: "",
      }))
    );

    const handleChange = (e, index) => {
      const { name, value } = e.target;

      setFormData((prevFormData) => {
        const newData = [...prevFormData];
        newData[index] = {
          ...newData[index],
          [name]: value,
        };
        return newData;
      });
    };

    const handleChangeSelect = (value, name, index) => {
      setFormData((prevFormData) => {
        const newData = [...prevFormData];
        newData[index] = {
          ...newData[index],
          [name]: value,
        };
        return newData;
      });
    };

    if (_.isEmpty(passengers)) {
    }
    return (
      <Form form={form} layout="vertical" name={formName}>
        {!_.isEmpty(passengers) &&
          passengers.map((data, index) => (
            <Card
              style={{ marginTop: 16 }}
              title={
                <div>
                  <span style={{ fontSize: 14 }}>
                    {" "}
                    {index + 1}. {customerTypeName} Yolcu Bilgileri
                  </span>
                </div>
              }
            >
              <div key={index}>
                <div style={{}}>
                  <Row gutter={[16, 16]}>
                    <Col md={8} sm={12} xs={12}>
                      <Form.Item
                        label="Ad"
                        name={[formName, `name-${index}`]}
                        rules={[
                          { required: true, message: "Lütfen isim girin" },
                        ]}
                        initialValue={""}
                      >
                        <Input
                          name={`name-${index}`}
                          placeholder="İsim girin"
                          onChange={(e) => handleChange(e, index)}
                          value={formData[index].name}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={12}>
                      <Form.Item
                        label="Soyad"
                        name={[formName, `surname-${index}`]}
                        rules={[
                          { required: true, message: "Lütfen soyisim girin" },
                        ]}
                        initialValue={""}
                      >
                        <Input
                          name={`surname-${index}`}
                          placeholder="Soyisim girin"
                          onChange={(e) => handleChange(e, index)}
                          value={formData[index].surname || "tst"}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={12}>
                      <Form.Item
                        label="Doğum Tarihi"
                        name={[formName, `birth_date-${index}`]}
                        rules={[
                          {
                            required: true,
                            message: "Lütfen doğum tarihi girin",
                          },
                        ]}
                        initialValue={initialBirthDate}
                      >
                        <DatePicker
                          locale={locale}
                          onChange={(date) =>
                            handleChangeSelect(
                              dayjs(date).format("YYYY-MM-DD"),
                              "birth_date",
                              index
                            )
                          }
                          value={
                            dayjs(formData[index].birth_date) ||
                            dayjs().format("YYYY-MM-DD")
                          }
                          format="YYYY-MM-DD"
                          style={{ height: 56, width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={6} sm={12} xs={12}>
                      <Form.Item
                        label="Uyruk"
                        name={[formName, `nationality-${index}`]}
                        rules={[
                          { required: true, message: "Lütfen uyruk seçin" },
                        ]}
                        initialValue={"tur"}
                      >
                        <Select
                          showSearch
                          optionFilterProp="label"
                          onChange={(value) => {
                            console.log("countryesdf", value);
                            handleChangeSelect(value, "nationality", index);
                          }}
                          style={{ height: 56, width: "100%" }}
                          value={formData[index].nationality || "tur"}
                          options={countryOptions}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={6} sm={12} xs={9}>
                      <Form.Item
                        label="Cinsiyet"
                        name={[formName, `gender-${index}`]}
                        rules={[
                          { required: true, message: "Lütfen cinsiyet seçin" },
                        ]}
                        initialValue={1}
                      >
                        <Select
                          onChange={(value) =>
                            handleChangeSelect(value, "gender", index)
                          }
                          value={formData[index].gender || 1}
                          placeholder="Cinsiyet seçin"
                          style={{ height: 56, width: "100%" }}
                          options={genderOptions}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={12} xs={15}>
                      <Form.Item
                        label="Kimlik/Pasaport No"
                        name={[formName, `national_id-${index}`]}
                        rules={[
                          {
                            required: true,
                            message: "Lütfen kimlik/pasaport numarası girin",
                          },
                        ]}
                      >
                        <Input
                          name={`national_id-${index}`}
                          placeholder="Kimlik/Pasaport numarası girin"
                          onChange={(e) => handleChange(e, index)}
                          value={formData[index].national_id}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
          ))}
      </Form>
    );
  };

  return {
    PassengerForm,
  };
};
