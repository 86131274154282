import { Badge, Col, Row } from "antd"
import { formatTourTime } from "../../../../../utils/formatTourTime"
import dayjs from "dayjs"
import { convertTourTime } from "../../../../../utils/convertTourTime"
import '../styles.css'

export const TourInfo = ({
    selectedTour,
    wayLabel
}) => {

    return (
        <Row gutter={[16, 16]}>
            <Col md={24} style={{ textAlign: 'center' }}>
                <Badge
                    className="site-badge-count-109"
                    count={wayLabel}
                    style={{ backgroundColor: '#1777ff29', color: '#1677ff', fontWeight: '600', fontSize: 12 }}
                />
            </Col>
            <Col md={24} xs={24} style={{ textAlign: 'center' }}>
                <div>
                    {
                        selectedTour?.ferryCompanyName
                    }
                    ({selectedTour?.ferryName})
                </div>

                <div className="travelTimeLabel">
                    Sefer süresi: {formatTourTime(selectedTour?.travelTime)}
                </div>
            </Col>

            <Col md={12} xs={12} style={{ textAlign: 'center' }}>
                <div><b>Kalkış</b></div>
                <div className="tourTimeLabel">
                    {dayjs(selectedTour?.serviceDate).format('DD MMMM dddd')} {convertTourTime(selectedTour?.departureTime)}
                </div>
                <div>
                    {
                        selectedTour?.departureRegionName
                    }
                </div>
            </Col>

            <Col md={12} xs={12} style={{ textAlign: 'center' }}>
                <div><b>Varış</b></div>
                <div className="tourTimeLabel">
                    {dayjs(selectedTour?.serviceDate).format('DD MMMM dddd')} {convertTourTime(selectedTour?.arrivalTime)}
                </div>
                <div>
                    {
                        selectedTour?.arrivalRegionName
                    }
                </div>
            </Col>
        </Row>
    )
}