import axios from "axios";
import { SERVICE_URL } from "..";

export const Payment = () => {
  const getPaymentToken = (params) =>
    axios.get(`${SERVICE_URL}/Payment/get-token-pay-tr`, params);

  const getPaymentTokenPost = (params) =>
    axios.post(
      `${SERVICE_URL}/payment/get-paytr-token`,
      { ...params },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

  return {
    getPaymentToken,
    getPaymentTokenPost,
  };
};
