import React from 'react'
import '../pages/Css/HakkimizdaPage.css'
import { TabTitle } from "../tools/GeneralFunction";
import { CommonHeader } from '../components/CommonHeader';
import { Helmet } from 'react-helmet';

const AydinlatmaMetni = () => {
  const title = "Aydınlatma Metni"
  TabTitle(`${title} - Uygun Feribot | Yunan Adaları Feribot Bileti Satın Al`);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <CommonHeader title={title} />
      <div className='MetinContainerHakkimizda'>
        <p>
          KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİN AYDINLATMA METNİ
          TANIMLAR
          İşbu aydınlatma metninde geçen; Kişisel Veri: Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi,
          Kişisel Verilerin Korunması Kanunu (“KVKK”): 7 Nisan 2016 tarihinde Resmi Gazete’de yayınlanarak yürürlüğe giren 6698 sayılı Kişisel Verilerin Korunması Kanunu’nu,
          Uygun Feribot: mukim şirketi
          Veri İşleyen: Veri sorumlusunun verdiği yetkiye dayanarak onun adına Kişisel Verileri işleyen gerçek veya tüzel kişiyi,
          Veri Sorumlusu: Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel kişiyi ifade eder.
          AYDINLATMA METNİNİN AMACI VE ŞİRKETİMİZİN VERİ SORUMLUSU KONUMU
          Şirketimiz Uygun Feribot, 6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında iş bu Aydınlatma Metni ile müşterilerin şirketimiz tarafından gerçekleştirilen kişisel veri işleme faaliyetleri hakkında aydınlatılması hedeflenmektedir. KVKK uyarınca şirketimiz veri sorumlusu sıfatını haiz olmayıp müşterilerin herhangi bir kişisel verisini depolamamaktadır. Kişisel veriler depolanmadığı için de şirketimizde bir veri merkezi bulunmamaktadır.
          KİŞİSEL VERİLERİN İŞLENME AMACI
          KVKK’nın 4., 5. ve 6. maddeleri uyarınca kişisel verileriniz;
          • Hukuka ve dürüstlük kurallarına uygun • Doğru ve gerektiğinde güncel • Belirli, açık ve meşru amaçlar için • İşlendikleri amaçla bağlantılı, sınırlı ve ölçülü • İlgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar muhafaza edilme kurallarına uygun bir şekilde Uygun Feribot’in aşağıda yer alan çalışma alanları ile bağlantılı olacak şekilde işlenecektir.
          Uygun Feribot’in çalışma alanları;
          • Şirketimiz tarafından sunulan ürün ve hizmetlerden ilgili kişileri faydalandırmak için gerekli çalışmaların iş birimleri tarafından yapılması • Üretmiş olduğumuz ürünlerin internet aracılığıyla müşterilerle buluşmasını sağlamak.
          KİŞİSEL VERİLERİN AKTARIMI
          Şirketimiz, kişisel verilerinizi yurt içi ve yurt dışındaki iş ortaklarımız, üye iş yerlerimiz, bankalar, finansal kuruluşlar, bağımsız denetim kuruluşları vb. Ödeme ve Menkul Kıymet Mutabakat Sistemleri,
          Ödeme Hizmetleri ve Elektronik Para Kuruluşları Hakkında Kanun ve diğer mevzuat hükümlerinin izin verdiği kişi ve kuruşlar ile paylaşabilir. Saklanan veriler, KVK Kanunu’ nun 8. ve 9. Maddelerinde belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde aktarılabilecektir.
          Şirketimizin müşterileri ile gerçekleştirdiği işlemlere ilişkin kayıt ve belgelerin yasal düzenlemeler kapsamında belirli bir süre saklanması söz konusu olup kişisel verilerinizin silinmesini istemeniz halinde bu talebiniz, yasal düzenlemeler ile belirlenen süre sonuna kadar yerine getirilebilecek, bu süreç içerisinde kişisel verileriniz yasal düzenlemelerden kaynaklı zorunluluklar haricinde işlenmeyecek ve üçüncü kişiler ile paylaşılmayacaktır.
          KİŞİSEL VERİLERİN TOPLANMA YÖNTEMİ VE HUKUKİ SEBEBİ
          Kişisel verileriniz, şirketimiz tarafından;
          • Kişisel verileriniz, her türlü sözlü, yazılı ya da elektronik ortamda, yukarıda yer verilen amaçlar doğrultusunda Şirketçe sunduğumuz ürün ve hizmetlerin belirlenen yasal çerçevede sunulabilmesi ve bu kapsamda Şirketimizin sözleşme ve yasadan doğan mesuliyetlerini eksiksiz ve doğru bir şekilde yerine getirebilmesi gayesi ile edinilir.
          • Sunulan hizmetlerin iyileştirilmesi, yeni hizmetlerin geliştirilmesi ve bununla ilgili bilgilendirme yapılması • Ticari elektronik ileti onayı mevcut müşteriler ve aday müşteriler açısından; kampanya ve hizmetlerin tanıtımı ile pazarlamasının yapılması,
          • Müşteri sorun ve şikâyetlerinin çözümlenmesi,
          • İstatistiksel değerlendirmeler ve pazar araştırmaları yapılması,
          • Şirketin ticari ve iş stratejilerinin belirlenmesi ve uygulanması,
          • Üye iş yerleri ve iş ortakları ile ilişkilerin yönetilmesi,
          • Muhasebe ve ödeme işlemlerinin takibi,
          • Hukuki süreçler ve mevzuata uyum,
          • İdari ve adli makamlardan gelen bilgi taleplerinin cevaplandırılması,
          • Şirket içi raporlama ve iş geliştirme faaliyetlerinin planlanması
          • Finansal kontrol ve raporlamaların yapılarak yasal bildirimlerin gerçekleştirilmesi,
          • İç kontrol ve denetim faaliyetlerinin yönetilmesi,
          • Bilgi ve işlem güvenliği sağlanması ve kötü amaçlı kullanımın önlenmesi,
          • İşlenen verilerin güncel ve doğru olmasının sağlanması amacıyla gerekli düzenlemelerin yapılması ve sayılan tüm bu süreçlere ilişkin faaliyetleri gerçekleştirmek amacıyla kullanılmaktadır.
          Bu gibi kanallarla ve farklı hukuki sebeplere dayanarak şirketimizin çalışmalarını sürdürülebilmesi için KVKK tarafından öngörülen temel ilkelere uygun olarak, KVKK’nın 5. Ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları kapsamında iş bu Aydınlatma Metninde belirtilen amaçlarla da veri toplanabilmekte, işlenebilmekte ve aktarılabilmektedir.
          KİŞİSEL VERİ SAHİBİNİN HAKLARI
          KVKK’nın 11. maddesi uyarınca herkes, veri sorumlusuna başvurarak kendisiyle ilgili;
          a) Kişisel verilerinin işlenip işlenmediğini öğrenme, b) Kişisel verileri işlenmişse buna ilişkin bilgi talep etme, c) Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme, ç) Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme, d) Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme, e) KVKK 7. maddede öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok edilmesini isteme,
          f) (d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme, g) İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,
          ğ) Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme, haklarına sahiptir.
          Yukarıda belirtilen haklarınız ile ilgili şirketimize yazılı olarak başvurmanız halinde, talebinizin niteliğine göre en geç 30 (otuz) gün içerisinde ücretsiz olarak yanıt verilecektir. Başvurunuzu açık, anlaşır bir şekilde ve kimlik ve adres bilgilerinizi tespit edici belgeleri de ekleyerek, yazılı ve ıslak imzalı olarak elden, postayla ya da noter kanalıyla ulaştırmanız gerekmektedir.
        </p>
      </div>
    </>
  )
}

export default AydinlatmaMetni