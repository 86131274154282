import { Steps, Form, Row, Col, message } from "antd";
import { DollarOutlined, SolutionOutlined, IdcardOutlined } from '@ant-design/icons';
import './styles.css';
import { useEffect, useState } from "react";
import { BillingInformations } from "./BillingInformations";
import { PaymentStep } from "./PaymentStep";
import { MainContainer } from "../../../../components/Layouts/MainContainer";
import { PassengerStep } from "./PassengerStep";
import { SummarySection } from "../SummarySection";
import { scrollToTop } from "../../../../utils/scrollToTop"
import { Payment } from "../../../../services/Payment";
import { setItem } from "../../../../utils/storage";
import { find } from "lodash";
import { countries } from "../../../../utils/countries";
import { genders } from "../../../../utils/genders";
import { SITE_URL } from "../../../../services";
import { customerTypes } from "../../../../utils/customerTypes";

export const ReservationSteps = ({
    reservationData,
    selectedDepartureTour,
    selectedArrivalTour
}) => {
    const [current, setCurrent] = useState(0);

    const [loadingPaymentStep, setLoadingPaymentStep] = useState(false);
    const [iframeToken, setIframeToken] = useState()

    const [form] = Form.useForm();
    const [formData, setFormData] = useState({
        contact: {},
        passenger: [],
        childs: [],
        adults: [],
        infants: [],
        billing: {},
    });

    const [totalPrice, setTotalPrice] = useState(reservationData?.passengerPrices.reduce((sum, item) => sum + item.totalAmount, 0))
    const [usedCouponData, setUsedCouponData] = useState([])
    const [confirmedPrivacyPolicy, setConfirmedPrivacyPolicy] = useState(false)

    const transformPassengerData = (passenger, formValues, customerType) => {
        const result = passenger.map((_, index) => {
            const findCountry = find(countries, ({ code }) => code.toLowerCase() == formValues[`nationality-${index}`])
            const findGender = find(genders, ({ id }) => id == formValues[`gender-${index}`])

            const resultData = {
                name: formValues[`name-${index}`],
                surName: formValues[`surname-${index}`],
                gsm: formValues[`phone-${index}`],
                email: formValues[`email-${index}`],
                birthDate: formValues[`birth_date-${index}`],
                gender: formValues[`gender-${index}`],
                genderName: findGender.name,
                nationality: formValues[`nationality-${index}`],
                national_id: formValues[`national_id-${index}`],
                citizenshipID: formValues[`national_id-${index}`],
                passportNo: "",
                countryID: findCountry.id,
                countryCode: findCountry.code,
                countryName: findCountry.name,
                customerTypeName: customerType?.name,
                customerTypeID: customerType?.id,
                accountingCode: "303030",
                birthPlace: "",
                email: "",
                gsm: ""
            }

            return resultData
        });
        return result
    }

    const next = () => {
        scrollToTop()
        form.validateFields()
            .then((values) => {
                const adultFormValues = values['adults']
                const childFormValues = values['childs']
                const infantFormValues = values['infants']

                const adultResult = transformPassengerData(reservationData?.passengers?.adults, adultFormValues, customerTypes[0])
                const childResult = transformPassengerData(reservationData?.passengers?.childs, childFormValues, customerTypes[1])
                const infantResult = transformPassengerData(reservationData?.passengers?.infants, infantFormValues, customerTypes[2])

                const updatedFormData = { ...formData, ...values, adults: adultResult, childs: childResult, infants: infantResult };
                setFormData(updatedFormData);
                setCurrent(current + 1);
                // console.log("updatedFormData", updatedFormData);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
                scrollToTop()
                message.error('Lütfen tüm bilgileri eksiksiz doldurun!');
            });
    }

    const prev = () => {
        scrollToTop()
        setCurrent(current - 1);
    };

    const payNow = async () => {
        scrollToTop()
        form.validateFields()
            .then(async (values) => {
                if (!confirmedPrivacyPolicy) {
                    message.error('Devam edebilmek için Gizlilik Sözleşmesini kabul etmelisiniz.');
                    return;
                }
                const updatedFormData = { ...formData, ...values };
                await setLoadingPaymentStep(true)
                await setCurrent(2)

                const currencyTypeID = reservationData?.passengerPrices[0].currencyTypeID;

                let basket = []
                reservationData?.passengerPrices.map((data) => {
                    basket.push([
                        `${data?.routeName} - Feribot Bileti`, `${data?.totalAmount}`, 1
                    ])
                })

                basket = JSON.stringify(basket);

                const userFullName = updatedFormData.contact.name + " " + updatedFormData.contact.surname
                const userEmail = updatedFormData.contact.email
                const userPhone = updatedFormData.contact.phone

                const paymentParams = {
                    "user_ip": "null",
                    "email": userEmail,
                    "payment_amount": (totalPrice * 100),
                    "currency": "EUR",
                    "currency_type": currencyTypeID,
                    "user_basket": basket,
                    "no_installment": 0,
                    "max_installment": 0,
                    "user_name": userFullName,
                    "user_address": "null",
                    "merchant_ok_url": `${SITE_URL}/bilet-al/odeme-basarili`,
                    "user_phone": userPhone,
                    "paytr_token": "null",
                    "merchant_oid": "null",
                    "merchant_fail_url": `${SITE_URL}/bilet-al/odeme-basarisiz`,
                    "test_mode": 0,
                    "debug_on": 0,
                    "timeout_limit": 0,
                    "lang": "tr"
                }

                const customer = [
                    ...updatedFormData?.adults,
                    ...updatedFormData?.childs,
                    ...updatedFormData?.infants
                ]
                const ticketData = {
                    ticketSearch: reservationData?.ticketSearch,
                    customer,
                    langCode: "TR",
                    salesWebUrlReferrer: "uygunferibot.com",
                    salesID: 0,
                    lastUserID: 0,
                    isTest: false
                }
                setItem("ticketData", { ticketData, contactData: updatedFormData?.contact, couponData: usedCouponData })

                await Payment().getPaymentTokenPost(paymentParams).then(async (response) => {
                    setLoadingPaymentStep(false)
                    // console.log("response", response.data);
                    setIframeToken(response?.data?.data)
                })
                // .finally(async () => {
                //     // finally den kaldır
                //     await setTimeout(() => {
                //         navigate('/bilet-al/odeme-basarili')
                //     }, 3000)
                // })
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
                message.error('Lütfen tüm bilgileri eksiksiz doldurun!');
            });
    }

    const steps = [
        {
            title: 'Yolcu Bilgileri',
            icon: <SolutionOutlined />,
            content: <PassengerStep passengers={reservationData?.passengers} form={form} />
        },
        {
            title: 'Fatura Bilgileri',
            icon: <IdcardOutlined />,
            content: <BillingInformations form={form} formData={formData} confirmedPrivacyPolicy={confirmedPrivacyPolicy} setConfirmedPrivacyPolicy={setConfirmedPrivacyPolicy} />
        },
        {
            title: 'Ödeme',
            icon: <DollarOutlined />,
            content: <PaymentStep loadingPaymentStep={loadingPaymentStep} iframeToken={iframeToken} />
        },
    ]

    useEffect(() => {
        scrollToTop()
    }, [])

    return (
        <div style={{ paddingTop: 64, height: '100%', paddingBottom: 100, backgroundColor: '#f9f9f9' }}>
            <MainContainer>
                <Row gutter={[32, 32]}>
                    <Col md={16} xs={24}>
                        <div className="reservation-steps-container">
                            <Steps
                                current={current}
                                items={steps}
                            />

                            <div style={{ marginTop: 24 }}>{steps[current].content}</div>
                        </div>
                    </Col>

                    <Col md={8} xs={24}>
                        <SummarySection
                            reservationData={reservationData}
                            selectedDepartureTour={selectedDepartureTour}
                            selectedArrivalTour={selectedArrivalTour}
                            current={current}
                            onPrev={prev}
                            onNext={next}
                            payNow={payNow}
                            totalPrice={totalPrice}
                            setTotalPrice={setTotalPrice}
                            usedCouponData={usedCouponData}
                            setUsedCouponData={setUsedCouponData}
                            form={form}
                        />
                    </Col>
                </Row>
            </MainContainer>
        </div>
    )
}
