import React, { useState, useEffect } from "react";
import "../Admin/Css/BlogAdd.css";
import RichTextEditor from "./TextEditor";

const BlogAdd = () => {
  const [uploadModal, setUploadModalOpen] = useState(false);
  const UploadModalOpen = () => {
    setUploadModalOpen(true);
  };
  const UploadModalOff = () => {
    setUploadModalOpen(false);
  };


  const [imageUrl, setImageUrl] = useState('');
  
  const handleInputChange = (event) => {
    setImageUrl(event.target.value);
  };

  return (
    <>
      <div className="blogadd-banner">
        <div className="blogadd-banner-text">
          <h1>Blog Oluşturun</h1>
        </div>
        <div className="blogadd-admin"></div>
      </div>
      <RichTextEditor />
      <div className="img-upload-container">
      <label htmlFor="imageURL">Resim URL'si:</label>
      <input type="text" id="imageURL" name="imageURL" onChange={handleInputChange} value={imageUrl} placeholder="Postunuza Resim Yükleyin..." />
      <div id="imageContainer">
        {imageUrl && <img src={imageUrl} alt="Resim" />}
      </div>
    </div>
      <button onClick={UploadModalOpen} className="upload-blog-btn">
        Yükle
      </button>

      {/* Show Modal */}
      {uploadModal && (
        <div className="show-modal">
          <div className="show-modal-content">
            <span className="close" onClick={UploadModalOff}>
              &times;
            </span>
            <p>Blogu Yüklemek İstediğinize Eminmisiniz ?</p>
            <div className="delete-modal-button-container">
              <button
                onClick={UploadModalOff}
                className="delete-modal-btn"
                id="vazgec-btn"
              >
                <p>Vazgeç</p>
              </button>
              <button className="delete-modal-btn" id="sil-btn">
                <p>Yükle</p>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BlogAdd;
