import React, { useState } from "react";
import { Divider } from "antd";
import { TfiReceipt } from "react-icons/tfi";
import "../Payments/PaymentsTable.css";

const PaymentsTable = () => {
  const [showPaymentModal, setShowPaymentModalOpen] = useState(false);
  const ShowPaymentModalOpen = () => {
    setShowPaymentModalOpen(true);
  };
  const ShowPaymentModalOff = () => {
    setShowPaymentModalOpen(false);
  };
  return (
    <>
      <div className="blogedit-admin">
        <h1>Son Rezervasyonlar</h1>
      </div>
      {/* ODEMELER TABLE */}
      <div className="table-container-blogedit">
        <div className="table-top-container">
          <select className="select">
            <option value="1">Gelişmiş Sıralama</option>
            <option value="2">En Yeni</option>
            <option value="3">En Eski</option>
          </select>
        </div>
        <table className="ui-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Ad</th>
              <th>Soyad</th>
              <th>Tarih</th>
              <th>Bilet Fiyatı</th>
              <th>Ödeme</th>
              <th>Dekont</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>FURKAN</td>
              <td>HIDIR</td>
              <td>27.03.2024</td>
              <td>80€</td>
              <td>Başarılı</td>
              <td className="button-container">
                <button onClick={ShowPaymentModalOpen} id="show-icon">
                  <TfiReceipt className="table-icon" />
                </button>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>EMILIA</td>
              <td>ROSALITA</td>
              <td>27.03.2024</td>
              <td>65€</td>
              <td>Başarılı</td>
              <td className="button-container">
                <button id="show-icon">
                  <TfiReceipt className="table-icon" />
                </button>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>AHMED</td>
              <td>SÖNMEZ</td>
              <td>27.03.2024</td>
              <td>70€</td>
              <td>Başarılı</td>
              <td className="button-container">
                <button id="show-icon">
                  <TfiReceipt className="table-icon" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Delete Blog Modal */}
      {showPaymentModal && (
        <div className="show-modal">
          <div className="show-modal-content">
            <span className="close" onClick={ShowPaymentModalOff}>
              &times;
            </span>
            <p>Ödeme Bilgileri</p>
            <Divider />
            <div className="round-trip-cont">
              <p className="round-trip-item">
                ID:
                <span>&nbsp;1</span>
              </p>
              <p className="round-trip-item">
                PNR:
                <span>&nbsp;31F62EC93</span>
              </p>
            </div>
            <div className="round-trip-cont">
              <p className="round-trip-item">
                Ad:
                <span>&nbsp;FURKAN</span>
              </p>
              <p className="round-trip-item">
                Soyad:
                <span>&nbsp;HIDIR</span>
              </p>
            </div>
            <div className="round-trip-cont">
              <p className="round-trip-item">
                Bilet Fiyatı:<span>&nbsp;80€</span>
              </p>
              <p className="round-trip-item">
                Ödeme Tarihi:
                <span>&nbsp;27.03.2024</span>
              </p>
            </div>
            <button
              className="show-payment-oky-btn"
              onClick={ShowPaymentModalOff}
            >
              Tamam
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentsTable;
