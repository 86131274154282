import axios from "axios";
import { SERVICE_URL } from "..";
import { getItem } from "../../utils/storage";

export const Stats = () => {
  const userData = getItem("adminUser");
  const dailyRevenue = () => {
    return axios.get(`${SERVICE_URL}/stats/daily_revenue`, {
      headers: {
        token: userData.token,
      },
    });
  };

  const weeklyRevenue = () => {
    return axios.get(`${SERVICE_URL}/stats/weekly_revenue`, {
      headers: {
        token: userData.token,
      },
    });
  };

  const monthlyRevenue = () => {
    return axios.get(`${SERVICE_URL}/stats/monthly_revenue`, {
      headers: {
        token: userData.token,
      },
    });
  };

  const dailyReservationsCount = () => {
    return axios.get(`${SERVICE_URL}/stats/daily_reservations_count`, {
      headers: {
        token: userData.token,
      },
    });
  };

  const weeklyReservationsCount = () => {
    return axios.get(`${SERVICE_URL}/stats/weekly_reservations_count`, {
      headers: {
        token: userData.token,
      },
    });
  };

  const monthlyReservationsCount = () => {
    return axios.get(`${SERVICE_URL}/stats/monthly_reservations_count`, {
      headers: {
        token: userData.token,
      },
    });
  };

  return {
    dailyRevenue,
    weeklyRevenue,
    monthlyRevenue,
    dailyReservationsCount,
    weeklyReservationsCount,
    monthlyReservationsCount,
  };
};
