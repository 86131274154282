import React, { useState, useEffect } from "react";
import { GiTicket } from "react-icons/gi";
/*import HandPng from "../Admin/images/hand-png.png";*/
import { FaEuroSign } from "react-icons/fa6";
import "../Admin/Css/index.css";
import PaymentsTable from "./Table/Payments/PaymentsTable";
import { Token } from "../../services/Token";
import { getItem } from "../../utils/storage";
import { useNavigate } from "react-router-dom";
import { Stats } from "../../services/Stats";
import { Card, Flex, Select, Space, Typography } from "antd";

const { Title } = Typography;

export const Admin = () => {
  const [isLogged, setIsLogged] = useState(false)
  const navigate = useNavigate()


  const { dailyRevenue, weeklyRevenue, monthlyRevenue, dailyReservationsCount, weeklyReservationsCount, monthlyReservationsCount } = Stats()

  const [revenue, setRevenue] = useState("0.00");
  const [reservationsCount, setReservationsCount] = useState("0");

  useEffect(() => {
    const userData = getItem("adminUser");
    console.log("userData", userData)
    if (userData) {
      Token().check({
        token: userData?.token
      }).then((response) => {
        // başarılıysa oturum açık demektir.
        if (response.data.status == "success") {
          setIsLogged(true)
        } else {
          // navigate('/admin/login')
          setIsLogged(false)
        }
      }).catch(error => {
        // navigate('/admin/login')
        setIsLogged(false)
      })
    } else {
      // navigate('/admin/login')
      setIsLogged(false)
    }

    dailyRevenue().then((response) => {
      if (response.data.status == "success") {
        setRevenue(response.data.data.daily_revenue)
      }
    }).catch(error => { })

    dailyReservationsCount().then((response) => {
      if (response.data.status == "success") {
        setReservationsCount(response.data.data.daily_reservations_count)
      }
    }).catch(error => { })
  }, [])

  const handleChangeReservationsCount = (value) => {
    console.log(" value", value);
    if (value == "daily") {
      dailyReservationsCount().then((response) => {
        if (response.data.status == "success") {
          setReservationsCount(response.data.data.daily_reservations_count)
        }
      }).catch(error => { })
    } else if (value == "weekly") {
      weeklyReservationsCount().then((response) => {
        if (response.data.status == "success") {
          setReservationsCount(response.data.data.weekly_reservations_count)
        }
      }).catch(error => { })
    } else if (value == "monthly") {
      monthlyReservationsCount().then((response) => {
        if (response.data.status == "success") {
          setReservationsCount(response.data.data.monthly_reservations_count)
        }
      }).catch(error => { })
    }
  }

  const handleChangeRevenue = (value) => {
    if (value == "daily") {
      dailyRevenue().then((response) => {
        if (response.data.status == "success") {
          setRevenue(response.data.data.daily_revenue)
        }
      }).catch(error => { })
    } else if (value == "weekly") {
      weeklyRevenue().then((response) => {
        if (response.data.status == "success") {
          setRevenue(response.data.data.weekly_revenue)
        }
      }).catch(error => { })
    } else if (value == "monthly") {
      monthlyRevenue().then((response) => {
        if (response.data.status == "success") {
          setRevenue(response.data.data.monthly_revenue)
        }
      }).catch(error => { })
    }
  }

  return (
    <>
      <div className="blogedit-banner">
        <h1>Merhaba, <span>Admin</span></h1>
      </div>
      <div className="container-admin">
        <Space size={'large'} style={{ width: '100%' }} className="statsSpacer">
          <Card
            title="Ciro"
            extra={
              <Select
                defaultValue="daily"
                onChange={handleChangeRevenue}
                options={[
                  { value: 'daily', label: 'Günlük' },
                  { value: 'weekly', label: 'Haftalık' },
                  { value: 'monthly', label: 'Aylık' },
                ]}
              />
            }
            style={{ width: '100%' }}
          >
            <h1>€{revenue}</h1>
          </Card>

          <Card
            title="Satılan Bilet"
            extra={
              <Select
                defaultValue="daily"
                onChange={handleChangeReservationsCount}
                options={[
                  { value: 'daily', label: 'Günlük' },
                  { value: 'weekly', label: 'Haftalık' },
                  { value: 'monthly', label: 'Aylık' },
                ]}
              />
            }
            style={{ width: '100%' }}
          >
            <h1>{reservationsCount}</h1>
          </Card>
        </Space>
        {/* <div className="card-info">
          <div className="card-info-text-container">
            <p>Satılan Bilet</p>
            <GiTicket className="card-info-icon" />
          </div>
          <h4>3</h4>
        </div> */}
        {/* <div className="card-info">
          <div className="card-info-text-container">
            <p>Aylık Ciro</p>
            <FaEuroSign className="card-info-icon" />
          </div>
          <span>30 Günlük :</span>
          <h4>520.000 TL</h4>
        </div> */}
        {/* <div className="card-info">
          <div className="card-info-text-container">
            <p>Firma</p>
            <GiTicket className="card-info-icon" />
          </div>
          <h4>3</h4>
        </div> */}
      </div>
      {/* <PaymentsTable /> */}
    </>
  );
};
