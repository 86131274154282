import React, { useState } from "react";
import "../Admin/Css/BlogEdit.css";
import { IoEyeSharp } from "react-icons/io5";
import { IoPencilSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import RichTextEditor from "./TextEditor";
import TextArea from "antd/es/input/TextArea";
import BlogEditTable from "./Table/Blog/BlogEdit/BlogEditTable";

const BlogEdit = () => {
  return (
    <>
      <div className="blogedit-banner">
        <div className="blogedit-banner-text">
          <h1>Blog Düzenleyin</h1>
        </div>
      </div>

    <BlogEditTable/>
    </>
  );
};

export default BlogEdit;
