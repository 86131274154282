import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import '../../styles/common.css'
import dayjs from 'dayjs';
import { Tours } from '../../services/Tours';
import { setItem } from '../../utils/storage';
import { SearchWidget } from '../SearchWidget';
import _ from 'lodash';

export const SearchToursWidget = ({
    isHome,
    searchParams,
    departureTours,
    setDepartureTours,
    arrivalTours,
    setArrivalTours,
    YetiskinSayisi, setYetiskinSayisi,
    CocukSayisi, setCocukSayisi,
    ChildAges, setChildAges,
    BebekSayisi, setBebekSayisi,
    GidisTarihi, setGidisTarihi,
    DonusTarihi, setDonusTarihi,
    selectedDepartureTour, setSelectedDepartureTour,
    selectedArrivalTour, setSelectedArrivalTour,
    loadingTours, setLoadingTours
}) => {
    const navigate = useNavigate()

    const [from, setFrom] = useState("Yükleniyor.."); // bodrum
    const [to, setTo] = useState("Yükleniyor.."); // rodos
    const [donusTarihiAktif, setDonusTarihiAktif] = useState(searchParams?.donusTarihiAktif);

    useEffect(() => {
        if (from && to) {
            searchTour()
        }
    }, [from, to])

    useEffect(() => {
        const bugun = new Date().toISOString().split("T")[0];
        setGidisTarihi(bugun);
        if (donusTarihiAktif) {
            if (searchParams?.ReturnServiceDate == "") {
                setDonusTarihi("")
            } else {
                setDonusTarihi(dayjs(searchParams?.ReturnServiceDate) || dayjs());
            }
        } else {
            setDonusTarihi("");
        }
    }, [donusTarihiAktif]);

    const searchTours = (searchParams) => {
        if (from != "Yükleniyor.." || to != "Yükleniyor..") {
            setLoadingTours(true)
            Tours().search({
                ...searchParams,
                ServiceDate: searchParams?.ServiceDate,
            })
                .then((res) => {
                    if (res.data?.statusCode == 200) {
                        const tours = res?.data?.data?.result
                        if (tours == null || !tours) {
                            setDepartureTours([])
                        } else {
                            setDepartureTours(tours)
                        }
                        setItem("departureTours", tours)
                    }
                })
                .catch((error) => console.log(error))
                .finally(() => {
                    setLoadingTours(false)
                })

            Tours().search({
                ...searchParams,
                DepartureRegionId: searchParams?.ArrivalRegionId,
                ArrivalRegionId: searchParams?.DepartureRegionId,
                ServiceDate: searchParams?.ReturnServiceDate
            })
                .then((res) => {
                    // console.log("arrival tours:", res.data)
                    if (res.data?.statusCode == 200) {
                        const tours = res?.data?.data?.result
                        if (tours == null || !tours) {
                            setArrivalTours([])
                        } else {
                            setArrivalTours(tours)
                        }
                        // setItem("arrivalTou2rs", tours)
                    }
                })
                .catch((error) => console.log(error))
                .finally(() => {
                    setLoadingTours(false)
                })
        }
    }

    const searchTour = () => {
        setSelectedArrivalTour("")
        setSelectedDepartureTour("")
        setArrivalTours([])
        setDepartureTours([])
        if (DonusTarihi && dayjs(DonusTarihi).isBefore(GidisTarihi, 'day')) {
            notification.error({
                message: 'Hata',
                description: 'Dönüş tarihi gidiş tarihinden önce olamaz!',
                placement: 'topRight'
            })
            setSelectedArrivalTour(undefined)
            setSelectedDepartureTour(undefined)
            setArrivalTours([])
            setDepartureTours([])
            return;
        }
        setSelectedArrivalTour(undefined)
        setSelectedDepartureTour(undefined)

        const searchPayload = {
            RouteType: donusTarihiAktif ? 2 : 1,
            ServiceDate: dayjs(GidisTarihi).format('YYYY-MM-DDTHH:mm:ss'),
            DepartureRegionId: from, //departure_port,
            ArrivalRegionId: to, //arrival_port,
            AdultQuantity: YetiskinSayisi,
            ChildQuantity: CocukSayisi,
            InfantQuantity: BebekSayisi,
            VehicleQuantity: 0,
            HavePets: false,
            DepartureExpeditionId: 0,
            ReturnExpeditionId: 0,
            LangCode: "TR"
        }
        if (donusTarihiAktif) {
            searchPayload.ReturnServiceDate = dayjs(DonusTarihi).format('YYYY-MM-DDTHH:mm:ss');
        }
        // console.log(searchPayload);

        setItem("tourSearchParams", {
            ...searchPayload,
            // departure_date: GidisTarihi,
            // arrival_date: DonusTarihi
        })

        searchTours(searchPayload)
    }

    return (
        <SearchWidget
            from={from}
            setFrom={setFrom}
            to={to}
            setTo={setTo}
            GidisTarihi={GidisTarihi}
            setGidisTarihi={setGidisTarihi}
            DonusTarihi={DonusTarihi}
            setDonusTarihi={setDonusTarihi}
            donusTarihiAktif={donusTarihiAktif}
            setDonusTarihiAktif={setDonusTarihiAktif}
            YetiskinSayisi={YetiskinSayisi}
            setYetiskinSayisi={setYetiskinSayisi}
            CocukSayisi={CocukSayisi}
            setCocukSayisi={setCocukSayisi}
            BebekSayisi={BebekSayisi}
            setBebekSayisi={setBebekSayisi}
            searchTour={isHome ? () => navigate('/bilet-al') : searchTour}
            searchParams={searchParams}
            ChildAges={ChildAges}
            setChildAges={setChildAges}
        />
    )
};
