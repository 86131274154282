import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../Admin/Css/TextEditor.css";

function RichTextEditor() {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  return (
    <>
      <div className="texteditor-container">
        <div className="texteditor-options">
          <div className="texteditor-head-cont">
            <input type="text" placeholder="Postunuzun Başlığını Girin"></input>
          </div>
          <Editor
            placeholder="Postunuzun Açıklamasını Girin..."
            editorState={editorState}
            toolbar={{
              options: [
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "list",
                "textAlign",
                "link",
                "image",
                "remove",
                "history",
              ],
              inline: {
                options: ["bold", "italic", "underline", "strikethrough"],
              },
              blockType: {
                options: [
                  "Normal",
                  "Başlık 1",
                  "Başlık 2",
                  "Başlık 3",
                  "Başlık 4",
                  "Başlık 5",
                  "Başlık 6",
                ],
              },
              fontFamily: {
                options: [
                  "Arial",
                  "Georgia",
                  "Impact",
                  "Tahoma",
                  "Times New Roman",
                  "Verdana",
                ],
              },
              fontSize: {
                options: [8, 10, 12, 14, 18, 24, 30, 36],
              },
              link: {
                popupClassName: "link-popup",
              },
              image: {
                alt: { present: true, mandatory: true },
              },
            }}
            onEditorStateChange={handleEditorChange}
          />
        </div>
      </div>
    </>
  );
}

export default RichTextEditor;
