import { Checkbox, Col, Form, Input, Row, Select } from "antd"
import { useState } from "react";
import { Link } from "react-router-dom";

export const BillingInformations = ({
    form,
    formData,
    confirmedPrivacyPolicy,
    setConfirmedPrivacyPolicy
}) => {
    const contactFormData = formData?.contact
    const [invoiceType, setInvoiceType] = useState('individual')

    return (
        <Row>
            <Col span={24}>
                <Form form={form} layout="vertical" name="billing">
                    <Row gutter={[16, 16]}>
                        <Col md={12} xs={24}>
                            <Form.Item
                                name={['billing', 'invoice_type']}
                                label="Fatura tipi"
                                rules={[{ required: true, message: 'Lütfen fatura tipi seçin!' }]}
                                initialValue="individual"
                            >
                                <Select
                                    placeholder='Fatura tipi seçin'
                                    style={{ height: 56, width: '100%' }}
                                    onChange={(value) => setInvoiceType(value)}
                                    options={[
                                        {
                                            label: 'Bireysel',
                                            value: 'individual'
                                        },
                                        {
                                            label: 'Kurumsal',
                                            value: 'corporate'
                                        }
                                    ]}
                                />
                            </Form.Item>
                        </Col>

                        {
                            invoiceType == "individual" ? (
                                <>
                                    <Col md={12} xs={24}>
                                        <Form.Item
                                            name={['billing', 'national_id']}
                                            label='TC Kimlik Numarası'
                                            rules={[{ required: true, message: 'Lütfen kimlik numaranızı girin!' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col md={12} xs={24}>
                                        <Form.Item
                                            name={['billing', 'name']}
                                            label='Ad'
                                            rules={[{ required: true, message: 'Lütfen adınızı girin!' }]}
                                            initialValue={contactFormData?.name}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col md={12} xs={24}>
                                        <Form.Item
                                            name={['billing', 'surname']}
                                            label='Soyad'
                                            rules={[{ required: true, message: 'Lütfen soyadınızı girin!' }]}
                                            initialValue={contactFormData?.surname}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </>
                            ) : (
                                <>
                                    <Col md={12} xs={24}>
                                        <Form.Item
                                            name={['billing', 'company_name']}
                                            label='Firma Adı'
                                            rules={[{ required: true, message: 'Lütfen firma adı girin!' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} xs={24}>
                                        <Form.Item
                                            name={['billing', 'tax_number']}
                                            label='Vergi No'
                                            rules={[{ required: true, message: 'Lütfen vergi numarası girin!' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col md={12} xs={24}>
                                        <Form.Item
                                            name={['billing', 'tax_office']}
                                            label='Vergi Dairesi'
                                            rules={[{ required: true, message: 'Lütfen vergi dairesi girin!' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </>
                            )
                        }

                        <Col span={24}>
                            <Checkbox onChange={() => { }}>SMS ve Mail ile tarafıma kampanya bildirimleri yapılmasına izin veriyorum.</Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox onChange={(e) => setConfirmedPrivacyPolicy(e.target.checked)} checked={confirmedPrivacyPolicy}>
                                <Link href="/gizlilik-sozlesmesi" to="/gizlilik-sozlesmesi">
                                    Gizlilik Sözleşmesi
                                </Link>
                                <span style={{ marginLeft: 3 }}>'ni Kişisel verilerimin ilgili yasal düzenlemelerin izin verdiği veya gerektirdiği durumlarda Uygun Feribot tarafından Uygun Feribot Gizlilik Politikası’nda belirtilen şartlar uyarınca işlenebileceğini,
                                    yurt içi ve yurt dışına aktarılabileceğini biliyorum ve kabul ediyorum.</span>
                            </Checkbox>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    )
}