import React from "react";
import "../Admin/Css/FirsatAdd.css";

const FirsatAdd = () => {
  return (
    <>
      <div className="blogadd-banner">
        <div className="blogadd-banner-text">
          <h1>Fırsat Ekleyin</h1>
        </div>
        <div className="blogadd-admin"></div>
      </div>
    </>
  );
};

export default FirsatAdd;
