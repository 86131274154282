import { Card, Table } from "antd";
import dayjs from "dayjs";

export const TicketInformationCard = ({ ticketData }) => {

    const passengerTableColumns = [
        {
            title: 'İsim',
            dataIndex: 'name',
            render: (value, record) => (
                <span style={{
                    whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'
                }}>
                    {value}
                </span>
            ),
        },
        {
            title: 'Soyisim',
            dataIndex: 'surName',
            sorter: {
                compare: (a, b) => a.chinese - b.chinese,
                multiple: 3,
            },
        },
        {
            title: 'Kalkış',
            dataIndex: 'departureRegionName',
            sorter: {
                compare: (a, b) => a.math - b.math,
                multiple: 2,
            },
        },
        {
            title: 'Varış',
            dataIndex: 'arrivalRegionName',
            sorter: {
                compare: (a, b) => a.english - b.english,
                multiple: 1,
            },
        },
        {
            title: 'Feribot',
            dataIndex: 'ferryName',
        },
        {
            title: 'Firma',
            dataIndex: 'ferryCompanyName',
        }
    ];

    return (
        <Card
            title="Yolcular"
            bordered={false}
        >
            <Table
                bordered
                size='small'
                columns={passengerTableColumns}
                dataSource={ticketData?.passengerTicket}
                scroll={{ x: true, scrollToFirstRowOnChange: true }}
                pagination={{ defaultPageSize: 20 }}
                expandable={{
                    expandedRowRender: (record) => (
                        <div>
                            <span>
                                Hareket tarihi: <span style={{ fontWeight: '600' }}>{dayjs(record.serviceDate).format('L')}</span>
                            </span>
                            <span style={{ marginLeft: 8 }}>
                                Kalkış saati: <span style={{ fontWeight: '600' }}>{record.departureTime}</span>
                            </span>
                            <span style={{ marginLeft: 8 }}>
                                Varış saati: <span style={{ fontWeight: '600' }}>{record.arrivalTime}</span>
                            </span>
                        </div>
                    ),
                    rowExpandable: (record) => record.name !== 'Not Expandable',
                }}
            />
        </Card>
    )
}