import React from 'react'
import '../pages/Css/HakkimizdaPage.css'
import { TabTitle } from "../tools/GeneralFunction";
import { CommonHeader } from '../components/CommonHeader';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  const title = 'Gizlilik Sözleşmesi'
  TabTitle(`${title} - Uygun Feribot | Yunan Adaları Feribot Bileti Satın Al`);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <CommonHeader title={title} />
      <div className='MetinContainerHakkimizda'>
        <h3>1) CHECK – IN</h3>
        <p>a) Yolcu Pasaport, vize kontrollerinin yapılabilmesi, biletlerini teslim alabilmesi için sefer saatinden en geç 1 saat önce check-in işlemlerini tamamlamak üzere ilgili firmanın Checkin Ofisinde hazır bulunmalıdır. Check-in işlemleri sefer saatine yarım saat kala sona erer. Hafta sonları ve yüksek sezonda daha erken gelmeniz tavsiye edilir.</p>
        <p>Check-in işlemleri sefer gününden öncede tüm ofislerimizde yapılabilmektedir.</p>
        <p>Yolcu hareket saatinden 15 dk. önce feribotta hazır bulunmak zorundadır.</p>
        <p>Yolcularımız Liman sahası içerisinde Kafeterya veya Freeshop’ta geçirdiği zamanı bu kurallara göre ayarlamakla mükelleftir.</p>
        <p>b) Araç ile seyahat edecek yolcular için özel hükümler;</p>
        <p>Araç ile seyahat edecek yolcuların Check-in işlemleri sadece Liman ofisinde yapılır. Araçlı yolcuların (sadece sürücü) Check-in önceliği vardır lütfen ilgili firma yetkililerine durumunuzu belirtiniz.</p>
        <p>Araçlı yolcuların Check-in işlemi için kesin olarak sefer saatinden 1.5 saat önce limanda hazır bulunması gerekir.</p>
        <p>Şirket Araçlarında firma sahibi iseniz şirket imza sirküleri ya da çalışanı iseniz noterden vekalet yazısı gereklidir.</p>
        <p>Rent a Car aracı ile yurt dışı çıkışı yapabilmeniz için firmadan noter kanalı ile vekaletname alınmalıdır.</p>
        <p>Sakız Adasına geçecek yolcularımızın beynelmilel ehliyet almasına gerek yoktur.</p>
        <p>Yeşil Sigorta aracınızın kaskosunu yurtdışında da geçerli kılan ek bir sigorta poliçesidir Sigorta firmalarından temin edilebilinir.</p>

        <h3>2) AÇIK BİLETLER</h3>
        <p>Açık biletlerin tarifeli sefere alınması sadece seferin uygunluğunda yapılabilmekte ve sadece ilgili firma ofisleri tarafından gerçekleştirilmektedir. Açık biletlerin kapatılması için hareketten en geç 1 gün önce bir taşıyıcı firma ile irtibata geçilmeli ve bu hususta yolcu seyahatinden önce bilgilendirilmelidir.</p>
        <ul>
          <li>Açık biletin geçerlilik süresi 6 aydır. Biletin kesim tarihinden 6 ay sonrasında kullanılmayan bilet otomatik olarak iptal olur.</li>
          <li>Açığa alınan biletleri, bileti kesen acente açığa alma tarihini imzalayıp ve kaşeleyip boarding üzerinde beyan etmelidir.</li>
        </ul>

        <h3>3) BİLETİN KAYBOLMASI DURUMU</h3>
        <p>Biletini kaybeden yolcu yeni bilet almak durumundadır. Bilet kayıplarından herhangi bir nedenle acenteler sorumlu değildir.</p>

        <h3>4) BİLETİN DEVREDİLEMEMESİ</h3>
        <p>İsme düzenlenen yolcu bileti devredilemez ve başkasına tekrar satılamaz. Bir biletle yolculuk etme hakkına haiz olan kişiden başka bir kişi tarafından taşıma ve ücret iadesi için bilet ibraz edilirse, taşıyıcı, iyi niyetli ibraz edeni taşıdığı veya bileti ibraz edene iade yaptığı takdirde, o biletle seyahat etme hakkına haiz olan kişiye karşı sorumlu olmaz.</p>

        <h3>5) BİLET İPTAL VE İADE TALEPLERİ</h3>
        <p>27.11.2014 tarihli Mesafeli Sözleşmeler Yönetmeliği’nin “Cayma Hakkının Kullanımı ve Tarafların Yükümlülükleri” ni düzenleyen “Üçüncü Bölüm” başlığı altında yazılı maddelerinin,</p>
        <p>“Yolcu Taşıma Hizmetleri” ne uygulanmaması sebebi ile; ALICI’NIN cayma hakkı bulunmamaktadır.</p>
        <p>“Yolculuğun İptali ve Ücret İade Şartları</p>
        <p>İşbu sözleşmeye göre satın alınan elektronik biletlerin, hiçbir şekilde yolcu, tarih, saat ve güzergah bilgilerinin değiştirilmesi satıcıdan talep edilemez. Alıcı işbu sözleşmeye göre satın aldığı bileti satış onayından sonra hiçbir şekilde değiştirmeyeceğini ve iade talep etmeyeceğini kabul ve taahhüt eder.” denmişse de</p>
        <p>Yolcularının sefer saatinden 2 gün önce bildirmek koşuluyla biletlerini 1 kez açığa alma ve yeniden tarihleme veya 1 kez yeniden tarihleme hakkı bulunmaktadır. Ücret iadesi talepleri kabul edilemeyecektir.</p>

        <h3>6) YOLCUNUN YURTDIŞI ÇIKIŞINA VEYA YUNANİSTANA GİRİŞİNE İZİN VERİLMEYEN HALLER</h3>
        <p>Satıcı Firmadan  kaynaklanmayan yolcunun adli, idari, vize veya beraberinde götürmek istediği araç, evcil hayvan vs. den kaynaklanan girişine/çıkışına mani durumlarda hizmet akdi sona erer ve herhangi bir iade talebi kabul edilmez.</p>

        <h3>7) SEFER İPTALİ / DEĞİŞİKLİĞİ</h3>
        <ul>
          <li>Acenteler, yolcuların mobil numaralarını sisteme kaydetmeyle yükümlüdür. Böylelikle yolcular sefer iptal veya değişikliğinden haberdar olabilir.</li>
          <li>Hava muhalefeti sebebiyle gerçekleştirilemeyen seferlerde yolcuya koşulsuz %100 iade yapılır. Satıcı firmanın elinde olmayan sebeplerden kaynaklanan hava muhalefeti ve benzer durumlarda ortaya çıkabilecek konaklama, yeme içme giderlerinde Satıcı Firmanın sorumluluğu bulunmamaktadır.</li>
          <li>Program dışı Bayrak devleti kontrolleri, Plansız gemi arızalanma durumlarında taşıyıcı firmanın sefer saati ve gemi değiştirme hakları saklıdır.</li>
          <li>Sefer iptal ve değişikliği hususunda Taşıyıcı Firmanın tüm hakları saklıdır.</li>
        </ul>

        <h3>8) BİLET KONTROLÜ</h3>
        <p>Biletlerin kontrolleri gemi kapağında boardinglerdeki barkodlar yardımıyla yapılmaktadır. Herhangi bir sebeple iptal edilmiş, tarihi değiştirilmiş veya açığa alınmış biletli yolcular gemiye alınmaz.</p>

        <h3>9) ÖZEL İSTEĞİ OLAN YOLCULAR / EVCİL HAYVAN İLE YOLCULUK EDECEK YOLCULAR</h3>
        <ul>
          <li>Acenteler özel durumlu yolcular hakkında Taşıyıcı Firma Hareketten en geç 2 saat önce bilgilendirmelidir.</li>
          <li>Evcil Hayvan ile yolculuk edecek yolcular için gerekli evraklar Taşıyıcı Firma Ofislerinden istenmeli ve seyahatten 2 gün önce Taşıyıcı Firma ofisine ulaştırılmalıdır. Veteriner Başkanlığından onay gelmeden yolcunun seyahati konfirme edilemez.</li>
        </ul>
      </div>
    </>
  )
}

export default PrivacyPolicy