import React from 'react'
import './IletisimForm.css'
import '../../styles/common.css'
import { Button, Card, Col, Flex, Form, Input, Row, Space } from 'antd'
import TextArea from 'antd/es/input/TextArea'

const İletisimForm = () => {
    return (
        <section className='section-main-contact'>
            <Flex>
                <Row gutter={[24, 12]}>
                    <Col span={24} style={{ marginTop: 50 }}>
                        <h3 className="text">Bizimle iletişime geçmekten çekinmeyin.</h3>
                        <p className='textKucuk'>Talep, soru, öneri ve şikayetleriniz için aşağıda listelenen iletişim kanalları üzerinden bizimle iletişime geçebilirsiniz.</p>
                    </Col>
                    <Col style={{ width: '100%' }} md={24} sm={24} xs={24}>
                        <Card>
                            <Form
                                layout={'vertical'}
                            >
                                <Row gutter={[16, 16]}>
                                    <Col span={24} xs={24} sm={12} md={12}>
                                        <Form.Item
                                            label='Adınız'
                                            name='name'
                                            rules={[{ required: true }]}
                                        >
                                            <Input
                                                placeholder="Adınızı yazın"
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24} xs={24} sm={12} md={12}>
                                        <Form.Item
                                            label='Soyadınız'
                                            name="surname"
                                            rules={[{ required: true }]}
                                        >
                                            <Input placeholder="Soyadınızı yazın" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24} xs={24} sm={12} md={12}>
                                        <Form.Item
                                            label='Mail adresiniz'
                                            name="email"
                                            rules={[{ required: true }]}
                                        >
                                            <Input type='email' placeholder='Mail adresiniz' />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24} xs={24} sm={12} md={12}>
                                        <Form.Item
                                            label='PNR numaranız (varsa)'
                                            name="pnrNumber"
                                        >
                                            <Input placeholder="PNR numaranız" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24} xs={24} sm={24} md={24}>
                                        <Form.Item
                                            label='Mesajınız'
                                            rules={[{ required: true }]}
                                        >
                                            <TextArea rows={5} />
                                        </Form.Item>
                                    </Col>
                                </Row>


                                <Button type='primary' size='large'>Gönder</Button>
                            </Form>
                        </Card>
                    </Col>
                    <Col style={{ width: '100%' }} md={24} sm={24} xs={24}>
                        <Card>
                            Mail: destek@uygunferibot.com
                        </Card>
                        {/* <Card>
                            Telefon:
                        </Card>
                        <Card>
                            Adres:
                        </Card> */}
                    </Col>
                </Row>
            </Flex>
        </section>
    )
    return (
        <section className='section-main-contact'>
            <div className="container">
                <div className="text">Bizimle iletişime geçmekten çekinmeyin.</div>
                <p className='textKucuk'>Talep, soru, öneri ve şikayetleriniz için aşağıda listelenen iletişim kanalları üzerinden bizimle iletişime geçebilirsiniz.</p>
                <div className="dividerForm"></div>
                <form action="#">
                    <div className="form-row">
                        <div className="input-data">
                            <input type="text" required />
                            <div className="underline" />
                            <label htmlFor>Adınız</label>
                        </div>
                        <div className="input-data">
                            <input type="text" required />
                            <div className="underline" />
                            <label htmlFor>Soyadınız</label>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="input-data">
                            <input type="text" required />
                            <div className="underline" />
                            <label htmlFor>E-posta adresiniz</label>
                        </div>
                        <div className="input-data">
                            <input type="text" required />
                            <div className="underline" />
                            <label htmlFor>Telefon Numaranız</label>
                        </div>
                        <div className="input-data">
                            <input type="text" required />
                            <div className="underline" />
                            <label htmlFor>Konu</label>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="input-data textarea">
                            <textarea rows={8} cols={80} required defaultValue={""} />
                            <br />
                            <div className="underline" />
                            <label htmlFor>Mesajınız</label>
                            <br />
                            <div className="form-row submit-btn">
                                <button className="contact-form-btn">Mesaj Gönder</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default İletisimForm