import React, { useState } from "react";
import "../pages/Css/PnrSorgula.css";
import { TabTitle } from "../tools/GeneralFunction";
import PnrSorguContainer from "../components/PNR/PnrSorguContainer";
import PnrText from "../components/Section/PNR/pnr-text";
import { CommonHeader } from "../components/CommonHeader";
import { Col, Row, Space, Spin, Typography } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import { TicketInformationCard } from "../components/TicketInformationCard";

const PnrSorgula = () => {
  TabTitle("PNR Sorgulama - PNR Kodu Sorgulama - Uygun Feribot | Yunan Adaları Feribot Bileti Satın Al");

  const [pnrInput, setPnrInput] = useState("");
  const [ticketData, setTicketData] = useState([])
  const [pnrIsChecking, setPnrIsChecking] = useState(false)

  return (
    <div style={{ paddingBottom: 65, backgroundColor: '#f7f7f7' }}>
      <CommonHeader title="PNR Sorgulama">
        <section className="pnr-section">
          <PnrSorguContainer
            ticketData={ticketData}
            setTicketData={setTicketData}
            pnrIsChecking={pnrIsChecking}
            setPnrIsChecking={setPnrIsChecking}
            pnrInput={pnrInput}
            setPnrInput={setPnrInput}
          />
        </section>
      </CommonHeader>


      <section className="pnr-text-section">
        {
          pnrIsChecking ? (
            <div>
              <Row gutter={[32, 32]}>
                <Col span={24} style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                  <Space style={{ height: 100 }}>
                    <div style={{ display: 'block' }}>
                      <Spin /><br></br>
                    </div>
                    <Typography.Title level={3}>Bilet sorgulanıyor, lütfen bekleyin..</Typography.Title>
                  </Space>
                </Col>
              </Row>
            </div>
          )
            :
            !_.isEmpty(ticketData) && (
              <>
                <Row>
                  <Col span={24}>
                    <h2 className="ticket-card-title" style={{ marginBottom: 0 }}>Bilet Bilgileriniz ({pnrInput})</h2>
                    <div className="ticket-informations">
                      <div className="ticket-info-item">
                        Bilet kesim tarihi <b>{dayjs(ticketData?.salesDate).format('L LT')}</b>
                      </div>
                      <div className="ticket-info-item">
                        Feribot giriş numaranız: <b>{ticketData?.id}</b>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Col md={24}>
                  <TicketInformationCard ticketData={ticketData} />
                </Col>
              </>
            )
        }
      </section >

      <PnrText />

    </div>
  );
};

export default PnrSorgula;


const ticketDataRaw = {
  "passengerTicket": [
    {
      "passengerTicketId": 11,
      "isDeleted": false,
      "citizenshipID": "15986395286",
      "customerID": 2311430,
      "id": 3699290,
      "expeditionID": 471846,
      "salesID": 1297847,
      "serviceDate": "2024-07-29T00:00:00",
      "salesDate": "2024-07-23T00:00:00",
      "salesAmount": 32.50,
      "totalSalesAmount": 32.50,
      "totalSalesAmountTL": 32.5000,
      "commissionAmount": 0.00,
      "currencyID": 1071805,
      "currencyTypeID": 2,
      "portTax": 0.00,
      "salesItemID": 0,
      "arrivalTime": "10:15:00",
      "departureTime": "08:25:00",
      "ferryName": "SEA STAR LİNDOS",
      "ferryCompanyName": "Tilos Travel\t",
      "ferryID": 106,
      "routeID": 292,
      "departureRegionName": "FETHİYE - LIMAN",
      "arrivalRegionName": "RODOS - TOURISTIKO PORT",
      "name": "TURKIZ MELIH",
      "passportNo": "",
      "surName": "OZSECGIN",
      "gsm": "05437317318",
      "openWay": false,
      "currencyTypeCode": "EUR",
      "currencyTypeSymbol": "€",
      "returnTicketID": 3699291,
      "birthDate": "2002-03-04T00:00:00",
      "routeType": 2,
      "nationality": "TURKIYE",
      "passengerTypeID": 1,
      "passengerTypeName": "Adult",
      "departureRegionID": 300,
      "arrivalRegionID": 965,
      "countryName": "TURKIYE",
      "passportExpireDate": null,
      "countryID": 1,
      "gender": 0,
      "genderName": "Erkek",
      "visaTypeID": 0,
      "visaExpireDate": null,
      "visaBeginDate": null,
      "visaClassName": null,
      "taxAmount": 0,
      "termAmount": 0,
      "isBranchesCanChange": false
    },
    {
      "passengerTicketId": 12,
      "isDeleted": false,
      "citizenshipID": "15986395286",
      "customerID": 2311430,
      "id": 3699291,
      "expeditionID": 472669,
      "salesID": 1297847,
      "serviceDate": "2024-07-29T00:00:00",
      "salesDate": "2024-07-23T00:00:00",
      "salesAmount": 32.50,
      "totalSalesAmount": 32.50,
      "totalSalesAmountTL": 32.5000,
      "commissionAmount": 0.00,
      "currencyID": 1071805,
      "currencyTypeID": 2,
      "portTax": 0.00,
      "salesItemID": 0,
      "arrivalTime": "18:20:00",
      "departureTime": "16:30:00",
      "ferryName": "SEA STAR LİNDOS",
      "ferryCompanyName": "Tilos Travel\t",
      "ferryID": 106,
      "routeID": 293,
      "departureRegionName": "RODOS - TOURISTIKO PORT",
      "arrivalRegionName": "FETHİYE - LIMAN",
      "name": "TURKIZ MELIH",
      "passportNo": "",
      "surName": "OZSECGIN",
      "gsm": "05437317318",
      "openWay": false,
      "currencyTypeCode": "EUR",
      "currencyTypeSymbol": "€",
      "returnTicketID": 3699290,
      "birthDate": "2002-03-04T00:00:00",
      "routeType": 2,
      "nationality": "TURKIYE",
      "passengerTypeID": 1,
      "passengerTypeName": "Adult",
      "departureRegionID": 965,
      "arrivalRegionID": 300,
      "countryName": "TURKIYE",
      "passportExpireDate": null,
      "countryID": 1,
      "gender": 0,
      "genderName": "Erkek",
      "visaTypeID": 0,
      "visaExpireDate": null,
      "visaBeginDate": null,
      "visaClassName": null,
      "taxAmount": 0,
      "termAmount": 0,
      "isBranchesCanChange": false
    }
  ],
  "vehicleTicket": [],
  "extraService": [],
  "hotel": [],
  "salesCurrency": [
    {
      "currencyID": 1066315,
      "name": "Türk Lirası",
      "symbol": "₺",
      "code": "TRL",
      "typeID": 1,
      "amount": 0,
      "currencyAmount": 1.00
    },
    {
      "currencyID": 1071805,
      "name": "Euro",
      "symbol": "€",
      "code": "EUR",
      "typeID": 2,
      "amount": 0,
      "currencyAmount": 35.93
    },
    {
      "currencyID": 1071804,
      "name": "Dolar",
      "symbol": "$",
      "code": "USD",
      "typeID": 3,
      "amount": 0,
      "currencyAmount": 33.12
    },
    {
      "currencyID": 1071806,
      "name": "Pound",
      "symbol": "£",
      "code": "GBP",
      "typeID": 4,
      "amount": 0,
      "currencyAmount": 42.68
    }
  ],
  "salesSource": {
    "companyID": 0,
    "id": 0,
    "status": 0,
    "utmCampaing": null,
    "utmMedium": null,
    "utmSource": null,
    "utmTerm": null,
    "utmContent": null,
    "urlReferrer": null,
    "salesID": 0
  },
  "id": 1297847,
  "pnrNo": "GL5J3E6JUH",
  "salesDate": "2024-07-23T20:39:58.37",
  "totalAmount": 0.00,
  "totalAmountTL": 2335.4500,
  "totalCommisonAmount": 0.00,
  "totalRemainingAmount": 0.00,
  "branchID": 7031,
  "step": 1,
  "userID": 7146,
  "userName": "uygunferibot api",
  "branchName": "uygunferibot.com / Emre Fırtına",
  "reservationStatus": 1,
  "forthCrsReservation": {
    "forthCrsReserveIssueRequest": [],
    "contact": null,
    "langCode": null
  },
  "note": null,
  "guid": "14512669-a63e-44a6-82df-dc645f01fc34"
}
