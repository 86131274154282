import React, { useState, useEffect } from "react";
import { IoEyeSharp } from "react-icons/io5";
import { IoPencilSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import ImgDrop from "../ImgDrop"
import { Divider, Table } from "antd";
import "../Agents/AgentsTable.css"
import { AgentService } from '../../../../services/AgentService';
import { Dropdown, Flex } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { RiShipLine } from "react-icons/ri";
import { PiHandshakeLight } from "react-icons/pi";
import { notification } from "antd";

const AgentsTable = () => {
  const [TourmodalOpen, setTourModalOpen] = useState(false);
  const [DeletemodalOpen, setDeleteModalOpen] = useState(false);
  const [UpdatemodalOpen, setUpdateModalOpen] = useState(false);
  const [ShowmodalOpen, setShowModalOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  const TourModalOpen = () => {
    setTourModalOpen(true);
  };
  const TourModalOff = () => {
    setTourModalOpen(false);
  };

  const DeleteModalOpen = () => {
    setDeleteModalOpen(true);
  };
  const DeleteModalOff = () => {
    setDeleteModalOpen(false);
  };

  const UpdateModalOpen = () => {
    setUpdateModalOpen(true);
  };
  const UpdateModalOff = () => {
    setUpdateModalOpen(false);
  };

  const ShowModalOpen = () => {
    setShowModalOpen(true);
  };
  const ShowModalOff = () => {
    setShowModalOpen(false);
  };

  const [number, setNumber] = useState("");

  const handleChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setNumber(event.target.value);
    }
  };

  const fetchData = () => {
    AgentService().list().then((response) => {
      const { data } = response;
      if (data?.status == "success") {
        const agents = data.data;
        setDataSource(agents)
        console.log("reservations", data);
      } else {
        console.log("hata", data?.message);
      }
    }).catch((error) => {
      console.log("err", error);
    });
  }

  const createAgent = () => {
    const agentName = document.querySelector("input[name=name").value;
    const agentDesc = document.querySelector("input[name=description").value;
    const agentVisible = document.querySelector("select[name=visible").value;
    AgentService().create({
      name: agentName,
      description: agentDesc,
      visible: agentVisible
    }).then((response) => {
      const { data } = response;
      if (data?.status == "success") {
        console.log("reservations", data);

        notification.success({
          message: 'Başarılı',
          description: 'Başarıyla acente eklendi!',
          placement: 'topRight'
        })
        TourModalOff()
        fetchData()
      } else {
        notification.error({
          message: 'Hata',
          description: 'Acente eklenemedi!',
          placement: 'topRight'
        })
      }
    }).catch((error) => {
      notification.error({
        message: 'Hata',
        description: 'Acente eklenemedi!',
        placement: 'topRight'
      })
    });
  }

  useEffect(() => {
    fetchData()
  }, [])

  // const visibleCount = dataSource.filter(item => item.visible).length;

  // visible false olanların sayısını bulma
  // const invisibleCount = dataSource.filter(item => !item.visible).length;

  const columns = [
    {
      title: 'İsim',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Açıklama',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Durum',
      dataIndex: 'visible',
      key: 'visible',
    },
    {
      title: 'İşlemler',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record, index) => {
        return (
          <></>
          // <Flex align="flex-start" gap="small">
          //   <Button type='primary' ghost icon={<EyeOutlined />}></Button>
          //   <Button onClick={() => {
          //     setUpdateModalForm(record)
          //     UpdateModalOpen()
          //   }} icon={<EditOutlined />}></Button>
          //   <Button danger icon={<DeleteOutlined />} onClick={() => deleteGuest(record.id)}></Button>
          // </Flex>
        )
      }
    },

  ];

  return (
    <>
      <div className="tours-banner">
        <h1>Acenteler</h1>
      </div>
      {/* Table */}
      <div className="table-container-tours">
        <div className="table-top-container">
          <button onClick={TourModalOpen} className="tour-add-btn">
            <p>Acente Ekle</p>
          </button>
        </div>
        {/* <table className="ui-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nerden</th>
              <th>Nereye</th>
              <th>Gidiş Tarihi</th>
              <th>Dönüş Tarihi</th>
              <th>Firma</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>İstanbul</td>
              <td>Ankara</td>
              <td>2024-03-25</td>
              <td>2024-03-27</td>
              <td>Tilos</td>
              <td className="button-container">
                <button onClick={ShowModalOpen} id="show-icon">
                  <IoEyeSharp className="table-icon" />
                </button>
                <button onClick={UpdateModalOpen} id="edit-icon">
                  <IoPencilSharp className="table-icon" />
                </button>
                <button onClick={DeleteModalOpen} id="delete-icon">
                  <MdDelete className="table-icon" />
                </button>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Ankara</td>
              <td>İzmir</td>
              <td>2024-04-01</td>
              <td>2024-04-05</td>
              <td>Turyol</td>
              <td className="button-container">
                <button onClick={ShowModalOpen} id="show-icon">
                  <IoEyeSharp className="table-icon" />
                </button>
                <button onClick={UpdateModalOpen} id="edit-icon">
                  <IoPencilSharp className="table-icon" />
                </button>
                <button onClick={DeleteModalOpen} id="delete-icon">
                  <MdDelete className="table-icon" />
                </button>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>İzmir</td>
              <td>Antalya</td>
              <td>2024-04-10</td>
              <td>2024-04-15</td>
              <td>Ertürk Lines</td>
              <td className="button-container">
                <button onClick={ShowModalOpen} id="show-icon">
                  <IoEyeSharp className="table-icon" />
                </button>
                <button onClick={UpdateModalOpen} id="edit-icon">
                  <IoPencilSharp className="table-icon" />
                </button>
                <button onClick={DeleteModalOpen} id="delete-icon">
                  <MdDelete className="table-icon" />
                </button>
              </td>
            </tr>
          </tbody>
        </table> */}
        <Table dataSource={dataSource} columns={columns} />
      </div>

      {/*Tour Add*/}
      {TourmodalOpen && (
        <div className="tour-add">
          <div className="tour-add-modal-content">
            <span className="close" onClick={TourModalOff}>
              &times;
            </span>
            <p>Acente Ekle</p>
            <Divider />
            <div className="name-modal">
              <label>İsim</label>
              <input
                type="text"
                id="name"
                name="name"
                onChange={handleChange}
                required
                placeholder="Lütfen acente ismi girin"
              />
            </div>

            <div className="description-modal">
              <label>Açıklama</label>
              <input
                type="text"
                id="description"
                name="description"
                onChange={handleChange}
                required
                placeholder="Lütfen açıklaması girin"
              />
            </div>
            <div className="company-select-modal">
              <label>Görünürlük</label>
              <select className="select" name="visible" id="visible">
                <option value="true">Aktif</option>
                <option value="false">Pasif</option>
              </select>
            </div>
            <button className="add-trip-btn" onClick={createAgent}>Tamam</button>
          </div>
        </div>
      )}

      {/* Show Modal */}
      {ShowmodalOpen && (
        <div className="show-modal">
          <div className="show-modal-content">
            <span className="close" onClick={ShowModalOff}>
              &times;
            </span>
            <p>Sefer Bilgileri</p>
            <div className="round-trip-cont">
              <p className="round-trip-item">ID:</p>
              <p className="round-trip-item">Firma:</p>
            </div>
            <div className="round-trip-cont">
              <p className="round-trip-item">Nerden:</p>
              <p className="round-trip-item">Nereye:</p>
            </div>
            <div className="round-trip-cont">
              <p className="round-trip-item">Gidiş Tarihi:</p>
              <p className="round-trip-item">Dönüş Tarihi:</p>
            </div>
            <button className="show-payment-oky-btn" onClick={ShowModalOff}>
              Tamam
            </button>
          </div>
        </div>
      )}

      {/* Update Modal */}
      {UpdatemodalOpen && (
        <div className="update-modal">
          <div className="update-modal-content">
            <span className="close" onClick={UpdateModalOff}>
              &times;
            </span>
            <p>Sefer Düzenle</p>
            <div className="form-group-select-ticket">
              <div className="one-way-two-way-container">
                <label
                  htmlFor="toggle"
                  className="toggleContainer"
                  style={{
                    fontWeight: "500",
                  }}
                >
                  <div>Tek Yön</div>
                  <div>Gidiş Dönüş</div>
                </label>
              </div>
            </div>
            <div className="update-modal-company">
              <select className="select">
                <option value="1">Firma</option>
                <option value="2">Tilos</option>
                <option value="3">Turyol</option>
                <option value="4">Ertürk Lines</option>
              </select>
            </div>
            <button onClick={UpdateModalOff} className="update-modal-btn">
              <p>Güncelle</p>
            </button>
          </div>
        </div>
      )}

      {/* Delete Modal */}
      {DeletemodalOpen && (
        <div className="delete-modal">
          <div className="delete-modal-content">
            <span className="close" onClick={DeleteModalOff}>
              &times;
            </span>
            <p>Seçili Seferi Silmek İstediğinize Emin misiniz ?</p>
            <Divider />
            <div className="delete-modal-button-container">
              <button
                onClick={DeleteModalOff}
                className="delete-modal-btn"
                id="vazgec-btn"
              >
                <p>Vazgeç</p>
              </button>
              <button className="delete-modal-btn" id="sil-btn">
                <p>Sil</p>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AgentsTable;
