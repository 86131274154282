import React, { useEffect, useState } from 'react';
import { Row, Col, Select, Card, Flex, Radio, Button, Checkbox, Typography, Divider, Space } from 'antd';
import { IoPersonOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { CiClock2 } from "react-icons/ci";
import { GiShipBow } from "react-icons/gi";

import '../../../styles/common.css';
import '../styles.css';
import dayjs from 'dayjs';
import { convertTourTime } from '../../../utils/convertTourTime';
import { formatTourTime } from '../../../utils/formatTourTime'

export const TourCard = ({ data, selectTour, isDeparture, selectedTour, disabled }) => {
    const {
        departureRegionName,
        departurePort,
        departureTime,
        arrivalRegionName,
        arrivalPort,
        arrivalTime,
        ferryName,
        expeditionID,
        travelTime,
        serviceDate,
        ferryCompanyName,
        expeditionPrice,
        returnTourComponentRef
    } = data

    const tourIsSelected = selectedTour?.expeditionID == expeditionID
    const totalPrice = expeditionPrice?.reduce((sum, item) => sum + item.priceQuantity, 0) || 0;

    return (
        <Card
            bordered={true}
            style={{ borderLeft: '7px solid dodgerblue', cursor: 'pointer' }}
            onClick={() => {
                if (!disabled) {
                    if (returnTourComponentRef?.current) {
                        returnTourComponentRef?.current.scrollIntoView({ behavior: 'smooth' });
                    }
                    selectTour(data)
                    // console.log("selected", data);
                }
            }}
        >
            <Flex justify='space-between'>
                <Radio value={data}>
                    <h2 style={{ paddingLeft: 10 }}>
                        <span style={{ fontWeight: '600', fontSize: 19 }}>{ferryName}</span>
                        <span color="#4096ff" style={{ fontWeight: '500', fontSize: 17, display: 'block', marginTop: -5, color: '#4096ff' }}>{ferryCompanyName}</span>
                    </h2>
                </Radio>

                <h2 style={{ color: 'dodgerblue' }}>€{totalPrice}</h2>
            </Flex>

            <Divider />


            <Row gutter={[16, 16]}>
                <Col md={8} xs={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div>
                        <Typography.Text style={{ fontSize: 17, textAlign: 'center', fontWeight: '500' }}>
                            <div className='tourLabel' style={{ fontSize: 13, color: '#999' }}>
                                KALKIŞ
                            </div>
                            <div className='center'>
                                {departurePort}
                            </div>
                            <div className='tourLabel' style={{ fontSize: 13, color: '#999' }}>
                                {
                                    convertTourTime(departureTime)
                                }
                            </div>
                        </Typography.Text>
                    </div>
                </Col>

                <Col md={8} xs={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography.Text style={{ fontSize: 14, textAlign: 'center' }}>
                        <div>
                            Sefer süresi: {formatTourTime(travelTime)}
                        </div>

                        <div>
                            {dayjs(serviceDate).format('DD.MM.YYYY')}
                        </div>

                        {data?.available == false && (
                            <div style={{ color: '#999' }}>
                                (En yakın tarih)
                            </div>
                        )}
                    </Typography.Text>
                </Col>

                <Col md={8} xs={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography.Text style={{ fontSize: 16, textAlign: 'center', fontWeight: '500' }}>
                        <div className='tourLabel' style={{ fontSize: 13, color: '#999' }}>
                            VARIŞ
                        </div>
                        <div className='center'>
                            {arrivalPort}
                        </div>
                        <div className='tourLabel' style={{ fontSize: 13, color: '#999' }}>
                            {
                                convertTourTime(arrivalTime)
                            }
                        </div>
                    </Typography.Text>
                </Col>
            </Row>
            <div className='tourDirections' hidden>
                <Flex justify='space-between'>
                    <Typography.Text style={{ fontSize: 16 }}>
                        <span className='tourLabel'>
                            <GiShipBow /> Kalkış:
                        </span>
                        {departureRegionName}
                    </Typography.Text>

                    <Typography.Text style={{ fontSize: 14 }}>
                        {dayjs(serviceDate).format('DD.MM.YYYY')}
                    </Typography.Text>

                    <Typography.Text style={{ fontSize: 16 }}>
                        <span className='tourLabel'>
                            Varış:
                        </span>
                        {arrivalRegionName}
                        <GiShipBow className='tourLabelIconRight' />
                    </Typography.Text>
                </Flex>

                <div style={{ marginTop: 5 }}>
                    <Flex justify='space-between'>
                        <Typography.Text style={{ fontSize: 16 }}>
                            <span className='tourLabel'>
                                <CiClock2 /> Saat:
                            </span>
                            {departureTime}
                        </Typography.Text>

                        <Typography.Text style={{ fontSize: 14 }}>
                            Sefer süresi: {formatTourTime(travelTime)}
                        </Typography.Text>

                        <Typography.Text style={{ fontSize: 16 }}>
                            <span className='tourLabel'>
                                Saat:
                            </span>

                            {arrivalTime} <CiClock2 />
                        </Typography.Text>
                    </Flex>
                </div>
            </div>

        </Card >
    )
}