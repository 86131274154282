import React from 'react'
import '../pages/Css/HakkimizdaPage.css'
import { TabTitle } from "../tools/GeneralFunction";
import { CommonHeader } from '../components/CommonHeader';
import { Helmet } from 'react-helmet';

const SalesContract = () => {
  const title = "Mesafeli Satış Sözleşmesi"
  TabTitle(`${title} - Uygun Feribot | Yunan Adaları Feribot Bileti Satın Al`);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <CommonHeader title={title} />
      <div className='MetinContainerHakkimizda'>
        <p>
          MADDE 1. : SÖZLEŞMEYE KONU TARAFLAR
          1.  Emre Fırtına
          (Aşağıda belirtilen tüzel kişilikler kısaca ‘’ uygunferibot.com ‘’olarak anılacaktır)
          <br></br>
          Vergi Dairesi: Gaziemir
          <br></br>
          Vergi Numarası: 37597671320
          <br></br>
          Tel : +90 530 130 54 08
          <br></br>
          E-mail: destek@uygunferibot.com
          <br></br>
          2. uygunferibot.com adresi üzerinden gerek kendisi, gerek ise bir başkası vasıtasıyla (gerçek şahıs, Turizm acentesi veya komisyoncu) üzerinden bilet alımını gerçekleştiren gerçek veya tüzel kişiler;  “YOLCU” olarak anılacaktır.
          MADDE 2.: SÖZLEŞMENİN KONUSU
          Uygun Feribot ‘un düzenlediği ve / veya pazarladığı, aracılık ettiği, tüm yurtdışı feribot ve gemi seferleri ve buna bağlı hizmetler için yolcu ve araç bileti satışı kurallarını, tarafların sorumluluklarının belirlenmesini içermektedir. Yolcu,  internet sitesi üzerinden işbu sözleşmede belirtilen bilet siparişini sonlandırdığı anda sözleşme konusu bileti TESLİM ALMIŞ, hizmetin tamamını eksiksiz teslim aldığını kabul eder. Uygun Feribot ve Yolcu, teslimle birlikte sözleşmede bulunan kurallara uyacağını ve edimlerini yerine getireceğini kabul ve taahhüt etmiş sayılır.
          MADDE 3.: SÖZLEŞMENİN SÜRESİ
          İşbu sözleşme, tanzim ve elektronik onaylama işlemini takip eden yılsonuna kadar geçerli olup, sözleşme süresi tarafların yeni edinimleriyle uzatılabilir.
          MADDE 4.: GENEL ŞARTLAR
          Bu yolculukta taşıyanın yapacağı yolcu ve bagaj ulaştırması, Türk Ticaret Kanunu'nun Deniz Ticaretine hükmeden kurallarına, 1974 Atina Konvansiyonunca saptanmış kurallara ve "Uygun Feribot" un aşağıda belirtilen standart form ve kurallarına bağlıdır. Sözü geçen kural ve koşullar Taşıyanın sorumluluğunu düzenler ve birçok hallerde sınırlandırılacaktır. Yolcu bu kurallar bütününü peşinen kabul etmiş sayılmaktadır.
          A. Yolcu Biletinin Kullanımı:
          Bilet yolcuya, yalnızca üzerinde yazılı sefer için iniş biniş hakkı sağlar. Yolcu tarafından vaktinde kullanılmayan biletler geçersiz olup, bir başka sefer için de olsa biniş hakkı sağlamaz. Biletin üzerinde yer alan bilgilerin doğruluğu, bilet satın alındığında kabul edilmiş sayılır. Sonradan yapılacak itirazlar geçersizdir. Bileti ibraz eden şahsın, Bilette "Yolcu İsmi" hanesindeki şahıs olduğunu ispat edememesi durumunda Taşıyan böyle bir bilete el koyma, yolcuyu gemiye kabul etmeme hakkını saklı tutar. Biletini kaybedenler, yeni bir bilet satın almak zorundadırlar.
          B. Gemiye Biniş İşlemleri:
          Yolcu, kendisi adına düzenlenmiş bileti ile; gerekli formalitelerin, biniş ve hareket usullerinin tamamlanmasına imkan verecek şekilde, geminin hareket saatinden en az 30 dakika önce, araçlı yolcunun ise 90 dakika önce, Uygun Feribot Check-in ofislerine gelmesi gerekmektedir. Uygun Feribot, Check-in işlemlerini geminin kalkışına 20 dakika kala sona erdirir. Yolcu, Taşıyanın biniş işlemlerini başlattığı yere veya biniş kapısına zamanında gelmezse veya yolculuk için gerekli belgeye sahip değilse veya yolculuğa hazır değilse ( ülkelerin talep ettiği belge ve geçiş için gerekli dokümantasyona sahip olmaması dâhil ), Uygun Feribot, Yolcu için ayırdığı yeri iptal eder ve seferi geciktirmez. Taşıyan, Yolcunun bu maddenin koşullarına uymamasından doğan bilet hakkı geçersiz hale gelir. Yolcu gerek sürelere riayet etmemesi, yeterli geçiş belgelerine sahip olmamasından dolayı biletinin iptal edileceğini, ücret iadesinin yapılmayacağını peşinen kabul eder. Sefere hazır yolcu, sefer kalkış saatinden 10 dakika önce, tüm gümrük işlerini bitirmiş ( Pasaport ve gümrük kontrolleri dâhil), yolculuğa hazır olarak gemi içerisinde bulunmak zorundadır.
          C. Bagaj Kuralları:
          Aksi kararlaştırılmış olmadıkça yolcu, taşıma mukavelesi gereğince gemiye kişinin el yordamıyla bir defada taşıyabileceği bagaj için taşıma ücretinden başka bir ücret vermeye mecbur değildir. Yolcu, seyahat süresince gemide yanında sadece bir valiz ya da el çantası bulundurabilir. Bunun dışındaki bagaj ilgili gemi personeli tarafından kayıt altına alınacak ve emniyetli bir yerde muhafaza edilecektir. Yolcu kayıtlı bagajı içinde kırılabilir ve bozulabilir maddeler, para, mücevher, değerli madenler, gümüş eşya, senetler veya diğer ticari değerli kâğıtlar, pasaport ve diğer hüviyet belgeleri veya örneklerini bulunduramaz. Bu maddede sayılanların Bagaj içinde bulundurulmasından doğacak zarardan taşıyan sorumlu tutulamaz. Bagaj kayıplarını Taşıyandan talep edemez. Uluslararası kurallar gereği; Bagaj olarak kabul edilmeyen maddeler için "Taşımanın Reddi" hakkını Uygun Feribot uhdesinde bulundurur. Taşıyan reddi ile alakalı olarak müşterinin tazmin hükümleri geçersizdir.
          D. Gemi İçerisinde Davranış:
          Yolcu kaptanın gemideki nizama yönelik bütün talimatlarına uymaya mecburdur. Yolcu gemi içinde, gemiyi veya herhangi bir kişiyi veya malı tehlikeye düşürecek şekilde davranışta bulunursa veya gemi personelinin görevlerini yapmasını engellerse veya gemi personeli talimatlarına uymazsa veya diğer Yolcuların makul itirazlarına sebebiyet verirse; Uygun Feribot ve Gemi Personeli, Yolcuyu zapt etmek de dâhil olmak üzere, böyle bir davranışın devamını önlemek için gerekli gördüğü her türlü tedbirleri alabilir. Her ne sebeple olursa olsun gemi kaptanı Uluslararası kurallar gereği; Yolcu, Araç ve Yükü "Taşımanın Reddi" hakkını uhdesinde bulundurur. Yolcu, Gemi Personelinin alacağı karar doğrultusunda gemiye kabul edilmeyebileceğini, Uygun Feribot veya Gemi Personelinin reddi ile alakalı olarak müşterinin tazmin hükümleri geçersiz olduğu peşinen kabul edilmiş sayılır.
          E. Yolcu Beraberi Evcil Hayvan Taşıması:
          Hayvan taşımasının kabulü, Yolcunun bu hayvana ilişkin tüm sorumluluğu yüklenmesine bağlıdır. Evcil hayvanın ölümünden, hastalanmasından, gecikmesinden veya kaybından veya yaralanmasından veya bu sebeple ortaya çıkabilecek masraf ve sair zararlardan Taşıyan sorumlu olmayacaktır. Evcil hayvanların her türlü yiyecek ve içecek ihtiyaçlarından da sahipleri sorumludur.
          Sakız Adası ( Chios ): Evcil hayvanla geçiş için Sakız adasında bulunan veterinere; Seyahat tarihinizi, Evcil hayvanınıza ait Mikrochip numarası, Aşı karnesi fotokopisi, pet bilgileri ve sahibinin bilgilerini ‘’ chiostkt@gmail.com ‘’ e-mail adresine 2 ( iki ) çalışma günü öncesinde gönderilerek yazılı teyit alınması gerekmektedir.
          Midilli Adası ( Lesvos ) : Evcil Hayvanınızla geçiş için evcil hayvanınıza ait Mikrochip numarası, Aşı karnesi fotokopisi, pet bilgileri ve sahibinin bilgilerini yanınızda bulundurmanız yeterli olmaktadır. Mevcut durum haricinde Yunan ve Türkiye evcil hayvan taşıma kuralları değişmesi durumunda Uygun Feribot sorumluluk kabul etmediği taraflarca kabul edilir.
          F. Sefer Tarifeleri ve Sefer İptalleri:
          Uygun Feribot, ilan ettiği seferlerini yerine getirmekle yükümlüdür ve Yolcu Araç ve Bagajı makul sürede taşımak için gerekli gayreti göstermeyi üstlenir. Uygun Feribot, (hava koşulları ve deniz durumu, liman tesislerinin devre dışı kalması, idarenin sefer engellemesi, salgın hastalık tedbirleri, grev v.b.) olağanüstü ve mücbir durumlarda sözleşmeden geri çekilme, seyrüsefer tarifelerini değiştirme, yola devam etme veya taşımaya ara verme haklarını saklı tutar. Hava muhalefeti sebebiyle gerçekleştirilemeyen seferlerde yolcuya koşulsuz tam iade yapılır.
          G. Taşımanın Reddi
          Uygun Feribot; güvenlik nedenleriyle veya takdir yetkisini kullanarak aşağıdaki ve benzeri hallerde Yolcu, Araç ve Bagaj taşımayı reddedebilir. Yolcu; diğer kişilere veya mallara herhangi bir risk veya tehlike arz ediyorsa, taşıyanın talimatlarına uymuyorsa, güvenlik kontrolünü reddetmişse veya yürürlükteki yasaları veya taşıyıcının ücret tarifelerini veya kurallarını ihlal ederek bilet temin etmiş olan herhangi bir kişiyi taşıyıcı, taşımayı reddetme hakkını saklı tutar. Denizde tehlikeli yüklerin taşınması ile ilgili uluslararası "IMDG Code" sözleşmesi kapsamında bulunan her türlü yanıcı, patlayıcı ve radyoaktif v.s yüklerin taşınması kesinlikle yasaktır. Tehlikeli yük kapsamına giren maddelerin, avcılık ve spor amaçları dışında kullanılan ateşli silahların ve mühimmatın, kırılabilir veya bozulabilir nitelikleri haiz olan veya ağırlığı, boyutu bakımından Taşıyanın görüşüne göre taşıma için uygun olmayan nitelikteki maddelerin taşınması yasaktır. 16 yaşından küçük çocuklar ancak bir refakatçi eşliğinde seyahat edebilirler. Taşıyan kurallarına göre, refakatsiz çocukların, yardıma muhtaç Yolcuların, hamile kadınların veya hastalıklı kişilerin, taşıma için kabulü ancak Uygun Feribot ile önceden yapılacak mutabakat ve düzenlemelere tabi olabilir. Seyahat sırasında böyle bir Yolcunun sıhhi durumunun bozulması nedeni ile Uygun Feribot ’a herhangi bir sorumluluk yüklenemez.
          MADDE 5. : TARAFLARIN YÜKÜMLÜLÜKLERİ / İPTAL İADE KOŞULLARI
          Yolcu, uygunferibot.com ve satış kanalları ile doğrudan veya dolaylı aldığı tüm yolcu ve araç biletlerinin; 27 Kasım 2014 tarihinde resmi gazetede yayınlanan Mesafeli Sözleşmeler Yönetmeliğine Bölüm 2 - H maddesinde belirtilen ‘’ yolcu taşımacılığı yapan firmalar muafiyet kapsamında ’’ olduğunu, cayma hakkının olmadığını, peşinen kabul ve taahhüt eder. Bilet alan tüm yolcular yönetmelik gereği cayma hakkının olmadığının yanı sıra, bilet iptal iadesinin veya kredi kartına geri ödeme yapılmayacağını, iade ile ilgili talebinin olamayacağını bilerek feribot biletini satın aldığını peşinen kabul eder.
          Yolcu, Bilet satın aldıktan sonra hizmeti tam ve eksiksiz satın aldığını kabul eder. Sefere her ne sebeple olursa olsun katılamaması veya katılmaması durumunda, hizmeti satın almaması ile ilgili iddia öne süremeyeceği gibi, bankalar aracılığıyla iade talep etmeyeceğini / edemeyeceğini peşinen kabul eder.
          Yolcu, üzerinde ismi yazılı bileti bir başkasına devredemez, başkasının kullanımına sunamaz, isim değişikliği talebinde bulunamaz.
          Yolcu, sözleşmeye konu tüm biletlerin iptal iadesini talep edemez. Uygun Feribot, iptal veya iade yapmaz. Sadece sefer süresine 48 saat kalaya kadar, 1 kereye mahsus olmak üzere tarih / saat değişikliği veya açığa alma işlemi gerçekleştirilebilir. Açığa alınan biletlerin geçerlilik süresi 6 ay aydır. Biletin düzenleme tarihinden 6 ay sonrasında kullanılmayan bilet otomatik olarak iptal olur. Yolcu iptal edilen biletlerle ilgili talepte bulunamaz. Yolcu, açık biletinin olduğunu elindeki belge ile ispatlamak zorundadır. Sefer süresine 48 saat kalan biletler açığa alınamaz, sefer değişikliği yapılamaz.
          Uygun Feribot, uygunferibot.com web sitesinden ve gişelerden tanzim edilen biletli yolculara, Uygun Feribot Yolcu ve Araç Taşıma Kuralları çerçevesinde seyahat hizmeti sağlayacağını taahhüt etmektedir
          Uygun Feribot, ihtiyaç duyulduğunda, yolculukla ilgili bilgi vermek amacıyla telefon ve bilişim desteği sağlayacaktır.
          Taraflar bilet satın alım işleminin tamamlanması sonrasında; uygunferibot.com web adresinde yayınlanan bilet satış kurallarına uyacağını peşinen kabul edecektir.
          Yolcular mobil numaralarını sisteme kaydetmeyle yükümlüdür. Sisteme eksik veya yanlış mobil telefon bilgisi verilmesi durumunda, bilgilendirme yapılmamasından kaynaklanan sorumluluk yolcuya aittir.
          Yolcu, Konsolosluklardan VİZE RED alınmasını gerekçe göstererek yolcu biletinin iptal ve iadesini talep edemez. Bu taleple ilgili isim değişikliği talep edemez.
          Her iki ülke otoriteleri tarafından gemiye binmesine izin verilmeyen veya geçerli herhangi bir nedenden dolayı seyahat edemeyen yolcuya bilet ücreti iadesi yapılmaz, biletin tekrar kullanmasına izin verilmez.
          Yolcu, bilet alımı sonrasında tarafına gönderilen bilgilendirme yazısındaki tüm taşıma kurallarını ve sözleşmedeki tüm kuralları peşinen kabul eder.
          Uygun Feribot, gerekli gördüğünde bu taşıma kurallarının bir kısmını veya tamamını değiştirebilir.
          MADDE 6.: GENEL TAŞIMA KURALLARI
          Yolcu; bu bileti alarak, Uygun Feribot ’un uygunferibot.com internet adresinde ilan ettiği, ‘’ Uygun Feribot Yolcu ve Araç Taşıma Sözleşmesi’’ ’ni peşinen kabul etmiş sayılır.
          Yolcu, bilet üzerindeki sefer ve yolcu bilgilerinin doğruluğunu kontrol etmek ile yükümlüdür.
          Biletler kişiye ait olup başkasına devredilemez. Her ne sebeple olursa olsun iptal / iade yapılmaz.
          Yolcu; Check-in işlemlerinin tamamlanmasına imkân verecek şekilde, geminin hareket saatinden en az 30 dakika önce, araçlı yolcunun ise 90 dakika önce, Uygun Feribot Check-in ofislerine gelmesi gerekmektedir. Uygun Feribot, Check-in işlemlerini geminin kalkışına 20 dakika kala sona erdirir. Yolcu, sefer kalkış saatinden 10 dakika önce, tüm gümrük işlerini bitirmiş, yolculuğa hazır olarak gemi içerisinde bulunmak zorundadır.
          Araçlı yolcular, araçları başkasının adına kayıtlı ise; noter vekâletnamesi getirmek zorundadır. Araç şirket adına kayıtlı ise; imza yetkilisi olduğu gösterir imza sirküleri veya vekâletname getirmek zorundadır.
          Araçlı yolcular, araçlarına uluslararası ‘’Yeşil Sigorta’’ ( Yunanistan’da ve Türkiye’de geçerli sigorta ) yaptırmak zorundadır.
          Uygun Feribot, biletlerde iptal veya iade yapmaz. Sadece sefer süresine 48 saat kalaya kadar, 1 kereye mahsus olmak üzere tarih / saat değişikliği veya açığa alma işlemi gerçekleştirilebilir. Sefer süresine 48 saat kalan biletler açığa alınamaz, sefer değişikliği yapılamaz.
          Açığa alınan biletlerin geçerlilik süresi 6 ay aydır. Biletin düzenleme tarihinden 6 ay sonrasında kullanılmayan bilet otomatik olarak iptal olur.
          Yolcunun, adli, idari, vize rejimi, beraberinde götürmek istediği araç, evcil hayvan ile ilgili ve her ne sebeple olursa olsun seyahatine mani durumlarda hizmet akdi sona ermiş olup, yolcunun iade talebi kabul edilmez.
          Tüm Pasaportların (yeşil pasaport dâhil ) geçerlilik süresi, giriş yapılacak tarihten itibaren asgari 90 günden fazla olmalıdır.
          Normal pasaport sahiplerinin, giriş sayısını ve kalış süresini doldurmamış, seyahat tarihlerini kapsayan Schengen Vizesine sahip olması gerekmektedir.
          Pasaportunda K.K.T.C. girişi bulunanlar, Yunanistan’a kabul edilmemektedirler.
          Yolcu, geçerli bir seyahat belgesi sorumluluğunun kendisine ait olduğunu kabul eder, geçerli bir seyahat belgesinin olmamasından dolayı Uygun Feribot‘ un uğrayacağı tüm zararları karşılamak zorundadır.
          MADDE 7. : FERAGAT VE DEĞİŞİKLİK
          Gemilerimizde seyahat etmek üzere bilet almış her yolcu yukarıdaki hükümlerin hepsini aynen kabul etmiş sayılır. Taşıyan önceden haber vermeksizin ücret, tarifeler ve sefer günlerinde değişiklik yapma hakkını saklı tutar. Sözleşmenin belirli hükümlerinin geçersiz sayılması halinde, kalan hükümlerin hiçbiri geçerliliğini yitirmez. Taşıyanın hiçbir acentesi, personeli veya temsilcisi bu sözleşmenin hükümlerini değiştirmeye veya iptal etmeye yetkili kılınmamıştır.
          MADDE 8.: VİZE PASAPORT GÜMRÜK MEVZUATI VE CEZAİ SORUMLULUK
          Ulaşımın sağlanabilmesi için yolcular, yürürlükte bulunan şart ve mevzuata uymak mecburiyetindedirler. Yunanistan’a giriş yapan Yolcu, pasaportunda KKTC giriş/çıkış damgası bulunmadığını, seyahat belgesinin gideceği ülkede geçerli olduğunu, vizesinin seyahat tarihinde geçişine engel olmayacak şekilde geçerli şartları taşıdığını kabul ve taahhüt eder. Aksi nedenle Uygun Feribot aleyhine herhangi bir devlet otoritesince idari yaptırım uygulanırsa, Uygun Feribot ’un uğradığı maddi ve manevi zararın yanı sıra en az 5.000 (beşbin) Euro cezai şart ödemeyi kabul ve taahhüt eder.
          MADDE 9.: HUKUK VE YETKİLİ MAHKEME
          İşbu Sözleşme Türkiye Cumhuriyeti yasaları altında yorumlanacak ve uygulanacak olup; bu Sözleşme koşullarından doğabilecek herhangi bir anlaşmazlık halinde kanundaki sınırlar gözetilerek İzmir Tüketici Hakem Heyeti veya İzmir Mahkemeleri yetkili olacaktır.
          İşbu sözleşme, 9 ( Dokuz ) ana maddeden oluşmakta olup bilet alım tarihinde elektronik onay ile geçerliliği başlamıştır.
        </p>
      </div>
    </>
  )
}

export default SalesContract