import React from 'react'
import '../pages/Css/HakkimizdaPage.css'
import { TabTitle } from "../tools/GeneralFunction";
import { CommonHeader } from '../components/CommonHeader';
import { Helmet } from 'react-helmet';

const FrequentlyAskedQuestions = () => {
  const title = "Sıkça Sorulan Sorular"
  TabTitle(`${title} - Uygun Feribot | Yunan Adaları Feribot Bileti Satın Al`);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <CommonHeader title={title} />
      <div className='MetinContainerHakkimizda'>
        <p>aydınlatma metni buraya</p>
      </div>
    </>
  )
}

export default FrequentlyAskedQuestions