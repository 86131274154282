import "./styles.css";
import IletisimForm from "../../components/IletisimForm/IletisimForm";
import { TabTitle } from "../../tools/GeneralFunction";
import { FaAngleRight } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6";
import { HiOutlineTicket } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../components/CommonHeader";
import { MainContainer } from "../../components/Layouts/MainContainer";
import { Helmet } from "react-helmet";

const ContactPage = () => {
  TabTitle("İletişim - Uygun Feribot | Yunan Adaları Feribot Bileti Satın Al");

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <CommonHeader title="İletişim">
        <MainContainer>
          <div className="contact-banner-text-container">
            <span>
              Hızlı işlemler ile işlemlerinizi kolayca yapabilir, iletişim formu üzerinden öneri ve şikayetlerinizi bize iletebilir ve iletişim bilgilerimize ulaşabilirsiniz.
            </span>
          </div>
          <div className="contact-banner-card-container">
            {/* <Link to="/iletisim" style={{ textDecoration: "none" }}>
            <div className="contact-banner-card">
              <TbTicketOff className="contact-banner-card-icon-first" />
              <p>Biletimi açığa almak istiyorum</p>
              <FaAngleRight className="contact-banner-card-icon-two"/>
            </div>
          </Link> */}
            <Link to="/" style={{ textDecoration: "none" }}>
              <div className="contact-banner-card">
                <FaCheck className="contact-banner-card-icon-first" />
                <p>Online bilet almak istiyorum</p>
                <FaAngleRight className="contact-banner-card-icon-two" />
              </div>
            </Link>
            <Link to="/pnr-sorgula" style={{ textDecoration: "none" }}>
              <div className="contact-banner-card">
                <HiOutlineTicket className="contact-banner-card-icon-first" />
                <p>Biletimi görüntülemek istiyorum</p>
                <FaAngleRight className="contact-banner-card-icon-two" />
              </div>
            </Link>
          </div>
        </MainContainer>
      </CommonHeader>

      <IletisimForm />
    </>
  );
};

export default ContactPage;
