import React from "react";
import "../PNR/pnr-text.css";

const PnrText = () => {
  return (
    <section className="pnr-text-section">
      UygunFeribot.com üzerinden satın almış olduğunuz feribot biletine ait rezervasyon bilgilerine bu sayfa üzerinden ulaşabilirsiniz.  PNR numaranız, biletinizi satın aldığınızda size verilen özel bir referans numarasıdır.

      <h2>PNR Sorgulama Nasıl Yapılır?</h2>

      <ol>
        <li>
          PNR Numarası: Biletinizde yer alan 10 haneli PNR numarasını girin.
        </li>
        <li>
          E-posta Adresi: Bilet sahibi olan kişinin satın alma sırasında kullandığı e-posta adresini girin.
        </li>
        <li>
          Sorgula: Bilgilerinizi girdikten sonra “Sorgula” butonuna tıklayarak rezervasyon detaylarını görüntüleyebilirsiniz.
        </li>
      </ol>

      <p>
        Bu sayfa aracılığıyla rezervasyon durumunuzu, sefer bilgilerinizi ve biletinizle ilgili diğer detayları kolayca öğrenebilirsiniz. İyi yolculuklar dileriz!
      </p>
    </section>
  );
};

export default PnrText;
