import React, { useState, useEffect } from "react";
import "../Admin/Css/Seferler.css";
import { IoEyeSharp } from "react-icons/io5";
import { IoPencilSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { Button, Divider, Layout, Modal } from "antd";
import { RiShipLine } from "react-icons/ri";
import { PiHandshakeLight } from "react-icons/pi";
import SeferlerTable from "./Table/Seferler/SeferlerTable";

const { Header, Content } = Layout;

const Seferler = () => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [DeletemodalOpen, setDeleteModalOpen] = useState(false);
  const [UpdatemodalOpen, setUpdateModalOpen] = useState(false);
  const [ShowmodalOpen, setShowModalOpen] = useState(false);

  const [from, setFrom] = useState("BODRUM");
  const [to, setTo] = useState("RODOS");
  const [varisLimanlari] = useState({
    FETHIYE: ["RODOS"],
    BODRUM: ["RODOS", "KOS", "SYMI", "PATMOS"],
    KUSADASI: ["SAMOS", "PATMOS"],
    CESME: ["CHIOS-SAKIZ"],
    AYVALIK: ["MİDİLLİ"],
    KAS: ["MEIS"],
    KOS: ["BODRUM"],
    CHIOS_SAKIZ: ["ÇEŞME"],
    MIDILLI: ["AYVALIK"],
    MEIS: ["KAS"],
    SAMOS: ["KUŞADASI"],
    SYMI: ["BODRUM"],
    PATMOS: ["BODRUM", "KUŞADASI"],
    RODOS: ["BODRUM", "FETHiYE"],
    SEFERIHISAR: ["SAMOS"],
    // KALKIS LİMANLARİ
  });

  const [GidisTarihi, setGidisTarihi] = useState("");
  const [DonusTarihi, setDonusTarihi] = useState("");
  const [donusTarihiAktif, setDonusTarihiAktif] = useState(true);

  useEffect(() => {
    // Günün tarihini al
    const bugun = new Date().toISOString().split("T")[0];
    setGidisTarihi(bugun);
    if (donusTarihiAktif) {
      setDonusTarihi(bugun);
    } else {
      setDonusTarihi("");
    }
  }, [donusTarihiAktif]);

  const TourModalOff = () => {
    setOpenAddModal(false);
  };

  const DeleteModalOpen = () => {
    setDeleteModalOpen(true);
  };
  const DeleteModalOff = () => {
    setDeleteModalOpen(false);
  };

  const UpdateModalOpen = () => {
    setUpdateModalOpen(true);
  };
  const UpdateModalOff = () => {
    setUpdateModalOpen(false);
  };

  const ShowModalOpen = () => {
    setShowModalOpen(true);
  };
  const ShowModalOff = () => {
    setShowModalOpen(false);
  };

  const [number, setNumber] = useState("");

  const handleChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setNumber(event.target.value);
    }
  };

  const submitAdd = () => {

  }

  return (
    <SeferlerTable />
  )
};

export default Seferler;