export const getItem = (key) => {
  const storageData = localStorage.getItem(key);
  return JSON.parse(storageData);
};

export const setItem = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const removeItem = (key) => {
  localStorage.removeItem(key);
};
