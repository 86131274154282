import React from 'react';
import { Form, Input, Row, Col, Spin } from 'antd';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

export const PaymentStep = ({ loadingPaymentStep, iframeToken }) => {

  // console.log("iframeToken", iframeToken);

  return (
    <div>
      <script src="https://www.paytr.com/js/iframeResizer.min.js"></script>
      {
        loadingPaymentStep ?
          <div style={{ padding: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spin />
          </div>
          :
          <iframe src={iframeToken} id="paytriframe" frameborder="0"
            scrolling="no" style={{ width: '100%', height: '150vh' }}></iframe>
      }

      <script>iFrameResize({ },'#paytriframe');</script>
    </div>
  )
}

export const Payment2 = ({
  number,
  setNumber,
  name,
  setName,
  expiry,
  setExpiry,
  cvc,
  setCvc,
  focus,
  setFocus
}) => {
  const handleNumberChange = (e) => {
    const input = e.target.value.replace(/\D/g, ''); // Sayısal olmayan karakterleri kaldır
    if (input.length <= 16) {
      const formattedNumber = input
        .replace(/(\d{4})(?=\d)/g, '$1 ')
        .trim(); // 4 haneli gruplar halinde formatla ve fazladan boşlukları kaldır
      setNumber(formattedNumber);
    }
  };

  const handleExpiryChange = (e) => {
    const input = e.target.value.replace(/\D/g, ''); // Sayısal olmayan karakterleri kaldır
    if (input.length <= 4) {
      const formattedExpiry = input
        .replace(/(\d{2})(\d{1,2})/, '$1/$2')
        .slice(0, 5); // Ay/Yıl formatına dönüştür ve uzunluğu 5 karakterle sınırla
      setExpiry(formattedExpiry);
    }
  };

  const handleCvcChange = (e) => {
    const input = e.target.value.replace(/\D/g, ''); // Sayısal olmayan karakterleri kaldır
    if (input.length <= 3) {
      setCvc(input);
    }
  };

  const isValidExpiry = (expiry) => {
    if (expiry.length !== 5) return false;
    const [month, year] = expiry.split('/').map(num => parseInt(num, 10));
    if (month < 1 || month > 12) return false;
    const currentYear = new Date().getFullYear() % 100;
    const currentMonth = new Date().getMonth() + 1;
    if (year < currentYear || (year === currentYear && month < currentMonth)) return false;
    return true;
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xl={12} lg={24} md={24} xs={24}>
        <Cards
          number={number}
          name={name}
          expiry={expiry}
          cvc={cvc}
          focused={focus}
        />
      </Col>

      <Col xl={12} lg={24} md={24} xs={24}>
        <Form layout='vertical'>
          <Row gutter={[16, 16]}>
            <Col md={16} xs={24}>
              <Form.Item label="Kart Sahibi">
                <Input
                  type="text"
                  name="name"
                  onChange={e => setName(e.target.value)}
                  onFocus={e => setFocus(e.target.name)}
                />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label="Ay/Yıl"
                validateStatus={expiry && !isValidExpiry(expiry) ? 'error' : ''}
                help={expiry && !isValidExpiry(expiry) ? 'Geçersiz son kullanma tarihi' : ''}
              >
                <Input
                  type="tel"
                  name="expiry"
                  value={expiry}
                  onChange={handleExpiryChange}
                  onFocus={e => setFocus(e.target.name)}
                />
              </Form.Item>
            </Col>

            <Col md={18} xs={24}>
              <Form.Item label="Kart Numarası">
                <Input
                  type="tel"
                  name="number"
                  value={number}
                  onChange={handleNumberChange}
                  onFocus={e => setFocus(e.target.name)}
                />
              </Form.Item>
            </Col>

            <Col md={6} xs={24}>
              <Form.Item label="CVC">
                <Input
                  type="tel"
                  name="cvc"
                  value={cvc}
                  onChange={handleCvcChange}
                  onFocus={e => setFocus(e.target.name)}
                />
              </Form.Item>
            </Col>

          </Row>
        </Form>
      </Col>
    </Row>
  );
};
