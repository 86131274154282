import { Card, Image } from "antd";
import '../styles.css'

export const FeatureCard = ({ title, description, icon }) => (
    <div className="site-card-border-less-wrapper">
        <Card className="feature-card" bordered={false} styles={{ title: { textAlign: 'center' } }}>
            <div style={{ textAlign: 'center', marginBottom: 16 }}>
                <Image src={icon} preview={false} width={80} />
            </div>
            <h1 className="title">{title}</h1>

            <p className="description">{description}</p>
        </Card>
    </div>
)