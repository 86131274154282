import axios from "axios";
import { SERVICE_URL } from "..";
import { getItem } from "../../utils/storage";

export const Tours = () => {
  const userData = getItem("adminUser");
  // limanların listesini getirir
  const list = () => {
    return axios.get(`${SERVICE_URL}/tours`);
  };

  const search = (params) => {
    const { departure_port_id, arrival_port_id, direction } = params;
    return axios.get(`${SERVICE_URL}/Ferries/FerrySearch`, { params });
  };

  const create = (params) => {
    return axios.post(
      `${SERVICE_URL}/tours`,
      {
        ...params,
      },
      {
        headers: {
          token: userData.token,
        },
      }
    );
  };

  const update = (id, params) => {
    return axios.put(`${SERVICE_URL}/tours/${id}`, {
      params,
      headers: {
        token: userData.token,
      },
    });
  };

  const remove = (id) => {
    // return axios.get(`${SERVICE_URL}/reservation_guests`, { params });

    return axios.delete(`${SERVICE_URL}/tours/${id}`, {
      headers: {
        token: userData.token,
      },
    });
  };

  return {
    list,
    create,
    search,
    update,
    remove,
  };
};
