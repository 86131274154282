import axios from "axios";
import { SERVICE_URL } from "..";

export const CurrencyParities = () => {
  const calculateParities = (params) =>
    axios.get(
      `${SERVICE_URL}/Common/CalculateParities?amount=${params?.amount}&sourceCurrencyTypeId=${params?.sourceCurrencyTypeId}&targetCurrencyTypeId=${params?.targetCurrencyTypeId}`
    );

  return {
    calculateParities,
  };
};
