import React from "react";
import "../Admin/Css/Seferler.css";
import ReservationsTable from "./Table/Reservations/ReservationsTable";

const Reservations = () => {

  return (
    <>
      <ReservationsTable />
    </>
  );
};

export default Reservations;