import React from "react";
import "../Admin/Css/BlogEdit.css";
import { FaEuroSign } from "react-icons/fa6";
import { GiTicket } from "react-icons/gi";
import "../Admin/Css/Payment.css";
import PaymentsTable from "./Table/Payments/PaymentsTable";

const Payments = () => {
  return (
    <>
      <div className="blogedit-banner">
        <div className="blogedit-banner-text">
          <h1>Ödemeler</h1>
        </div>
      </div>
      <div className="container-admin">
        <div className="card-info">
          <div className="card-info-text-container">
            <p>Satılan Bilet</p>
            <GiTicket className="card-info-icon" />
          </div>
          <span>Bugün :</span>
          <h4>3</h4>
        </div>
        <div className="card-info">
          <div className="card-info-text-container">
            <p>Satılan Bilet</p>
            <GiTicket className="card-info-icon" />
          </div>
          <span>30 Günlük :</span>
          <h4>3</h4>
        </div>
        <div className="card-info">
          <div className="card-info-text-container">
            <p>Aylık Ciro</p>
            <FaEuroSign className="card-info-icon" />
          </div>
          <span>30 Günlük :</span>
          <h4>520.000 TL</h4>
        </div>
        <div className="card-info">
          <div className="card-info-text-container">
            <p>Firma</p>
            <GiTicket className="card-info-icon" />
          </div>
          <h4>3</h4>
        </div>
      </div>

      <PaymentsTable />
    </>
  );
};

export default Payments;
