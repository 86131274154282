import axios from "axios";
import { SERVICE_URL } from "..";

export const Coupons = () => {
  const getCouponByCode = (couponCode) =>
    axios.get(`${SERVICE_URL}/Coupon/GetCouponByCode?couponCode=${couponCode}`);

  const couponIsActive = ({ couponCode, routeType, phoneNumber }) => {
    return axios.get(
      `${SERVICE_URL}/Coupon/GetActiveCouponByCouponId?couponCode=${couponCode}&couponUsageTypeId=${routeType}&phoneNumber=${phoneNumber}`
    );
  };

  return {
    getCouponByCode,
    couponIsActive,
  };
};
