import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Radio, Button, Space, notification, Spin } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import '../../styles/common.css';
import './styles.css';
import { ReservationSteps, TourCard } from './partials';
import dayjs from 'dayjs';
import { SearchToursWidget } from '../../components/SearchToursWidget';
import { TabTitle } from '../../tools/GeneralFunction';
import { CommonHeader } from '../../components/CommonHeader';
import { initialGuestData } from '../../utils/initialGuestData'
import { MainContainer } from '../../components/Layouts/MainContainer';
import _ from 'lodash'
import { PriceCalculate } from '../../services/PriceCalculate';
import { CurrencyParities } from '../../services/Common/CurrencyParities';

export const SearchTours = () => {
    const title = "Biletinizi Seçin";
    TabTitle(`${title} - Uygun Feribot | Yunan Adaları Feribot Bileti Satın Al`);
    const location = useLocation();
    const navigate = useNavigate()
    const searchParams = location?.state;

    const [GidisTarihi, setGidisTarihi] = useState(searchParams?.ServiceDate || "");
    const [DonusTarihi, setDonusTarihi] = useState(searchParams?.ReturnServiceDate || "");
    const [donusTarihiAktif, setDonusTarihiAktif] = useState(searchParams?.RouteType == 2 || true);
    const [YetiskinSayisi, setYetiskinSayisi] = useState(searchParams?.AdultQuantity || 1);
    const [CocukSayisi, setCocukSayisi] = useState(searchParams?.ChildQuantity || 0);
    const [BebekSayisi, setBebekSayisi] = useState(searchParams?.InfantQuantity || 0);
    const [ChildAges, setChildAges] = useState(searchParams?.ChildAges || []);
    const [selectedDepartureTour, setSelectedDepartureTour] = useState(undefined)
    const [selectedArrivalTour, setSelectedArrivalTour] = useState(undefined)

    const [departureTours, setDepartureTours] = useState([])
    const [arrivalTours, setArrivalTours] = useState([])
    const [continueToCreateReservation, setContinueToCreateReservation] = useState(false)

    const [departurePrice, setDeparturePrice] = useState()
    const [arrivalPrice, setArrivalPrice] = useState()
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalPriceTL, setTotalPriceTL] = useState(0)

    const [loadingTours, setLoadingTours] = useState(false)
    const [disableContinue, setDisableContinue] = useState(true)
    const [passengerPricesData, setPassengerPricesData] = useState({})

    const tempReservationData = {
        departure_tour_id: 1,
        arrival_tour_id: 1,
        child_count: 1,
        adult_count: 1,
        departure_date: dayjs().format('YYYY-MM-DD').toString(),
        arrival_date: dayjs(),
        guests: [],
        departur_tour_price: 500.00
    };
    const [reservationData, setReservationData] = useState(tempReservationData)

    const returnTourComponentRef = useRef(null);

    const calculateTourPrice = ({ routeType }) => {
        const payload = {
            routeType: routeType,
            serviceDate: GidisTarihi,
            departureRegionID: 300,
            arrivalRegionID: 965,
            adultQuantity: YetiskinSayisi,
            childQuantity: CocukSayisi,
            infantQuantity: BebekSayisi,
            vehicleQuantity: 0,
            //childAge:
            //infantAge:
            //vehicleType:
            havePets: false,
            departureExpeditionID: selectedDepartureTour?.expeditionID,
            returnExpeditionID: selectedArrivalTour?.expeditionID,
            langCode: "TR"
        }
        if (donusTarihiAktif && DonusTarihi && DonusTarihi != '') {
            payload.returnServiceDate = dayjs(DonusTarihi).format('YYYY-MM-DD')
        }

        PriceCalculate().calculate(payload).then((resp) => {
            if (resp?.data?.statusCode == 200) {
                const calculatedPriceData = resp.data?.data?.result
                // console.log("calculatedPriceData", calculatedPriceData);
                const passengerPrices = calculatedPriceData?.passenger
                const total = passengerPrices.reduce((sum, item) => sum + item.totalAmount, 0);

                setTotalPrice(total)
                CurrencyParities().calculateParities({
                    amount: total, sourceCurrencyTypeId: 2, targetCurrencyTypeId: 1
                }
                ).then(({ data }) => {
                    if (data?.statusCode == 200) {
                        const totalAmountTL = data?.data
                        setTotalPriceTL(totalAmountTL)
                    }
                })
                setDisableContinue(false)
                setPassengerPricesData(passengerPrices)
            } else {
                setDisableContinue(true)
                notification.error({ message: 'Hata!', description: resp?.data?.message + ". Lütfen daha sonra tekrar deneyin." })
            }
        }).catch((error) => notification.error({ message: 'Hata!', description: error?.message }))
    }

    useEffect(() => {
        setDisableContinue(true)
    }, [GidisTarihi, DonusTarihi, YetiskinSayisi, CocukSayisi, BebekSayisi])

    useEffect(() => {
        if (selectedArrivalTour && DonusTarihi) {
            const selectedArrivalTourPrice = arrivalTours.find(tour => tour.id == selectedArrivalTour)?.price || 35

            setArrivalPrice(parseFloat(selectedArrivalTourPrice))
            setTotalPrice(0)
            calculateTourPrice({ routeType: 2 })
        }
    }, [selectedArrivalTour])

    useEffect(() => {
        if (selectedDepartureTour) {
            const selectedDepartureTourPrice = departureTours.find(tour => tour.id == selectedDepartureTour)?.price || 35

            setDeparturePrice(parseFloat(selectedDepartureTourPrice))
            setTotalPrice(0)
            if (donusTarihiAktif && DonusTarihi && DonusTarihi != '' && !_.isEmpty(selectedArrivalTour)) {
                calculateTourPrice({ routeType: 2 })
            } else if (!donusTarihiAktif || !DonusTarihi || DonusTarihi == '') {
                calculateTourPrice({ routeType: 1 })
            } else {
                calculateTourPrice({ routeType: 1 })
            }
        }
    }, [selectedDepartureTour])

    let clickedContinue = false;

    const continueWithSelectedTours = () => {
        clickedContinue = true
        const arrival_tour_id = donusTarihiAktif && Number(selectedArrivalTour)
        const arrival_date = donusTarihiAktif && dayjs(DonusTarihi).format('YYYY-MM-DD').toString()

        const tempReservationData = {
            departure_tour_id: Number(selectedDepartureTour) || 1,
            arrival_tour_id: arrival_tour_id || 1,
            child_count: CocukSayisi,
            adult_count: YetiskinSayisi,
            departure_date: dayjs(GidisTarihi).format('YYYY-MM-DD').toString(),
            arrival_date: arrival_date || dayjs().format('YYYY-MM-DD').toString(),
            guests: [],
            passengers: {
                adults: [],
                childs: [],
                infants: [],
            },
            departur_tour_price: departurePrice || 500,
            departureExpeditionID: Number(selectedDepartureTour),
            arrivalExpeditionID: Number(selectedArrivalTour),
            childCount: CocukSayisi,
            adultCount: YetiskinSayisi,
            passengerPrices: passengerPricesData,
            ticketSearch: {
                routeType: donusTarihiAktif ? 2 : 1,
                serviceDate: dayjs(GidisTarihi).format('YYYY-MM-DD').toString(),
                returnServiceDate: dayjs(DonusTarihi).format('YYYY-MM-DD').toString(),
                departureRegionID: selectedDepartureTour.routeID,
                arrivalRegionID: selectedArrivalTour?.routeID,
                adultQuantity: YetiskinSayisi,
                childQuantity: CocukSayisi,
                infantQuantity: BebekSayisi,
                vehicleQuantity: 0,
                childAge: null,
                infantAge: null,
                vehicleType: null,
                havePets: false,
                departureExpeditionID: selectedDepartureTour?.expeditionID,
                returnExpeditionID: selectedArrivalTour?.expeditionID,
                langCode: "TR"
            }
        };
        if (selectedArrivalTour) {
            tempReservationData['arrival_tour_price'] = arrivalPrice;
        }

        // Çocuklar için bilgi ekleme
        for (let i = 0; i < CocukSayisi; i++) {
            tempReservationData.passengers.childs.push(initialGuestData);
        }

        // Yetişkinler için bilgi ekleme
        for (let i = 0; i < YetiskinSayisi; i++) {
            tempReservationData.passengers.adults.push(initialGuestData);
        }

        // Bebekler için bilgi ekleme
        for (let i = 0; i < BebekSayisi; i++) {
            tempReservationData.passengers.infants.push(initialGuestData);
        }

        setReservationData(tempReservationData)

        // console.log("tempReservationData", tempReservationData);

        if (!selectedDepartureTour) {
            notification.error({
                message: 'Hata',
                description: 'Gidiş seferini seçmelisiniz!',
                placement: 'topRight'
            })
            return;
        }
        if (DonusTarihi && !selectedArrivalTour) {
            notification.error({
                message: 'Hata',
                description: 'Dönüş seferini seçmelisiniz!',
                placement: 'topRight'
            })
            return;
        }

        setContinueToCreateReservation(true)
    }

    if (continueToCreateReservation) {
        return <ReservationSteps
            reservationData={reservationData}
            selectedDepartureTour={selectedDepartureTour}
            selectedArrivalTour={selectedArrivalTour}
        />
    }

    const departureToursExists = !_.isEmpty(departureTours)
    const showContinueBar1 = departureToursExists && !_.isEmpty(selectedDepartureTour)
    const disableReturnSelection = !_.isEmpty(departureTours) && _.isEmpty(selectedDepartureTour)

    return (
        <div className='searchToursContainer'>
            <CommonHeader title="Ucuz Feribot Bileti Ara" where="search">
                <MainContainer>
                    <SearchToursWidget
                        isHome={false}
                        searchParams={searchParams}
                        departureTours={departureTours}
                        setDepartureTours={setDepartureTours}
                        arrivalTours={arrivalTours}
                        setArrivalTours={setArrivalTours}
                        YetiskinSayisi={YetiskinSayisi}
                        setYetiskinSayisi={setYetiskinSayisi}
                        CocukSayisi={CocukSayisi}
                        ChildAges={ChildAges}
                        setChildAges={setChildAges}
                        setCocukSayisi={setCocukSayisi}
                        BebekSayisi={BebekSayisi}
                        setBebekSayisi={setBebekSayisi}
                        GidisTarihi={GidisTarihi}
                        setGidisTarihi={setGidisTarihi}
                        DonusTarihi={DonusTarihi}
                        setDonusTarihi={setDonusTarihi}
                        selectedDepartureTour={selectedDepartureTour}
                        setSelectedDepartureTour={setSelectedDepartureTour}
                        selectedArrivalTour={selectedArrivalTour}
                        setSelectedArrivalTour={setSelectedArrivalTour}
                        loadingTours={loadingTours}
                        setLoadingTours={setLoadingTours}
                    />
                </MainContainer>
            </CommonHeader>

            <MainContainer>
                {
                    loadingTours ? (
                        <div style={{ padding: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Spin />
                        </div>
                    ) : (
                        <>
                            <div style={{ marginBottom: 50 }}>
                                <Row gutter={[48, 16]}>
                                    <Col xs={24} sm={arrivalTours.length > 0 ? 12 : 24} className='tour-items'>
                                        {
                                            departureTours.length > 0 && (
                                                <div style={{ paddingTop: 15, paddingBottom: 15, fontSize: 16, fontWeight: '500' }}>
                                                    Gidiş Seferleri ({departureTours.length})
                                                </div>
                                            )
                                        }
                                        <Radio.Group name='departureTour' className='w-100' value={selectedDepartureTour}>
                                            <Space direction='vertical' className='w-100' size='middle'>
                                                {
                                                    departureTours && departureTours.map(tour => (
                                                        <TourCard
                                                            data={tour}
                                                            selectTour={setSelectedDepartureTour}
                                                            isDeparture={true}
                                                            selectedTour={selectedDepartureTour}
                                                            returnTourComponentRef={returnTourComponentRef}
                                                        />
                                                    ))
                                                }
                                            </Space>
                                        </Radio.Group>
                                    </Col>
                                    {
                                        DonusTarihi && (
                                            <Col xs={24} sm={12} className='tour-items' ref={returnTourComponentRef}>
                                                {
                                                    arrivalTours.length > 0 && (
                                                        <div style={{ paddingTop: 15, paddingBottom: 15, fontSize: 16, fontWeight: '500' }}>
                                                            Dönüş Seferleri ({arrivalTours.length})
                                                        </div>
                                                    )
                                                }
                                                <Radio.Group disabled={disableReturnSelection} name='arrivalTour' className='w-100' value={selectedArrivalTour} onChange={(e) => setSelectedArrivalTour(e.target.value)}>
                                                    <Space direction='vertical' className='w-100' size='middle'>
                                                        {
                                                            arrivalTours && arrivalTours.map(tour => (
                                                                <TourCard
                                                                    data={tour}
                                                                    selectTour={setSelectedArrivalTour}
                                                                    isDeparture={false}
                                                                    selectedTour={selectedArrivalTour}
                                                                    disabled={disableReturnSelection}
                                                                    returnTourComponentRef={returnTourComponentRef}
                                                                />
                                                            ))
                                                        }
                                                    </Space>
                                                </Radio.Group>
                                            </Col>
                                        )
                                    }
                                </Row>

                                {
                                    (departureTours.length <= 0 && arrivalTours.length <= 0 && !clickedContinue) && (
                                        <Row justify='center' align='middle' style={{ marginBottom: 12 }}>
                                            <Col>
                                                <p style={{ fontSize: 18, color: 'red' }}>Seçtiğiniz kriterlere uygun sefer bulunamamıştır.</p>
                                            </Col>
                                        </Row>
                                    )
                                }
                            </div >
                        </>
                    )
                }
            </MainContainer>

            {
                (disableContinue == false && showContinueBar1 && !loadingTours) && (
                    <div className='continue-container'>
                        <Row className='w-100' style={{
                            justifyContent: 'space-between', alignContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Col sm={24} md={12}>
                                <div style={{ fontSize: 20, fontWeight: '600' }}>
                                    Toplam Fiyat: €{totalPrice} <span style={{ fontSize: 17, color: '#666' }}>(₺{totalPriceTL})</span>
                                </div>
                            </Col>
                            <Col sm={24} md={12} justify={'end'} style={{ justifyContent: 'flex-end', textAlign: 'right', width: '100%' }}>
                                <Button
                                    disabled={disableContinue}
                                    className='continue-btn'
                                    type='primary' size='large' style={{ paddingInline: 50, fontWeight: '600' }}
                                    onClick={() => {
                                        continueWithSelectedTours()
                                    }}
                                >
                                    Devam Et
                                </Button>
                            </Col>
                        </Row>
                    </div>
                )
            }

        </div >
    );
};
