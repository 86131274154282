import axios from "axios";
import { SERVICE_URL, TILOS_SERVICE_URL } from "..";
import { getItem } from "../../utils/storage";
import { Ports as MockPorts } from "../../mocks";

export const Ports = () => {
  const userData = getItem("adminUser");
  const list = () => {
    return axios.get(`${SERVICE_URL}/ports`);
  };

  const arrivalPorts = (regionID) => {
    return axios.get(
      `${SERVICE_URL}/Common/GetArrivalRegion?regionId=${regionID}`
    );
  };

  const departurePorts = (params) => {
    return axios.get(`${SERVICE_URL}/Common/GetDepartureRegion`);
    // return MockPorts().departurePorts().result;
    //   MockPorts ||
    //   axios.post(
    //     `${TILOS_SERVICE_URL}/ticket/departureregion`,
    //     {
    //       langCode: "EN",
    //       ...params,
    //     },
    //     {
    //       headers: {
    //         accept: "application/json",
    //         "Content-Type": "application/json",
    //         Authorization: "Bearer dXlndW5mZXJpYm90LmFwaTo5MzMyMjI6Ng==",
    //       },
    //     }
    //   )
  };

  const create = (params) => {
    return axios.post(
      `${SERVICE_URL}/ports`,
      {
        ...params,
      },
      {
        headers: {
          token: userData.token,
        },
      }
    );
  };

  const update = (id, params) => {
    return axios.put(`${SERVICE_URL}/ports/${id}`, {
      params,
      headers: {
        token: userData.token,
      },
    });
  };

  const remove = (id) => {
    return axios.delete(`${SERVICE_URL}/ports`, {
      headers: {
        token: userData.token,
      },
    });
  };

  return {
    list,
    create,
    update,
    remove,
    departurePorts,
    arrivalPorts,
  };
};
