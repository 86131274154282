export const countries = [
  {
    id: 1,
    code: "TUR",
    name: "TURKIYE",
    postage: 5.0,
    phoneCode: "90",
    forthCrsCountryCode: "TR",
    postageCurrencyTypeID: 1,
  },
  {
    id: 2,
    code: "GRC",
    name: "GREECE",
    postage: 5.0,
    phoneCode: "30",
    forthCrsCountryCode: "GR",
    postageCurrencyTypeID: 2,
  },
  {
    id: 3,
    code: "KOR",
    name: "KOREA(SOUTH)",
    postage: 0,
    phoneCode: "410",
    forthCrsCountryCode: "KR",
    postageCurrencyTypeID: 0,
  },
  {
    id: 4,
    code: "D",
    name: "GERMANY",
    postage: 0,
    phoneCode: "49",
    forthCrsCountryCode: "DE",
    postageCurrencyTypeID: 0,
  },
  {
    id: 5,
    code: "USA",
    name: "USA",
    postage: 0,
    phoneCode: "1",
    forthCrsCountryCode: "US",
    postageCurrencyTypeID: 0,
  },
  {
    id: 6,
    code: "FRA",
    name: "FRANCE",
    postage: 0,
    phoneCode: "33",
    forthCrsCountryCode: "FR",
    postageCurrencyTypeID: 0,
  },
  {
    id: 7,
    code: "ITA",
    name: "ITALY",
    postage: 0,
    phoneCode: "39",
    forthCrsCountryCode: "IT",
    postageCurrencyTypeID: 0,
  },
  {
    id: 8,
    code: "POL",
    name: "POLAND",
    postage: 0,
    phoneCode: "48",
    forthCrsCountryCode: "PL",
    postageCurrencyTypeID: 0,
  },
  {
    id: 9,
    code: "BGR",
    name: "BULGARIA",
    postage: 0,
    phoneCode: "359",
    forthCrsCountryCode: "BG",
    postageCurrencyTypeID: 0,
  },
  {
    id: 10,
    code: "GBR",
    name: "UNITED KINGDOM",
    postage: 0,
    phoneCode: "44",
    forthCrsCountryCode: "GB",
    postageCurrencyTypeID: 0,
  },
  {
    id: 11,
    code: "AND",
    name: "ANDORRA",
    postage: 0,
    phoneCode: "376",
    forthCrsCountryCode: "AD",
    postageCurrencyTypeID: 0,
  },
  {
    id: 12,
    code: "ARE",
    name: "UNITED ARAB EMIRATES",
    postage: 0,
    phoneCode: "971",
    forthCrsCountryCode: "AE",
    postageCurrencyTypeID: 0,
  },
  {
    id: 13,
    code: "AFG",
    name: "AFGHANISTAN",
    postage: 0,
    phoneCode: "93",
    forthCrsCountryCode: "AF",
    postageCurrencyTypeID: 0,
  },
  {
    id: 14,
    code: "ATG",
    name: "ANTIGUA & BARBUDA",
    postage: 0,
    phoneCode: "1-268",
    forthCrsCountryCode: "AG",
    postageCurrencyTypeID: 0,
  },
  {
    id: 15,
    code: "AIA",
    name: "ANGUILLA",
    postage: 0,
    phoneCode: "1-264",
    forthCrsCountryCode: "AI",
    postageCurrencyTypeID: 0,
  },
  {
    id: 16,
    code: "ALB",
    name: "ALBANIA",
    postage: 0,
    phoneCode: "355",
    forthCrsCountryCode: "AL",
    postageCurrencyTypeID: 0,
  },
  {
    id: 17,
    code: "ARM",
    name: "ARMENIA",
    postage: 0,
    phoneCode: "374",
    forthCrsCountryCode: "AM",
    postageCurrencyTypeID: 0,
  },
  {
    id: 18,
    code: "AGO",
    name: "ANGOLA",
    postage: 0,
    phoneCode: "244",
    forthCrsCountryCode: "AO",
    postageCurrencyTypeID: 0,
  },
  {
    id: 19,
    code: "ATA",
    name: "ANTARCTICA",
    postage: 0,
    phoneCode: "672",
    forthCrsCountryCode: "AQ",
    postageCurrencyTypeID: 0,
  },
  {
    id: 20,
    code: "ARG",
    name: "ARGENTINA",
    postage: 0,
    phoneCode: "54",
    forthCrsCountryCode: "AR",
    postageCurrencyTypeID: 0,
  },
  {
    id: 21,
    code: "ASM",
    name: "AMERICAN SAMOA",
    postage: 0,
    phoneCode: "1-684",
    forthCrsCountryCode: "AS",
    postageCurrencyTypeID: 0,
  },
  {
    id: 22,
    code: "AUT",
    name: "AUSTRIA",
    postage: 0,
    phoneCode: "43",
    forthCrsCountryCode: "AT",
    postageCurrencyTypeID: 0,
  },
  {
    id: 23,
    code: "AUS",
    name: "AUSTRALIA",
    postage: 0,
    phoneCode: "61",
    forthCrsCountryCode: "AU",
    postageCurrencyTypeID: 0,
  },
  {
    id: 24,
    code: "ABW",
    name: "ARUBA",
    postage: 0,
    phoneCode: "297",
    forthCrsCountryCode: "AW",
    postageCurrencyTypeID: 0,
  },
  {
    id: 25,
    code: "ALA",
    name: "ALAND ISLANDS",
    postage: 0,
    phoneCode: null,
    forthCrsCountryCode: "AX",
    postageCurrencyTypeID: 0,
  },
  {
    id: 26,
    code: "AZE",
    name: "AZERBAIJAN",
    postage: 0,
    phoneCode: "994",
    forthCrsCountryCode: "AZ",
    postageCurrencyTypeID: 0,
  },
  {
    id: 27,
    code: "BIH",
    name: "BOSNIA & HERZEGOVINA",
    postage: 0,
    phoneCode: "387",
    forthCrsCountryCode: "BA",
    postageCurrencyTypeID: 0,
  },
  {
    id: 28,
    code: "BRB",
    name: "BARBADOS",
    postage: 0,
    phoneCode: "1-248",
    forthCrsCountryCode: "BB",
    postageCurrencyTypeID: 0,
  },
  {
    id: 29,
    code: "BGD",
    name: "BANGLADESH",
    postage: 0,
    phoneCode: "880",
    forthCrsCountryCode: "BD",
    postageCurrencyTypeID: 0,
  },
  {
    id: 30,
    code: "BEL",
    name: "BELGIUM",
    postage: 0,
    phoneCode: "32",
    forthCrsCountryCode: "BE",
    postageCurrencyTypeID: 0,
  },
  {
    id: 31,
    code: "BFA",
    name: "BURKINA FASO",
    postage: 0,
    phoneCode: "226",
    forthCrsCountryCode: "BF",
    postageCurrencyTypeID: 0,
  },
  {
    id: 32,
    code: "BHR",
    name: "BAHRAIN",
    postage: 0,
    phoneCode: "973",
    forthCrsCountryCode: "BH",
    postageCurrencyTypeID: 0,
  },
  {
    id: 33,
    code: "BDI",
    name: "BURUNDI",
    postage: 0,
    phoneCode: "257",
    forthCrsCountryCode: "BI",
    postageCurrencyTypeID: 0,
  },
  {
    id: 34,
    code: "BEN",
    name: "BENIN",
    postage: 0,
    phoneCode: "229",
    forthCrsCountryCode: "BJ",
    postageCurrencyTypeID: 0,
  },
  {
    id: 35,
    code: "BLM",
    name: "SAINT BARTHELEMY",
    postage: 0,
    phoneCode: "590",
    forthCrsCountryCode: "BL",
    postageCurrencyTypeID: 0,
  },
  {
    id: 36,
    code: "BMU",
    name: "BERMUDA",
    postage: 0,
    phoneCode: "1-441",
    forthCrsCountryCode: "BM",
    postageCurrencyTypeID: 0,
  },
  {
    id: 37,
    code: "BRN",
    name: "BRUNEI",
    postage: 0,
    phoneCode: "673",
    forthCrsCountryCode: "BN",
    postageCurrencyTypeID: 0,
  },
  {
    id: 38,
    code: "BOL",
    name: "BOLIVIA",
    postage: 0,
    phoneCode: "591",
    forthCrsCountryCode: "BO",
    postageCurrencyTypeID: 0,
  },
  {
    id: 39,
    code: "BES",
    name: "BONAIRE",
    postage: 0,
    phoneCode: null,
    forthCrsCountryCode: null,
    postageCurrencyTypeID: 0,
  },
  {
    id: 40,
    code: "BRA",
    name: "BRAZIL",
    postage: 0,
    phoneCode: "55",
    forthCrsCountryCode: "BR",
    postageCurrencyTypeID: 0,
  },
  {
    id: 41,
    code: "BHS",
    name: "BAHAMAS",
    postage: 0,
    phoneCode: "1-242",
    forthCrsCountryCode: "BS",
    postageCurrencyTypeID: 0,
  },
  {
    id: 42,
    code: "BTN",
    name: "BHUTAN",
    postage: 0,
    phoneCode: "975",
    forthCrsCountryCode: "BT",
    postageCurrencyTypeID: 0,
  },
  {
    id: 43,
    code: "BVT",
    name: "BOUVET ISLAND",
    postage: 0,
    phoneCode: null,
    forthCrsCountryCode: "BV",
    postageCurrencyTypeID: 0,
  },
  {
    id: 44,
    code: "BWA",
    name: "BOTSWANA",
    postage: 0,
    phoneCode: "267",
    forthCrsCountryCode: "BW",
    postageCurrencyTypeID: 0,
  },
  {
    id: 45,
    code: "BLR",
    name: "BELARUS",
    postage: 0,
    phoneCode: "375",
    forthCrsCountryCode: "BY",
    postageCurrencyTypeID: 0,
  },
  {
    id: 46,
    code: "BLZ",
    name: "BELIZE",
    postage: 0,
    phoneCode: "501",
    forthCrsCountryCode: "BZ",
    postageCurrencyTypeID: 0,
  },
  {
    id: 47,
    code: "CAN",
    name: "CANADA",
    postage: 0,
    phoneCode: "1",
    forthCrsCountryCode: "CA",
    postageCurrencyTypeID: 0,
  },
  {
    id: 48,
    code: "CCK",
    name: "COCOS ISLANDS",
    postage: 0,
    phoneCode: "61",
    forthCrsCountryCode: "CC",
    postageCurrencyTypeID: 0,
  },
  {
    id: 49,
    code: "COD",
    name: "CONGO REPUBLIC",
    postage: 0,
    phoneCode: "242",
    forthCrsCountryCode: "CD",
    postageCurrencyTypeID: 0,
  },
  {
    id: 50,
    code: "CAF",
    name: "C. AFRICAN REPUBLIC",
    postage: 0,
    phoneCode: "236",
    forthCrsCountryCode: "CF",
    postageCurrencyTypeID: 0,
  },
  {
    id: 51,
    code: "COG",
    name: "CONGO",
    postage: 0,
    phoneCode: "242",
    forthCrsCountryCode: "CG",
    postageCurrencyTypeID: 0,
  },
  {
    id: 52,
    code: "CHE",
    name: "SWITZERLAND",
    postage: 0,
    phoneCode: "41",
    forthCrsCountryCode: "CH",
    postageCurrencyTypeID: 0,
  },
  {
    id: 53,
    code: "CIV",
    name: "COTE D'IVOIRE",
    postage: 0,
    phoneCode: null,
    forthCrsCountryCode: "CI",
    postageCurrencyTypeID: 0,
  },
  {
    id: 54,
    code: "COK",
    name: "COOK ISLANDS",
    postage: 0,
    phoneCode: "682",
    forthCrsCountryCode: "CK",
    postageCurrencyTypeID: 0,
  },
  {
    id: 55,
    code: "CHL",
    name: "CHILE",
    postage: 0,
    phoneCode: "56",
    forthCrsCountryCode: "CL",
    postageCurrencyTypeID: 0,
  },
  {
    id: 56,
    code: "CMR",
    name: "CAMEROON",
    postage: 0,
    phoneCode: "237",
    forthCrsCountryCode: "CM",
    postageCurrencyTypeID: 0,
  },
  {
    id: 57,
    code: "CHN",
    name: "CHINA",
    postage: 0,
    phoneCode: "86",
    forthCrsCountryCode: "CN",
    postageCurrencyTypeID: 0,
  },
  {
    id: 58,
    code: "COL",
    name: "COLOMBIA",
    postage: 0,
    phoneCode: "57",
    forthCrsCountryCode: "CO",
    postageCurrencyTypeID: 0,
  },
  {
    id: 59,
    code: "CRI",
    name: "COSTA RICA",
    postage: 0,
    phoneCode: "506",
    forthCrsCountryCode: "CR",
    postageCurrencyTypeID: 0,
  },
  {
    id: 60,
    code: "CUB",
    name: "CUBA",
    postage: 0,
    phoneCode: "53",
    forthCrsCountryCode: "CU",
    postageCurrencyTypeID: 0,
  },
  {
    id: 61,
    code: "CPV",
    name: "CAPE VERDE",
    postage: 0,
    phoneCode: "238",
    forthCrsCountryCode: "CV",
    postageCurrencyTypeID: 0,
  },
  {
    id: 62,
    code: "CUW",
    name: "CURACAO",
    postage: 0,
    phoneCode: "599",
    forthCrsCountryCode: "null",
    postageCurrencyTypeID: 0,
  },
  {
    id: 63,
    code: "CXR",
    name: "CHRISTMAS ISLAND",
    postage: 0,
    phoneCode: "61",
    forthCrsCountryCode: "CX",
    postageCurrencyTypeID: 0,
  },
  {
    id: 64,
    code: "CYP",
    name: "CYPRUS",
    postage: 0,
    phoneCode: "357",
    forthCrsCountryCode: "CY",
    postageCurrencyTypeID: 0,
  },
  {
    id: 65,
    code: "CZE",
    name: "CZECH REPUBLIC",
    postage: 0,
    phoneCode: "420",
    forthCrsCountryCode: "CZ",
    postageCurrencyTypeID: 0,
  },
  {
    id: 66,
    code: "DJI",
    name: "DJIBOUTI",
    postage: 0,
    phoneCode: "253",
    forthCrsCountryCode: "DJ",
    postageCurrencyTypeID: 0,
  },
  {
    id: 67,
    code: "DNK",
    name: "DENMARK",
    postage: 0,
    phoneCode: "45",
    forthCrsCountryCode: "DK",
    postageCurrencyTypeID: 0,
  },
  {
    id: 68,
    code: "DMA",
    name: "DOMINICA",
    postage: 0,
    phoneCode: "1-767",
    forthCrsCountryCode: "DM",
    postageCurrencyTypeID: 0,
  },
  {
    id: 69,
    code: "DOM",
    name: "DOMINICAN REPUBLIC",
    postage: 0,
    phoneCode: "1-809",
    forthCrsCountryCode: "DO",
    postageCurrencyTypeID: 0,
  },
  {
    id: 70,
    code: "DZA",
    name: "ALGERIA",
    postage: 0,
    phoneCode: "213",
    forthCrsCountryCode: "DZ",
    postageCurrencyTypeID: 0,
  },
  {
    id: 71,
    code: "ECU",
    name: "ECUADOR",
    postage: 0,
    phoneCode: "593",
    forthCrsCountryCode: "EC",
    postageCurrencyTypeID: 0,
  },
  {
    id: 72,
    code: "EST",
    name: "ESTONIA",
    postage: 0,
    phoneCode: "372",
    forthCrsCountryCode: "EE",
    postageCurrencyTypeID: 0,
  },
  {
    id: 73,
    code: "EGY",
    name: "EGYPT",
    postage: 0,
    phoneCode: "20",
    forthCrsCountryCode: "EG",
    postageCurrencyTypeID: 0,
  },
  {
    id: 74,
    code: "ESH",
    name: "WESTERN SAHARA",
    postage: 0,
    phoneCode: "212",
    forthCrsCountryCode: "EH",
    postageCurrencyTypeID: 0,
  },
  {
    id: 75,
    code: "ERI",
    name: "ERITREA",
    postage: 0,
    phoneCode: "291",
    forthCrsCountryCode: "ER",
    postageCurrencyTypeID: 0,
  },
  {
    id: 76,
    code: "ESP",
    name: "SPAIN",
    postage: 0,
    phoneCode: "34",
    forthCrsCountryCode: "ES",
    postageCurrencyTypeID: 0,
  },
  {
    id: 77,
    code: "ETH",
    name: "ETHIOPIA",
    postage: 0,
    phoneCode: "251",
    forthCrsCountryCode: "ET",
    postageCurrencyTypeID: 0,
  },
  {
    id: 78,
    code: "FIN",
    name: "FINLAND",
    postage: 0,
    phoneCode: "358",
    forthCrsCountryCode: "FI",
    postageCurrencyTypeID: 0,
  },
  {
    id: 79,
    code: "FJI",
    name: "FIJI",
    postage: 0,
    phoneCode: "679",
    forthCrsCountryCode: "FJ",
    postageCurrencyTypeID: 0,
  },
  {
    id: 80,
    code: "FLK",
    name: "FALKLAND ISLANDS",
    postage: 0,
    phoneCode: "500",
    forthCrsCountryCode: "FK",
    postageCurrencyTypeID: 0,
  },
  {
    id: 81,
    code: "FSM",
    name: "MICRONESIA",
    postage: 0,
    phoneCode: "690",
    forthCrsCountryCode: "FM",
    postageCurrencyTypeID: 0,
  },
  {
    id: 82,
    code: "FRO",
    name: "FAROE ISLANDS",
    postage: 0,
    phoneCode: "298",
    forthCrsCountryCode: "FO",
    postageCurrencyTypeID: 0,
  },
  {
    id: 83,
    code: "GAB",
    name: "GABON",
    postage: 0,
    phoneCode: "241",
    forthCrsCountryCode: "GA",
    postageCurrencyTypeID: 0,
  },
  {
    id: 84,
    code: "GRD",
    name: "GRENADA",
    postage: 0,
    phoneCode: "1-473",
    forthCrsCountryCode: "GD",
    postageCurrencyTypeID: 0,
  },
  {
    id: 85,
    code: "GEO",
    name: "GEORGIA",
    postage: 0,
    phoneCode: "995",
    forthCrsCountryCode: "GE",
    postageCurrencyTypeID: 0,
  },
  {
    id: 86,
    code: "GUF",
    name: "FRENCH GUIANA",
    postage: 0,
    phoneCode: null,
    forthCrsCountryCode: "GF",
    postageCurrencyTypeID: 0,
  },
  {
    id: 87,
    code: "GGY",
    name: "GUERNSEY",
    postage: 0,
    phoneCode: "44-1481",
    forthCrsCountryCode: "GG",
    postageCurrencyTypeID: 0,
  },
  {
    id: 88,
    code: "GHA",
    name: "GHANA",
    postage: 0,
    phoneCode: "233",
    forthCrsCountryCode: "GH",
    postageCurrencyTypeID: 0,
  },
  {
    id: 89,
    code: "GIB",
    name: "GIBRALTAR",
    postage: 0,
    phoneCode: "350",
    forthCrsCountryCode: "GI",
    postageCurrencyTypeID: 0,
  },
  {
    id: 90,
    code: "GRL",
    name: "GREENLAND",
    postage: 0,
    phoneCode: "299",
    forthCrsCountryCode: "GL",
    postageCurrencyTypeID: 0,
  },
  {
    id: 91,
    code: "GMB",
    name: "GAMBIA",
    postage: 0,
    phoneCode: "220",
    forthCrsCountryCode: "GM",
    postageCurrencyTypeID: 0,
  },
  {
    id: 92,
    code: "GIN",
    name: "GUINEA",
    postage: 0,
    phoneCode: "224",
    forthCrsCountryCode: "GN",
    postageCurrencyTypeID: 0,
  },
  {
    id: 93,
    code: "GLP",
    name: "GUADALOUPE",
    postage: 0,
    phoneCode: null,
    forthCrsCountryCode: "GP",
    postageCurrencyTypeID: 0,
  },
  {
    id: 94,
    code: "GNQ",
    name: "EQUATORIAL GUINEA",
    postage: 0,
    phoneCode: "240",
    forthCrsCountryCode: "GQ",
    postageCurrencyTypeID: 0,
  },
  {
    id: 95,
    code: "SGS",
    name: "SOUTH GEORGIA",
    postage: 0,
    phoneCode: null,
    forthCrsCountryCode: "GS",
    postageCurrencyTypeID: 0,
  },
  {
    id: 96,
    code: "GTM",
    name: "GUATEMALA",
    postage: 0,
    phoneCode: "502",
    forthCrsCountryCode: "GT",
    postageCurrencyTypeID: 0,
  },
  {
    id: 97,
    code: "GUM",
    name: "GUAM",
    postage: 0,
    phoneCode: "1-671",
    forthCrsCountryCode: "GU",
    postageCurrencyTypeID: 0,
  },
  {
    id: 98,
    code: "GNB",
    name: "GUINEA-BISSAU",
    postage: 0,
    phoneCode: "245",
    forthCrsCountryCode: "GW",
    postageCurrencyTypeID: 0,
  },
  {
    id: 99,
    code: "GUY",
    name: "GUYANA",
    postage: 0,
    phoneCode: "592",
    forthCrsCountryCode: "GY",
    postageCurrencyTypeID: 0,
  },
  {
    id: 100,
    code: "HKG",
    name: "HONG KONG",
    postage: 0,
    phoneCode: "852",
    forthCrsCountryCode: "HK",
    postageCurrencyTypeID: 0,
  },
  {
    id: 101,
    code: "HMD",
    name: "HEARD ISLAND",
    postage: 0,
    phoneCode: null,
    forthCrsCountryCode: "HM",
    postageCurrencyTypeID: 0,
  },
  {
    id: 102,
    code: "HND",
    name: "HONDURAS",
    postage: 0,
    phoneCode: "504",
    forthCrsCountryCode: "HN",
    postageCurrencyTypeID: 0,
  },
  {
    id: 103,
    code: "HRV",
    name: "CROATIA",
    postage: 0,
    phoneCode: "385",
    forthCrsCountryCode: "HR",
    postageCurrencyTypeID: 0,
  },
  {
    id: 104,
    code: "HTI",
    name: "HAITI",
    postage: 0,
    phoneCode: "509",
    forthCrsCountryCode: "HT",
    postageCurrencyTypeID: 0,
  },
  {
    id: 105,
    code: "HUN",
    name: "HUNGARY",
    postage: 0,
    phoneCode: "36",
    forthCrsCountryCode: "HU",
    postageCurrencyTypeID: 0,
  },
  {
    id: 106,
    code: "IDN",
    name: "INDONESIA",
    postage: 0,
    phoneCode: "62",
    forthCrsCountryCode: "ID",
    postageCurrencyTypeID: 0,
  },
  {
    id: 107,
    code: "IRL",
    name: "IRELAND",
    postage: 0,
    phoneCode: "353",
    forthCrsCountryCode: "IE",
    postageCurrencyTypeID: 0,
  },
  {
    id: 108,
    code: "ISR",
    name: "ISRAEL",
    postage: 0,
    phoneCode: "972",
    forthCrsCountryCode: "IL",
    postageCurrencyTypeID: 0,
  },
  {
    id: 109,
    code: "IMN",
    name: "ISLE OF MAN",
    postage: 0,
    phoneCode: "44-1624",
    forthCrsCountryCode: "IM",
    postageCurrencyTypeID: 0,
  },
  {
    id: 110,
    code: "IND",
    name: "INDIA",
    postage: 0,
    phoneCode: "91",
    forthCrsCountryCode: "IN",
    postageCurrencyTypeID: 0,
  },
  {
    id: 111,
    code: "IOT",
    name: "BRITISH INDIAN OT",
    postage: 0,
    phoneCode: "246",
    forthCrsCountryCode: "IO",
    postageCurrencyTypeID: 0,
  },
  {
    id: 112,
    code: "IRQ",
    name: "IRAQ",
    postage: 0,
    phoneCode: "964",
    forthCrsCountryCode: "IQ",
    postageCurrencyTypeID: 0,
  },
  {
    id: 113,
    code: "IRN",
    name: "IRAN",
    postage: 0,
    phoneCode: "98",
    forthCrsCountryCode: "IR",
    postageCurrencyTypeID: 0,
  },
  {
    id: 114,
    code: "ISL",
    name: "ICELAND",
    postage: 0,
    phoneCode: "354",
    forthCrsCountryCode: "IS",
    postageCurrencyTypeID: 0,
  },
  {
    id: 115,
    code: "JEY",
    name: "JERSEY",
    postage: 0,
    phoneCode: "44-1534",
    forthCrsCountryCode: "JE",
    postageCurrencyTypeID: 0,
  },
  {
    id: 116,
    code: "JAM",
    name: "JAMAICA",
    postage: 0,
    phoneCode: "1-876",
    forthCrsCountryCode: "JM",
    postageCurrencyTypeID: 0,
  },
  {
    id: 117,
    code: "JOR",
    name: "JORDAN",
    postage: 0,
    phoneCode: "962",
    forthCrsCountryCode: "JO",
    postageCurrencyTypeID: 0,
  },
  {
    id: 118,
    code: "JPN",
    name: "JAPAN",
    postage: 0,
    phoneCode: "81",
    forthCrsCountryCode: "JP",
    postageCurrencyTypeID: 0,
  },
  {
    id: 119,
    code: "KEN",
    name: "KENYA",
    postage: 0,
    phoneCode: "254",
    forthCrsCountryCode: "KE",
    postageCurrencyTypeID: 0,
  },
  {
    id: 120,
    code: "KGZ",
    name: "KYRGYZSTAN",
    postage: 0,
    phoneCode: "996",
    forthCrsCountryCode: "KG",
    postageCurrencyTypeID: 0,
  },
  {
    id: 121,
    code: "KHM",
    name: "CAMBODIA",
    postage: 0,
    phoneCode: "857",
    forthCrsCountryCode: "KH",
    postageCurrencyTypeID: 0,
  },
  {
    id: 122,
    code: "KIR",
    name: "KIRIBATI",
    postage: 0,
    phoneCode: "686",
    forthCrsCountryCode: "KI",
    postageCurrencyTypeID: 0,
  },
  {
    id: 123,
    code: "COM",
    name: "COMOROS",
    postage: 0,
    phoneCode: "269",
    forthCrsCountryCode: "KM",
    postageCurrencyTypeID: 0,
  },
  {
    id: 124,
    code: "KNA",
    name: "SAINT KITTS & NEVIS",
    postage: 0,
    phoneCode: "1-869",
    forthCrsCountryCode: "KN",
    postageCurrencyTypeID: 0,
  },
  {
    id: 125,
    code: "KWT",
    name: "KUWAIT",
    postage: 0,
    phoneCode: "965",
    forthCrsCountryCode: "KW",
    postageCurrencyTypeID: 0,
  },
  {
    id: 126,
    code: "CYM",
    name: "CAYMAN ISLANDS",
    postage: 0,
    phoneCode: "1-345",
    forthCrsCountryCode: "KY",
    postageCurrencyTypeID: 0,
  },
  {
    id: 127,
    code: "KAZ",
    name: "KAZAKHSTAN",
    postage: 0,
    phoneCode: "7",
    forthCrsCountryCode: "KZ",
    postageCurrencyTypeID: 0,
  },
  {
    id: 128,
    code: "LAO",
    name: "LAOS",
    postage: 0,
    phoneCode: "856",
    forthCrsCountryCode: "LA",
    postageCurrencyTypeID: 0,
  },
  {
    id: 129,
    code: "LBN",
    name: "LEBANON",
    postage: 0,
    phoneCode: "961",
    forthCrsCountryCode: "LB",
    postageCurrencyTypeID: 0,
  },
  {
    id: 130,
    code: "LCA",
    name: "SAINT LUCIA",
    postage: 0,
    phoneCode: "1-758",
    forthCrsCountryCode: "LC",
    postageCurrencyTypeID: 0,
  },
  {
    id: 131,
    code: "LIE",
    name: "LIECHTENSTEIN",
    postage: 0,
    phoneCode: "423",
    forthCrsCountryCode: "LI",
    postageCurrencyTypeID: 0,
  },
  {
    id: 132,
    code: "LKA",
    name: "SRI LANKA",
    postage: 0,
    phoneCode: "94",
    forthCrsCountryCode: "LK",
    postageCurrencyTypeID: 0,
  },
  {
    id: 133,
    code: "LBR",
    name: "LIBERIA",
    postage: 0,
    phoneCode: "231",
    forthCrsCountryCode: "LR",
    postageCurrencyTypeID: 0,
  },
  {
    id: 134,
    code: "LSO",
    name: "LESOTHO",
    postage: 0,
    phoneCode: "266",
    forthCrsCountryCode: "LS",
    postageCurrencyTypeID: 0,
  },
  {
    id: 135,
    code: "LTU",
    name: "LITHUANIA",
    postage: 0,
    phoneCode: "370",
    forthCrsCountryCode: "LT",
    postageCurrencyTypeID: 0,
  },
  {
    id: 136,
    code: "LUX",
    name: "LUXEMBOURG",
    postage: 0,
    phoneCode: "352",
    forthCrsCountryCode: "LU",
    postageCurrencyTypeID: 0,
  },
  {
    id: 137,
    code: "LVA",
    name: "LATVIA",
    postage: 0,
    phoneCode: "371",
    forthCrsCountryCode: "LV",
    postageCurrencyTypeID: 0,
  },
  {
    id: 138,
    code: "LBY",
    name: "LIBYA",
    postage: 0,
    phoneCode: "218",
    forthCrsCountryCode: "LY",
    postageCurrencyTypeID: 0,
  },
  {
    id: 139,
    code: "MAR",
    name: "MOROCCO",
    postage: 0,
    phoneCode: "212",
    forthCrsCountryCode: "MA",
    postageCurrencyTypeID: 0,
  },
  {
    id: 140,
    code: "MCO",
    name: "MONACO",
    postage: 0,
    phoneCode: "377",
    forthCrsCountryCode: "MC",
    postageCurrencyTypeID: 0,
  },
  {
    id: 141,
    code: "MDA",
    name: "MOLDAVA",
    postage: 0,
    phoneCode: "373",
    forthCrsCountryCode: "MD",
    postageCurrencyTypeID: 0,
  },
  {
    id: 142,
    code: "MNE",
    name: "MONTENEGRO",
    postage: 0,
    phoneCode: "382",
    forthCrsCountryCode: "ME",
    postageCurrencyTypeID: 0,
  },
  {
    id: 143,
    code: "MAF",
    name: "SAINT MARTIN",
    postage: 0,
    phoneCode: "590",
    forthCrsCountryCode: "MF",
    postageCurrencyTypeID: 0,
  },
  {
    id: 144,
    code: "MDG",
    name: "MADAGASCAR",
    postage: 0,
    phoneCode: "261",
    forthCrsCountryCode: "MG",
    postageCurrencyTypeID: 0,
  },
  {
    id: 145,
    code: "MHL",
    name: "MARSHALL ISLANDS",
    postage: 0,
    phoneCode: "692",
    forthCrsCountryCode: "MH",
    postageCurrencyTypeID: 0,
  },
  {
    id: 146,
    code: "NMK",
    name: "FYROM",
    postage: 0,
    phoneCode: "389",
    forthCrsCountryCode: "MK",
    postageCurrencyTypeID: 0,
  },
  {
    id: 147,
    code: "MLI",
    name: "MALI",
    postage: 0,
    phoneCode: "223",
    forthCrsCountryCode: "ML",
    postageCurrencyTypeID: 0,
  },
  {
    id: 148,
    code: "MMR",
    name: "MYANMAR (BURMA)",
    postage: 0,
    phoneCode: "95",
    forthCrsCountryCode: "MM",
    postageCurrencyTypeID: 0,
  },
  {
    id: 149,
    code: "MNG",
    name: "MONGOLIA",
    postage: 0,
    phoneCode: "976",
    forthCrsCountryCode: "MN",
    postageCurrencyTypeID: 0,
  },
  {
    id: 150,
    code: "MAC",
    name: "MACAO",
    postage: 0,
    phoneCode: "853",
    forthCrsCountryCode: "MO",
    postageCurrencyTypeID: 0,
  },
  {
    id: 151,
    code: "MNP",
    name: "NORTHERN M. ISLANDS",
    postage: 0,
    phoneCode: "1-670",
    forthCrsCountryCode: "MP",
    postageCurrencyTypeID: 0,
  },
  {
    id: 152,
    code: "MTQ",
    name: "MARTINIQUE",
    postage: 0,
    phoneCode: "",
    forthCrsCountryCode: "MQ",
    postageCurrencyTypeID: 0,
  },
  {
    id: 153,
    code: "MRT",
    name: "MAURITANIA",
    postage: 0,
    phoneCode: "222",
    forthCrsCountryCode: "MV",
    postageCurrencyTypeID: 0,
  },
  {
    id: 154,
    code: "MSR",
    name: "MONTSERRAT",
    postage: 0,
    phoneCode: "1-664",
    forthCrsCountryCode: "MS",
    postageCurrencyTypeID: 0,
  },
  {
    id: 155,
    code: "MLT",
    name: "MALTA",
    postage: 0,
    phoneCode: "356",
    forthCrsCountryCode: "MT",
    postageCurrencyTypeID: 0,
  },
  {
    id: 156,
    code: "MUS",
    name: "MAURITIUS",
    postage: 0,
    phoneCode: "230",
    forthCrsCountryCode: "MU",
    postageCurrencyTypeID: 0,
  },
  {
    id: 157,
    code: "MDV",
    name: "MALDIVES",
    postage: 0,
    phoneCode: "960",
    forthCrsCountryCode: "MV",
    postageCurrencyTypeID: 0,
  },
  {
    id: 158,
    code: "MWI",
    name: "MALAWI",
    postage: 0,
    phoneCode: "265",
    forthCrsCountryCode: "MW",
    postageCurrencyTypeID: 0,
  },
  {
    id: 159,
    code: "MEX",
    name: "MEXICO",
    postage: 0,
    phoneCode: "52",
    forthCrsCountryCode: "MX",
    postageCurrencyTypeID: 0,
  },
  {
    id: 160,
    code: "MYS",
    name: "MALAYSIA",
    postage: 0,
    phoneCode: "60",
    forthCrsCountryCode: "MY",
    postageCurrencyTypeID: 0,
  },
  {
    id: 161,
    code: "MOZ",
    name: "MOZAMBIQUE",
    postage: 0,
    phoneCode: "258",
    forthCrsCountryCode: "MZ",
    postageCurrencyTypeID: 0,
  },
  {
    id: 162,
    code: "NAM",
    name: "NAMIBIA",
    postage: 0,
    phoneCode: "264",
    forthCrsCountryCode: "NA",
    postageCurrencyTypeID: 0,
  },
  {
    id: 163,
    code: "NCL",
    name: "NEW CALEDONIA",
    postage: 0,
    phoneCode: "687",
    forthCrsCountryCode: "NC",
    postageCurrencyTypeID: 0,
  },
  {
    id: 164,
    code: "NER",
    name: "NIGER",
    postage: 0,
    phoneCode: "227",
    forthCrsCountryCode: "NE",
    postageCurrencyTypeID: 0,
  },
  {
    id: 165,
    code: "NFK",
    name: "NORFOLK ISLAND",
    postage: 0,
    phoneCode: null,
    forthCrsCountryCode: "NF",
    postageCurrencyTypeID: 0,
  },
  {
    id: 166,
    code: "NGA",
    name: "NIGERIA",
    postage: 0,
    phoneCode: "234",
    forthCrsCountryCode: "NG",
    postageCurrencyTypeID: 0,
  },
  {
    id: 167,
    code: "NIC",
    name: "NICARAGUA",
    postage: 0,
    phoneCode: "505",
    forthCrsCountryCode: "NI",
    postageCurrencyTypeID: 0,
  },
  {
    id: 168,
    code: "NLD",
    name: "NETHERLANDS",
    postage: 0,
    phoneCode: "31",
    forthCrsCountryCode: "NL",
    postageCurrencyTypeID: 0,
  },
  {
    id: 169,
    code: "NOR",
    name: "NORWAY",
    postage: 0,
    phoneCode: "47",
    forthCrsCountryCode: "NO",
    postageCurrencyTypeID: 0,
  },
  {
    id: 170,
    code: "NPL",
    name: "NEPAL",
    postage: 0,
    phoneCode: "977",
    forthCrsCountryCode: "NP",
    postageCurrencyTypeID: 0,
  },
  {
    id: 171,
    code: "NRU",
    name: "NAURU",
    postage: 0,
    phoneCode: "674",
    forthCrsCountryCode: "NR",
    postageCurrencyTypeID: 0,
  },
  {
    id: 172,
    code: "NIU",
    name: "NIUE",
    postage: 0,
    phoneCode: "683",
    forthCrsCountryCode: "NU",
    postageCurrencyTypeID: 0,
  },
  {
    id: 173,
    code: "NZL",
    name: "NEW ZEALAND",
    postage: 0,
    phoneCode: "64",
    forthCrsCountryCode: "NZ",
    postageCurrencyTypeID: 0,
  },
  {
    id: 174,
    code: "OMN",
    name: "OMAN",
    postage: 0,
    phoneCode: "968",
    forthCrsCountryCode: "OM",
    postageCurrencyTypeID: 0,
  },
  {
    id: 175,
    code: "PAN",
    name: "PANAMA",
    postage: 0,
    phoneCode: "507",
    forthCrsCountryCode: "PA",
    postageCurrencyTypeID: 0,
  },
  {
    id: 176,
    code: "PER",
    name: "PERU",
    postage: 0,
    phoneCode: "51",
    forthCrsCountryCode: "PE",
    postageCurrencyTypeID: 0,
  },
  {
    id: 177,
    code: "PYF",
    name: "FRENCH POLYNESIA",
    postage: 0,
    phoneCode: "689",
    forthCrsCountryCode: "PF",
    postageCurrencyTypeID: 0,
  },
  {
    id: 178,
    code: "PNG",
    name: "PAPUA NEW GUINEA",
    postage: 0,
    phoneCode: "675",
    forthCrsCountryCode: "PG",
    postageCurrencyTypeID: 0,
  },
  {
    id: 179,
    code: "PHL",
    name: "PHILLIPINES",
    postage: 0,
    phoneCode: "63",
    forthCrsCountryCode: "PH",
    postageCurrencyTypeID: 0,
  },
  {
    id: 180,
    code: "PAK",
    name: "PAKISTAN",
    postage: 0,
    phoneCode: "92",
    forthCrsCountryCode: "PK",
    postageCurrencyTypeID: 0,
  },
  {
    id: 181,
    code: "SPM",
    name: "SAINT PIERRE",
    postage: 0,
    phoneCode: "508",
    forthCrsCountryCode: "PM",
    postageCurrencyTypeID: 0,
  },
  {
    id: 182,
    code: "PCN",
    name: "PITCAIRN",
    postage: 0,
    phoneCode: "64",
    forthCrsCountryCode: "PN",
    postageCurrencyTypeID: 0,
  },
  {
    id: 183,
    code: "PRI",
    name: "PUERTO RICO",
    postage: 0,
    phoneCode: "1-787",
    forthCrsCountryCode: "PR",
    postageCurrencyTypeID: 0,
  },
  {
    id: 184,
    code: "PSE",
    name: "PALESTINE",
    postage: 0,
    phoneCode: "970",
    forthCrsCountryCode: "PS",
    postageCurrencyTypeID: 0,
  },
  {
    id: 185,
    code: "PRT",
    name: "PORTUGAL",
    postage: 0,
    phoneCode: "351",
    forthCrsCountryCode: "PT",
    postageCurrencyTypeID: 0,
  },
  {
    id: 186,
    code: "PLW",
    name: "PALAU",
    postage: 0,
    phoneCode: "680",
    forthCrsCountryCode: "PW",
    postageCurrencyTypeID: 0,
  },
  {
    id: 187,
    code: "PRY",
    name: "PARAGUAY",
    postage: 0,
    phoneCode: "595",
    forthCrsCountryCode: "PY",
    postageCurrencyTypeID: 0,
  },
  {
    id: 188,
    code: "QAT",
    name: "QATAR",
    postage: 0,
    phoneCode: "974",
    forthCrsCountryCode: "QA",
    postageCurrencyTypeID: 0,
  },
  {
    id: 189,
    code: "REU",
    name: "REUNION",
    postage: 0,
    phoneCode: "262",
    forthCrsCountryCode: "RE",
    postageCurrencyTypeID: 0,
  },
  {
    id: 190,
    code: "ROU",
    name: "ROMANIA",
    postage: 0,
    phoneCode: "40",
    forthCrsCountryCode: "RO",
    postageCurrencyTypeID: 0,
  },
  {
    id: 191,
    code: "SRB",
    name: "SERBIA",
    postage: 0,
    phoneCode: "381",
    forthCrsCountryCode: "RS",
    postageCurrencyTypeID: 0,
  },
  {
    id: 192,
    code: "RUS",
    name: "RUSSIA",
    postage: 0,
    phoneCode: "7",
    forthCrsCountryCode: "RU",
    postageCurrencyTypeID: 0,
  },
  {
    id: 193,
    code: "RWA",
    name: "RWANDA",
    postage: 0,
    phoneCode: "250",
    forthCrsCountryCode: "RW",
    postageCurrencyTypeID: 0,
  },
  {
    id: 194,
    code: "SAU",
    name: "SAUDI ARABIA",
    postage: 0,
    phoneCode: "966",
    forthCrsCountryCode: "SA",
    postageCurrencyTypeID: 0,
  },
  {
    id: 195,
    code: "SLB",
    name: "SOLOMON ISLANDS",
    postage: 0,
    phoneCode: "677",
    forthCrsCountryCode: "SB",
    postageCurrencyTypeID: 0,
  },
  {
    id: 196,
    code: "SYC",
    name: "SEYCHELLES",
    postage: 0,
    phoneCode: "248",
    forthCrsCountryCode: "SC",
    postageCurrencyTypeID: 0,
  },
  {
    id: 197,
    code: "SDN",
    name: "SUDAN",
    postage: 0,
    phoneCode: "249",
    forthCrsCountryCode: "SD",
    postageCurrencyTypeID: 0,
  },
  {
    id: 198,
    code: "SWE",
    name: "SWEDEN",
    postage: 0,
    phoneCode: "46",
    forthCrsCountryCode: "SE",
    postageCurrencyTypeID: 0,
  },
  {
    id: 199,
    code: "SGP",
    name: "SINGAPORE",
    postage: 0,
    phoneCode: "65",
    forthCrsCountryCode: "SG",
    postageCurrencyTypeID: 0,
  },
  {
    id: 200,
    code: "SHN",
    name: "SAINT HELENA",
    postage: 0,
    phoneCode: "290",
    forthCrsCountryCode: "SH",
    postageCurrencyTypeID: 0,
  },
  {
    id: 201,
    code: "SYR",
    name: "SYRIAN ARAB REPUBLIC",
    postage: 0,
    phoneCode: "963",
    forthCrsCountryCode: "SY",
    postageCurrencyTypeID: 0,
  },
  {
    id: 202,
    code: "URY",
    name: "URUGUAY",
    postage: 0,
    phoneCode: "598",
    forthCrsCountryCode: "UY",
    postageCurrencyTypeID: 0,
  },
  {
    id: 203,
    code: "VNM",
    name: "VIETNAM",
    postage: 0,
    phoneCode: "84",
    forthCrsCountryCode: "VN",
    postageCurrencyTypeID: 0,
  },
  {
    id: 204,
    code: "YEM",
    name: "YEMEN",
    postage: 0,
    phoneCode: "967",
    forthCrsCountryCode: "YE",
    postageCurrencyTypeID: 0,
  },
  {
    id: 205,
    code: "ZMB",
    name: "ZAMBIA",
    postage: 0,
    phoneCode: "260",
    forthCrsCountryCode: "ZM",
    postageCurrencyTypeID: 0,
  },
  {
    id: 206,
    code: "ZWE",
    name: "ZIMBABWE",
    postage: 0,
    phoneCode: "263",
    forthCrsCountryCode: "ZW",
    postageCurrencyTypeID: 0,
  },
  {
    id: 207,
    code: "UZB",
    name: "UZBEKISTAN",
    postage: 0,
    phoneCode: "998",
    forthCrsCountryCode: "UZ",
    postageCurrencyTypeID: 0,
  },
  {
    id: 209,
    code: "UKR",
    name: "UKRAIN",
    postage: 0,
    phoneCode: "380",
    forthCrsCountryCode: "UA",
    postageCurrencyTypeID: 0,
  },
  {
    id: 210,
    code: "VEN",
    name: "VENEZUELLA",
    postage: 0,
    phoneCode: "58",
    forthCrsCountryCode: "VE",
    postageCurrencyTypeID: 0,
  },
  {
    id: 212,
    code: "SVK",
    name: "SLOVENIA",
    postage: 0,
    phoneCode: "386",
    forthCrsCountryCode: "SI",
    postageCurrencyTypeID: 0,
  },
  {
    id: 214,
    code: "SUR",
    name: "SURINAM",
    postage: 0,
    phoneCode: "597",
    forthCrsCountryCode: "SR",
    postageCurrencyTypeID: 0,
  },
  {
    id: 215,
    code: "ZAF",
    name: "SOUTH AFRICA",
    postage: 0,
    phoneCode: "27",
    forthCrsCountryCode: "ZA",
    postageCurrencyTypeID: 0,
  },
  {
    id: 216,
    code: "SLV",
    name: "EL SALVADOR",
    postage: 0,
    phoneCode: "503",
    forthCrsCountryCode: "SV",
    postageCurrencyTypeID: 0,
  },
  {
    id: 217,
    code: "TKM",
    name: "TURKMENISTAN",
    postage: 0,
    phoneCode: "993",
    forthCrsCountryCode: "TM",
    postageCurrencyTypeID: 0,
  },
  {
    id: 219,
    code: "THA",
    name: "THAILAND",
    postage: 0,
    phoneCode: "66",
    forthCrsCountryCode: "TH",
    postageCurrencyTypeID: 0,
  },
  {
    id: 220,
    code: "TWN",
    name: "TAIWAN",
    postage: 0,
    phoneCode: "886",
    forthCrsCountryCode: "TW",
    postageCurrencyTypeID: 0,
  },
  {
    id: 222,
    code: "WSM",
    name: "SAMOA",
    postage: 0,
    phoneCode: "685",
    forthCrsCountryCode: "WS",
    postageCurrencyTypeID: 0,
  },
  {
    id: 223,
    code: "SMR",
    name: "SAN MARINO",
    postage: 0,
    phoneCode: "378",
    forthCrsCountryCode: "SM",
    postageCurrencyTypeID: 0,
  },
  {
    id: 224,
    code: "SEN",
    name: "SENEGAL",
    postage: 0,
    phoneCode: "221",
    forthCrsCountryCode: "SN",
    postageCurrencyTypeID: 0,
  },
  {
    id: 226,
    code: "SVK",
    name: "SLOVAKIA",
    postage: 0,
    phoneCode: "421",
    forthCrsCountryCode: "SK",
    postageCurrencyTypeID: 0,
  },
  {
    id: 227,
    code: "SOM",
    name: "SOMALIA",
    postage: 0,
    phoneCode: "252",
    forthCrsCountryCode: "SO",
    postageCurrencyTypeID: 0,
  },
  {
    id: 230,
    code: "SJM",
    name: "Svalbard and Jan Mayen Islands\t",
    postage: 0,
    phoneCode: "47",
    forthCrsCountryCode: "SJ",
    postageCurrencyTypeID: 0,
  },
  {
    id: 231,
    code: "SWZ",
    name: "Swaziland",
    postage: 0,
    phoneCode: "268",
    forthCrsCountryCode: "SZ",
    postageCurrencyTypeID: 0,
  },
  {
    id: 234,
    code: "TJK",
    name: "TAJIKISTAN",
    postage: 0,
    phoneCode: "992",
    forthCrsCountryCode: "TJ",
    postageCurrencyTypeID: 0,
  },
  {
    id: 235,
    code: "TZA",
    name: "TANZANIA",
    postage: 0,
    phoneCode: "255",
    forthCrsCountryCode: "TZ",
    postageCurrencyTypeID: 0,
  },
  {
    id: 236,
    code: "TGO",
    name: "TOGO",
    postage: 0,
    phoneCode: "228",
    forthCrsCountryCode: "TG",
    postageCurrencyTypeID: 0,
  },
  {
    id: 237,
    code: "TKL",
    name: "TOKELAU",
    postage: 0,
    phoneCode: "690",
    forthCrsCountryCode: "TK",
    postageCurrencyTypeID: 0,
  },
  {
    id: 238,
    code: "TON",
    name: "TONGA",
    postage: 0,
    phoneCode: "676",
    forthCrsCountryCode: "TO",
    postageCurrencyTypeID: 0,
  },
  {
    id: 239,
    code: "TTO",
    name: "TRINIDAD AND TOBAGO",
    postage: 0,
    phoneCode: "1-868",
    forthCrsCountryCode: "TT",
    postageCurrencyTypeID: 0,
  },
  {
    id: 240,
    code: "TTN",
    name: "TUNISIA",
    postage: 0,
    phoneCode: "216",
    forthCrsCountryCode: "TN",
    postageCurrencyTypeID: 0,
  },
  {
    id: 241,
    code: "UGA",
    name: "UGANDA",
    postage: 0,
    phoneCode: "800",
    forthCrsCountryCode: "UG",
    postageCurrencyTypeID: 0,
  },
  {
    id: 242,
    code: "PRK",
    name: "KOREA(NORTH)",
    postage: 0,
    phoneCode: "408",
    forthCrsCountryCode: "KP",
    postageCurrencyTypeID: 0,
  },
  {
    id: 461,
    code: "VAT",
    name: "HOLY SEE (VATICAN)",
    postage: 0,
    phoneCode: "1",
    forthCrsCountryCode: "VA",
    postageCurrencyTypeID: 0,
  },
  {
    id: 580,
    code: "RKS",
    name: "KOSOVO",
    postage: 0,
    phoneCode: "395",
    forthCrsCountryCode: "XK",
    postageCurrencyTypeID: 0,
  },
  {
    id: 581,
    code: "KNA",
    name: "ST. KITTS AND NAVIS",
    postage: 0,
    phoneCode: "1",
    forthCrsCountryCode: "KN",
    postageCurrencyTypeID: 0,
  },
  {
    id: 582,
    code: "KBR",
    name: "KIBRIS",
    postage: 0,
    phoneCode: null,
    forthCrsCountryCode: null,
    postageCurrencyTypeID: 0,
  },
  {
    id: 583,
    code: "MKD",
    name: "NORTH MACEDONIA",
    postage: 0,
    phoneCode: "52",
    forthCrsCountryCode: "MK",
    postageCurrencyTypeID: 0,
  },
];
