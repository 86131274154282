import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useState } from "react";
import { Button, Layout, theme } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import ToggleThemeButton from '../../components/Logo/ToggleThemeButton';
import Logo from '../../components/Logo/Logo';
import MenuList from '../../components/Sidebar/MenuList';
import { getItem } from '../../utils/storage';
import { Token } from '../../services/Token';
import { useNavigate } from 'react-router-dom';

const { Header, Sider } = Layout;
export const Sidebar = () => {
    const [isLogged, setIsLogged] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        const userData = getItem("adminUser");
        // console.log("userData", userData)
        if (userData) {
            Token().check({
                token: userData?.token
            }).then((response) => {
                // başarılıysa oturum açık demektir.
                if (response.data.status == "success") {
                    setIsLogged(true)
                } else {
                    navigate('/admin/login')
                    setIsLogged(false)
                }
            }).catch(error => {
                navigate('/admin/login')
                setIsLogged(false)
            })
        } else {
            navigate('/admin/login')
            setIsLogged(false)
        }
    }, [])

    const [darkTheme, setDarkTheme] = useState(true);
    const [collapsed, setCollapsed] = useState(false)

    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <div>
            <Layout>
                <Sider collapsed={collapsed}
                    collapsible
                    trigger={null}
                    theme={darkTheme ? 'light' : 'dark'}
                    className="sidebar">
                    <Logo />
                    <MenuList darkTheme={darkTheme} />
                    {/* <ToggleThemeButton darkTheme={darkTheme}
                        toggleTheme={toggleTheme}
                    /> */}
                </Sider>
                <Layout>
                    <Header style={{ padding: 0, background: colorBgContainer }}>
                        <Button type="text"
                            className="toggle"
                            onClick={() => setCollapsed(!collapsed)}
                            icon={collapsed ? <MenuUnfoldOutlined /> :
                                <MenuFoldOutlined />} />
                    </Header>
                    <Outlet />
                </Layout>
            </Layout>
        </div>
    );
}
