import React from 'react'
import "./styles.css"
import { Row, Col } from 'antd'
import { MainContainer } from '../Layouts/MainContainer'
import { FeatureCard } from './partials/FeatureCard'
import ferryIcon from '../../assets/icons/ferry.png'
import creditCardIcon from '../../assets/icons/credit-card.png'
import creditCardInstallmentIcon from '../../assets/icons/credit-card-ins.png'
import customerServiceIcon from '../../assets/icons/customer-service.png'
import ticketIcon from '../../assets/icons/ticket.png'
import lowPriceIcon from '../../assets/icons/low-price.png'

export const FeaturesSection = () => {
    return (
        <MainContainer>
            <Row gutter={[32, 32]} style={{ marginBottom: 64 }}>
                <Col span={24}>
                    <h1 className='section-heading text-center'>
                        Yunan Adaları Ucuz Feribot Bileti Satın Al!
                    </h1>
                    <p className='altBaslik'>Yunan Adalarına Uygun Feribot Farkıyla Ulaşın ve 3 Adımda Hızlıca Bilet Satın Alın!</p>
                </Col>

                <Col lg={8} md={12} sm={12} xs={24}>
                    <FeatureCard
                        title="En Uygun Fiyat Garantisi"
                        description="Uygun Feribot, Yunan adalarına en uygun fiyatlı feribot biletlerini 3 adımda kolayca satın almanızı sağlar."
                        icon={lowPriceIcon}
                    />
                </Col>
                <Col lg={8} md={12} sm={12} xs={24}>
                    <FeatureCard
                        title="Güvenli Ödeme Altyapısı"
                        description="SSL sertifikası ve 3D kredi kartı doğrulamasıyla PayTR altyapısında güvenli ödeme yapabilirsiniz."
                        icon={creditCardIcon}
                    />
                </Col>
                <Col lg={8} md={12} sm={12} xs={24}>
                    <FeatureCard
                        title="Bileti Açığa Alma Hakkı"
                        description="Her firmanın koşullarına göre değişmekle birlikte, sefer saatinden 24 saat önce biletinizi açığa aldırabilirsiniz."
                        icon={ticketIcon}
                    />
                </Col>
                <Col lg={8} md={12} sm={12} xs={24}>
                    <FeatureCard
                        title="Müşteri Hizmetleri"
                        description="Her konuda bilgi almak için müşteri hizmetleri hattını arayabilir veya iletişim formunu doldurabilirsiniz."
                        icon={customerServiceIcon}
                    />
                </Col>

                <Col lg={8} md={12} sm={12} xs={24}>
                    <FeatureCard
                        title="Feribot Firması Çeşitliliği"
                        description="Yunan adalarına sefer yapan tüm feribot firmalarına sistemimiz üzerinden rezervasyon yapabilirsiniz."
                        icon={ferryIcon}
                    />
                </Col>

                <Col lg={8} md={12} sm={12} xs={24}>
                    <FeatureCard
                        title="Kredi Kartına Taksit Avantajı"
                        description="Tüm kredi kartlarıyla bilet satın alırken, taksit seçeneklerinden faydalanarak biletinizi taksitli olarak alabilirsiniz."
                        icon={creditCardInstallmentIcon}
                    />
                </Col>
            </Row>
        </MainContainer>
    )
}