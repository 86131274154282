import React, { useState, useEffect } from "react";
import { Button, Divider, Modal, Table } from "antd";
import "./FerriesTable.css"
import { AgentService } from '../../../../services/AgentService';
import { Flex } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { notification } from "antd";
import { Ferries } from "../../../../services/Ferries";
import { HeaderLayout } from "../../HeaderLayout";

const FerriesTable = () => {
  const [TourmodalOpen, setTourModalOpen] = useState(false);
  const [DeletemodalOpen, setDeleteModalOpen] = useState(false);
  const [UpdatemodalOpen, setUpdateModalOpen] = useState(false);
  const [ShowmodalOpen, setShowModalOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [agentsList, setAgentList] = useState([]);

  const [from, setFrom] = useState("BODRUM");
  const [to, setTo] = useState("RODOS");
  const [varisLimanlari] = useState({
    FETHIYE: ["RODOS"],
    BODRUM: ["RODOS", "KOS", "SYMI", "PATMOS"],
    KUSADASI: ["SAMOS", "PATMOS"],
    CESME: ["CHIOS-SAKIZ"],
    AYVALIK: ["MİDİLLİ"],
    KAS: ["MEIS"],
    KOS: ["BODRUM"],
    CHIOS_SAKIZ: ["ÇEŞME"],
    MIDILLI: ["AYVALIK"],
    MEIS: ["KAS"],
    SAMOS: ["KUŞADASI"],
    SYMI: ["BODRUM"],
    PATMOS: ["BODRUM", "KUŞADASI"],
    RODOS: ["BODRUM", "FETHiYE"],
    SEFERIHISAR: ["SAMOS"],
    // KALKIS LİMANLARİ
  });

  const [GidisTarihi, setGidisTarihi] = useState("");
  const [DonusTarihi, setDonusTarihi] = useState("");
  const [donusTarihiAktif, setDonusTarihiAktif] = useState(true);
  const [name, setName] = useState();
  const [agentName, setAgentName] = useState();
  const [selectedFerryId, setSelectedFerryId] = useState();

  useEffect(() => {
    // Günün tarihini al
    const bugun = new Date().toISOString().split("T")[0];
    setGidisTarihi(bugun);
    if (donusTarihiAktif) {
      setDonusTarihi(bugun);
    } else {
      setDonusTarihi("");
    }
  }, [donusTarihiAktif]);

  const TourModalOpen = () => {
    setTourModalOpen(true);
  };
  const TourModalOff = () => {
    setTourModalOpen(false);
  };

  const DeleteModalOpen = () => {
    setDeleteModalOpen(true);
  };
  const DeleteModalOff = () => {
    setDeleteModalOpen(false);
  };

  const UpdateModalOpen = (record) => {
    const agentName = getAgentName(record.agent_id)
    setName(record.name)
    setAgentName(agentName)
    setUpdateModalOpen(true);
    setSelectedFerryId(record.id)
  };
  const UpdateModalOff = () => {
    setUpdateModalOpen(false);
  };

  const ShowModalOpen = () => {
    setShowModalOpen(true);
  };
  const ShowModalOff = () => {
    setShowModalOpen(false);
  };

  const [number, setNumber] = useState("");

  const handleChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setNumber(event.target.value);
    }
  };

  const handleChangeName = (event) => {
    setName(event.target.value)
  }
  const handleChangeAgentName = (event) => {
    setAgentName(event.target.value)
  }
  const { remove: removeFerry } = Ferries()

  const deleteFerry = (id) => {
    removeFerry(id).then((response) => {
      if (response.data.status == "success") {
        fetchData()
        fetchAgents()
        notification.success({
          message: 'Başarılı',
          description: 'Feribot başarıyla silindi',
          status: 'success'
        })
      }
    }).catch(error => notification.error({
      message: 'Hata',
      description: 'Hata oluştu',
      status: 'error'
    }))
  }

  const getAgentName = (id) => agentsList.find((item) => (
    item.id == id
  ))?.name

  const fetchAgents = () => {
    AgentService().list().then((response) => {
      const { data } = response;
      if (data?.status == "success") {
        const agents = data.data;
        setAgentList(agents)
        console.log("ferries", data);
      } else {
        console.log("hata", data?.message);
      }
    }).catch((error) => {
      console.log("err", error);
    });
  }

  const fetchData = () => {
    Ferries().list().then((response) => {
      const { data } = response;
      if (data?.status == "success") {
        const agents = data.data;
        setDataSource(agents)
        console.log("ferries", data);
      } else {
        console.log("hata", data?.message);
      }
    }).catch((error) => {
      console.log("err", error);
    });
  }

  const createFerry = () => {
    const name = document.querySelector("input[name=name").value;
    const agentId = document.querySelector("select[name=agent_id").value;
    Ferries().create({
      name,
      agent_id: agentId
    }).then((response) => {
      const { data } = response;
      if (data?.status == "success") {
        console.log("ferry oluştu", data);

        notification.success({
          message: 'Başarılı',
          description: 'Başarıyla feribot eklendi!',
          placement: 'topRight'
        })
        TourModalOff()
        fetchData()
      } else {
        notification.error({
          message: 'Hata',
          description: 'Feribot eklenemedi!',
          placement: 'topRight'
        })
      }
    }).catch((error) => {
      notification.error({
        message: 'Hata',
        description: 'Feribot eklenemedi!',
        placement: 'topRight'
      })
    });
  }

  const updateFerry = () => {
    const agentId = document.querySelector("#edit-ferry select[name=agent_id").value;
    Ferries().update(selectedFerryId, {
      name,
      agent_id: agentId
    }).then((response) => {
      const { data } = response;
      if (data?.status == "success") {
        console.log("ferry oluştu", data);

        notification.success({
          message: 'Başarılı',
          description: 'Başarıyla feribot düzenlendi!',
          placement: 'topRight'
        })
        UpdateModalOff()
        fetchData()
      } else {
        notification.error({
          message: 'Hata',
          description: 'Feribot düzenlenemedi!',
          placement: 'topRight'
        })
      }
    }).catch((error) => {
      notification.error({
        message: 'Hata',
        description: 'Feribot düzenlenemedi!',
        placement: 'topRight'
      })
    });
  }

  useEffect(() => {
    fetchData()
    fetchAgents()
  }, [])

  const columns = [
    {
      title: 'İsim',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Acente ID',
      dataIndex: 'agent_id',
      key: 'agent_id',
      render: (text, record, index) => {
        const agentName = agentsList.find((item) => (
          item.id == record.agent_id
        ))?.name

        return agentName || '--'
      }
    },
    {
      title: 'İşlemler',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record, index) => {
        return (
          <Flex align="flex-start" gap="small">
            <Button
              type="primary"
              ghost
              onClick={() => {
                // setUpdateModalForm(record)
                UpdateModalOpen(record)
              }} icon={<EditOutlined />}
            />
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => Modal.confirm({
                title: record.id + ' - Silmek istediğinize emin misiniz?',
                footer: (_, { OkBtn, CancelBtn }) => (
                  <>
                    <OkBtn />
                    <CancelBtn />
                  </>
                ),
                okText: 'Sil',
                onOk: () => {
                  console.log("drecord", record);
                  deleteFerry(Number(record.id))
                },
                cancelText: 'İptal',
              })}
            />
          </Flex>
        )
      }
    },

  ];

  return (
    <>
      <HeaderLayout
        title='Feribotlar'
        btnTitle='Feribot Ekle'
        onClick={TourModalOpen}
        Table={<Table dataSource={dataSource} columns={columns} />}
      />

      {/*Tour Add*/}
      {TourmodalOpen && (
        <div className="tour-add">
          <div className="tour-add-modal-content">
            <span className="close" onClick={TourModalOff}>
              &times;
            </span>
            <p>Feribot Ekle</p>
            <Divider />
            <div className="name-modal">
              <label>İsim</label>
              <input
                type="text"
                id="name"
                name="name"
                onChange={handleChange}
                required
                placeholder="Lütfen feribot ismi girin"
              />
            </div>

            <div className="agent-select-modal">
              <label>Acente</label>
              <select className="select" name="agent_id" id="agent_id">
                {agentsList.map(option => (
                  <option key={option.id} value={option.id}>{option.name}</option>
                ))}
              </select>
            </div>
            <button className="add-trip-btn" onClick={createFerry}>Tamam</button>
          </div>
        </div>
      )}

      {/*Ferry Edit*/}
      {UpdatemodalOpen && (
        <div className="tour-add" id="edit-ferry">
          <div className="tour-add-modal-content">
            <span className="close" onClick={UpdateModalOff}>
              &times;
            </span>
            <p>Feribot Düzenle</p>
            <Divider />
            <div className="name-modal">
              <label>İsim</label>
              <input
                type="text"
                id="name"
                name="name"
                onChange={handleChangeName}
                value={name}
                required
                placeholder="Lütfen feribot ismi girin"
              />
            </div>

            <div className="agent-select-modal">
              <label>Acente</label>
              <select className="select" name="agent_id" id="agent_id" onChange={handleChangeAgentName}>
                {agentsList.map(option => (
                  <option key={option.id} value={option.id} selected={agentName == option.name}>{option.name}</option>
                ))}
              </select>
            </div>
            <button className="add-trip-btn" onClick={updateFerry}>Kaydet</button>
          </div>
        </div>
      )}

      {/* Show Modal */}
      {ShowmodalOpen && (
        <div className="show-modal">
          <div className="show-modal-content">
            <span className="close" onClick={ShowModalOff}>
              &times;
            </span>
            <p>Sefer Bilgileri</p>
            <div className="round-trip-cont">
              <p className="round-trip-item">ID:</p>
              <p className="round-trip-item">Firma:</p>
            </div>
            <div className="round-trip-cont">
              <p className="round-trip-item">Nerden:</p>
              <p className="round-trip-item">Nereye:</p>
            </div>
            <div className="round-trip-cont">
              <p className="round-trip-item">Gidiş Tarihi:</p>
              <p className="round-trip-item">Dönüş Tarihi:</p>
            </div>
            <button className="show-payment-oky-btn" onClick={ShowModalOff}>
              Tamam
            </button>
          </div>
        </div>
      )}

      {/* Update Modal */}
      {/* {UpdatemodalOpen && (
        <div className="update-modal">
          <div className="update-modal-content">
            <span className="close" onClick={UpdateModalOff}>
              &times;
            </span>
            <p>Sefer Düzenle</p>
            <div className="form-group-select-ticket">
              <div className="one-way-two-way-container">
                <input
                  type="checkbox"
                  id="toggle"
                  className="toggleCheckbox"
                  checked={donusTarihiAktif}
                  onChange={() => setDonusTarihiAktif(!donusTarihiAktif)}
                />
                <label
                  htmlFor="toggle"
                  className="toggleContainer"
                  style={{
                    fontWeight: "500",
                  }}
                >
                  <div>Tek Yön</div>
                  <div>Gidiş Dönüş</div>
                </label>
              </div>
            </div>
            <div className="update-modal-location">
              <div className="form-group-select-ticket">
                <div className="label-container">
                  <label htmlFor="from">Nereden</label>
                </div>
                <select
                  className="select-way"
                  id="from"
                  name="from"
                  value={from}
                  onChange={(e) => {
                    setFrom(e.target.value);
                    setTo(varisLimanlari[e.target.value][0]);
                  }}
                >
                  <option value="FETHIYE">FETHİYE</option>
                  <option value="BODRUM">BODRUM</option>
                  <option value="KUSADASI">KUŞADASI</option>
                  <option value="CESME">ÇEŞME</option>
                  <option value="AYVALIK">AYVALIK</option>
                  <option value="KAS">KAŞ</option>
                  <option value="KOS">KOS</option>
                  <option value="CHIOS_SAKIZ">CHIOS-SAKIZ</option>
                  <option value="MIDILLI">MİDİLLİ</option>
                  <option value="MEIS">MEIS</option>
                  <option value="SAMOS">SAMOS</option>
                  <option value="SYMI">SYMI</option>
                  <option value="PATMOS">PATMOS</option>
                  <option value="RODOS">RODOS</option>
                  <option value="SEFERIHISAR">SEFERİHİSAR</option>
                </select>
              </div>
              <div className="form-group-select-ticket">
                <div className="label-container">
                  <label htmlFor="to">Nereye</label>
                </div>
                <select
                  className="select-way"
                  id="to"
                  name="to"
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                >
                  {varisLimanlari[from] &&
                    varisLimanlari[from].map((liman) => (
                      <option key={liman} value={liman}>
                        {liman}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="update-modal-time">
              <input
                className={`departure-date ${donusTarihiAktif ? "aktif" : ""}`}
                type="date"
                id="GidisTarihi"
                name="GidisTarihi"
                value={GidisTarihi}
                onChange={(e) => setGidisTarihi(e.target.value)}
              />
              <input
                className={`return-date ${donusTarihiAktif ? "aktif" : "pasif"
                  }`}
                type="date"
                id="DonusTarihi"
                name="DonusTarihi"
                value={DonusTarihi}
                onChange={(e) => setDonusTarihi(e.target.value)}
                disabled={!donusTarihiAktif}
              />
            </div>
            <div className="update-modal-company">
              <select className="select">
                <option value="1">Firma</option>
                <option value="2">Tilos</option>
                <option value="3">Turyol</option>
                <option value="4">Ertürk Lines</option>
              </select>
            </div>
            <button onClick={UpdateModalOff} className="update-modal-btn">
              <p>Güncelle</p>
            </button>
          </div>
        </div>
      )} */}

      {/* Delete Modal */}
      {DeletemodalOpen && (
        <div className="delete-modal">
          <div className="delete-modal-content">
            <span className="close" onClick={DeleteModalOff}>
              &times;
            </span>
            <p>Seçili Seferi Silmek İstediğinize Emin misiniz ?</p>
            <Divider />
            <div className="delete-modal-button-container">
              <button
                onClick={DeleteModalOff}
                className="delete-modal-btn"
                id="vazgec-btn"
              >
                <p>Vazgeç</p>
              </button>
              <button className="delete-modal-btn" id="sil-btn">
                <p>Sil</p>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FerriesTable;
