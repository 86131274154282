import './styles.css'

export const CommonHeader = ({ children, title, where }) => {

    return (
        <div className={"header-section" + " " + where}>
            <div className={"header-title main-container" + " " + where}>
                <h2 style={{ color: 'white', margin: 0, marginBottom: 0, fontSize: 26 }}>{title}</h2>
            </div>

            {children}
        </div>
    )
}