import React from "react";
import "../Admin/Css/BlogEdit.css";
import MessagesTable from "./Table/Mesajlar/MessagesTable";

const Messages = () => {
  return (
    <>
      <div className="blogedit-banner">
        <div className="blogedit-banner-text">
          <h1>İletişim</h1>
        </div>
      </div>
      <div className="blogedit-admin">
        <h1>Son Mesajlar</h1>
      </div>
      <MessagesTable/>
    </>
  );
};

export default Messages;
