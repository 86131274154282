export const formatTourTime = (minutes) => {
  if (minutes < 60) {
    return `${minutes}dk`;
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return `${hours}sa ${remainingMinutes}d`;
};
