import React, { useState } from "react";
import "../Admin/Css/FirsatEdit.css";
import { IoEyeSharp } from "react-icons/io5";
import { IoPencilSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import RichTextEditor from "./TextEditor";
import TextArea from "antd/es/input/TextArea";
import FirsatEditTable from "./Table/FirsatEdit/FirsatEditTable";

const FirsatEdit = () => {
  const [deleteBlogModal, setDeleteBlogModalOpen] = useState(false);
  const DeleteBlogModalOpen = () => {
    setDeleteBlogModalOpen(true);
  };
  const DeleteBlogModalOff = () => {
    setDeleteBlogModalOpen(false);
  };

  const [editBlogModal, setEditBlogModalOpen] = useState(false);
  const EditBlogModalOpen = () => {
    setEditBlogModalOpen(true);
  };
  const EditBlogModalOff = () => {
    setEditBlogModalOpen(false);
  };

  return (
    <>
      <div className="blogadd-banner">
        <div className="blogadd-banner-text">
          <h1>Fırsat Düzenleyin</h1>
        </div>
      </div>
      <FirsatEditTable/>
    </>
  );
};

export default FirsatEdit;
