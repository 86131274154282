import React from "react";
import "./styles.css";
import { TabTitle } from "../../tools/GeneralFunction";
import ScrollToTop from "react-scroll-to-top";
import BiletAl from "../../components/BiletAl";
import { CommonHeader } from "../../components/CommonHeader";
import { FeaturesSection } from "../../components/FeaturesSection";
import { MainContainer } from "../../components/Layouts/MainContainer";

const HomePage = () => {
  TabTitle("Feribot Bileti Al - Uygun Feribot | Yunan Adaları Feribot Bileti Satın Al");

  return (
    <>
      <CommonHeader title="Yunan Adaları Ucuz Feribot Bileti Bir Arama Uzağınızda" where="search">
        <MainContainer>
          <BiletAl />
        </MainContainer>
      </CommonHeader>

      <FeaturesSection />

      <ScrollToTop style={{
        borderRadius: "90px",
        width: "48px",
        height: "48px",
      }} smooth top="700"
        color="#070B2A"
        width="20px" />
    </>
  );
};

export default HomePage;
