import { Button, Card, Checkbox, Col, Form, Input, Row } from "antd";
import { ContactsOutlined } from "@ant-design/icons";
import _ from "lodash";
import { useFormHelper } from "./helpers/useFormHelper";
import { Link } from "react-router-dom";

export const PassengerStep = ({ passengers, form }) => {
    const { adults, childs, infants } = passengers
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const { PassengerForm } = useFormHelper(form)


    const handleKeyDownPhoneNumber = (e) => {
        const isCtrlCmd = e.ctrlKey || e.metaKey;

        // Allow control keys
        if (
            isCtrlCmd &&
            (e.key === 'a' || e.key === 'A' ||
                e.key === 'c' || e.key === 'C' ||
                e.key === 'v' || e.key === 'V' ||
                e.key === 'x' || e.key === 'X')
        ) {
            return;
        }

        // Allow navigation keys
        if (
            e.key === 'Backspace' || e.key === 'Delete' ||
            e.key === 'ArrowLeft' || e.key === 'ArrowRight'
        ) {
            return;
        }

        // Allow digits
        if (!/^\d$/.test(e.key)) {
            e.preventDefault();
        }
    };

    const handlePastePhonenumber = (e) => {
        const paste = e.clipboardData.getData('text');
        if (!/^\d*$/.test(paste)) {
            e.preventDefault();
        }
    };

    return (
        <div>
            <Card
                title={
                    <div >
                        <ContactsOutlined style={{ fontSize: 19 }} />
                        <span style={{ marginLeft: 10 }}>İletişim Bilgileri</span>
                    </div>
                }
            >
                <Form form={form} layout='vertical' name="contact">
                    <Row gutter={[16, 16]}>
                        <Col md={12} sm={12} xs={24}>
                            <Form.Item
                                label='Adınız'
                                name={['contact', 'name']}
                                rules={[{ required: true, message: 'Lütfen adınızı girin' }]}
                                initialValue={""}
                            >
                                <Input
                                    placeholder="Adınızı girin"
                                />
                            </Form.Item>
                        </Col>
                        <Col md={12} sm={12} xs={24}>
                            <Form.Item
                                label='Soyadınız'
                                name={['contact', 'surname']}
                                rules={[{ required: true, message: 'Lütfen soyadınızı girin' }]}
                                initialValue={""}
                            >
                                <Input
                                    placeholder="Soyadınızı girin"
                                />
                            </Form.Item>
                        </Col>
                        <Col md={12} sm={12} xs={24}>
                            <Form.Item
                                label='Telefon numaranız'
                                name={['contact', 'phone']}
                                rules={[{ required: true, message: 'Lütfen telefon numaranızı girin' }]}
                                initialValue={""}
                            >
                                <Input
                                    placeholder="Telefon numaranızı girin"
                                    onKeyDown={handleKeyDownPhoneNumber}
                                    onPaste={handlePastePhonenumber}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={12} sm={12} xs={24}>
                            <Form.Item
                                label='E-posta adresiniz'
                                name={['contact', 'email']}
                                rules={[
                                    { required: true, message: 'Lütfen e-posta adresinizi girin' },
                                    { type: 'email', message: 'Geçerli bir e-posta adresi girin' },
                                    {
                                        pattern: emailRegex,
                                        message: 'Geçerli bir e-posta adresi girin (Türkçe ve yabancı karakterler olmamalı)',
                                    },
                                ]}
                                initialValue={""}
                            >
                                <Input
                                    placeholder="Eposta adresi girin"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>

            <PassengerForm passengers={adults} customerTypeName="Yetişkin" formName="adults" />
            <PassengerForm passengers={childs} customerTypeName="Çocuk" formName="childs" />
            <PassengerForm passengers={infants} customerTypeName="Bebek" formName="infants" />
        </div>
    )
}