import React from 'react'
import '../Footer/Footer.css'
import { LiaShipSolid } from "react-icons/lia";
import { Link } from 'react-router-dom';

import PaymentOptionsImage from '../../assets/images/odeme-secenekleri.png'
import PaymentLogos from '../../assets/images/tekparca-logolar-4.jpg'
import { Col, Image, Row } from 'antd';



const Footer = () => {
  return (
    <footer>
      <div className="content-footer">
        <div className="link-boxes">
          <ul className="box">
            <li className="link_name">Popüler Feribot Seferleri</li>
            <li>
              <Link to={"/bilet-al"}>Çeşme - Sakız Feribot Bileti</Link>
            </li>
            <li>
              <Link to={"/bilet-al"}>Kuşadası - Samos Feribot Bileti</Link>
            </li>
            <li>
              <Link to={"/bilet-al"}>Seferihisar - Samos Feribot Bileti</Link>
            </li>
            <li>
              <Link to={"/bilet-al"}>Ayvalık - Midilli Feribot Bileti</Link>
            </li>
          </ul>
          {/* <ul className="box">
            <li className="link_name">Hizmetlerimiz</li>
            <li><a href="#">Yunan Adaları Feribot Bileti</a></li>
            <li><a href="#">Otel</a></li>
            <li><a href="#">Transfer</a></li>
          </ul> */}
          <ul className="box">
            <li className="link_name">Gizlilik</li>
            <li><Link to={"/aydinlatma-metni"}>KVKK Aydınlatma Metni</Link></li>
            <li><Link to={"/gizlilik-sozlesmesi"}>Gizlilik Sözleşmesi</Link></li>
            <li><Link to={"/mesafeli-satis-sozlesmesi"}>Mesafeli Satış Sözleşmesi</Link></li>
            {/* <li><Link to={"/sikca-sorulan-sorular"}>Sıkça Sorulan Sorular</Link></li> */}
          </ul>
          <ul className="box">
            <li className="link_name">Kurumsal</li>
            <li><Link to={"/hakkimizda"}>Hakkımızda</Link></li>
            <li><Link to={"/iletisim"}>İletişim</Link></li>
            {/* <li><Link to={"/blog"}>Blog</Link></li> */}
          </ul>
        </div>
      </div>
      <div className='footer'>
        <Row gutter={[32, 32]}>
          <Col md={12} xs={24}>
            <div className='footerText'>
              <span className="copyright_text">Copyright © 2024 <a href="">Uygun Feribot</a> Tüm hakları saklıdır.</span>
            </div>
          </Col>
          <Col md={12} xs={24}>
            <div className='footerImages'>
              <div style={{ display: 'inline' }}>
                <Image src={PaymentOptionsImage} width={150} preview={false} />
              </div>
              <div style={{ marginLeft: 20, display: 'inline' }}>
                <Image src={PaymentLogos} width={150} preview={false} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  )
}

export default Footer