import { Button, Col, Collapse, Divider, Form, Input, notification, Row, Switch, Tooltip } from "antd"
import { ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined } from "@ant-design/icons"
import { TourInfo } from "./partials/TourInfo"
import _ from "lodash"
import { useEffect, useRef, useState } from "react"
import { Coupons } from "../../../../services/Coupons/Countries"
import { CurrencyParities } from "../../../../services/Common/CurrencyParities"

export const SummarySection = ({
    reservationData,
    selectedDepartureTour,
    selectedArrivalTour,
    current,
    onPrev,
    onNext,
    payNow,
    totalPrice, setTotalPrice,
    usedCouponData, setUsedCouponData,
    form
}) => {
    const [couponCode, setCouponCode] = useState(undefined)
    const [useCoupon, setUseCoupon] = useState(false)
    const [totalPriceTL, setTotalPriceTR] = useState(0)
    const [applyingCoupon, setApplyingCoupon] = useState(false)

    const [originalPriceTL, setOriginalPriceTR] = useState(0)
    const originalPrice = reservationData?.passengerPrices.reduce((sum, item) => sum + item.totalAmount, 0)

    useEffect(() => {
        if (totalPrice) {
            // convert EUR to TRY
            CurrencyParities().calculateParities({
                amount: totalPrice,
                sourceCurrencyTypeId: 2, // EUR
                targetCurrencyTypeId: 1 // to TRY
            }).then(({ data }) => {
                if (data?.statusCode == 200) {
                    const totalAmountTL = data?.data
                    // console.log("data", data);
                    setTotalPriceTR(totalAmountTL.toFixed(2))
                }
            })
            CurrencyParities().calculateParities({
                amount: originalPrice,
                sourceCurrencyTypeId: 2, // EUR
                targetCurrencyTypeId: 1 // to TRY
            }).then(({ data }) => {
                if (data?.statusCode == 200) {
                    const totalAmountTL = data?.data
                    // console.log("data", data);
                    setOriginalPriceTR(totalAmountTL.toFixed(2))
                }
            })
        }
    }, [totalPrice])

    console.log("formData", form.getFieldsValue());

    const onChangeCouponCode = (event) => {
        setCouponCode(event.target.value)
    }

    const applyCoupon = () => {
        const contactPhoneField = document.querySelector("#contact_contact_phone").value
        console.log("contactfield", contactPhoneField);
        if (!couponCode || couponCode == "") {
            notification.error({ message: "Hata", description: "Geçerli bir kupon kodu girin!" })
            return;
        }
        if (!contactPhoneField || contactPhoneField == "") {
            notification.error({ message: "Hata", description: "Kupon kodunun uygulanabilmesi için iletişim bilgilerinizi eksiksiz doldurun!" })
            return;
        }
        setApplyingCoupon(true)
        const routeType = isReturnTourActive ? 2 : 1
        Coupons().couponIsActive({ couponCode, routeType, phoneNumber: contactPhoneField }).then(async ({ data }) => {
            const couponData = data?.data?.data
            // console.log("couponData", couponData);
            if (couponData == null) {
                notification.error({ message: 'Hata!', description: data?.message || 'Geçersiz kupon kodu!' })
                setApplyingCoupon(false)
            }
            if (!_.isEmpty(couponData)) {
                if (couponData?.isActive) {
                    if (couponData?.discountType > 0) {
                        const discountAmount = couponData?.discountAmount
                        const couponDiscount = (originalPrice * discountAmount) / 100
                        const finalPrice = originalPrice - couponDiscount
                        setTotalPrice(finalPrice)
                        setUsedCouponData(couponData)
                        notification.success({ message: 'Başarılı!', description: `%${discountAmount} indirim başarıyla uygulandı!` })
                    } else if (couponData?.discountType <= 0) {
                        CurrencyParities().calculateParities({
                            amount: originalPrice,
                            sourceCurrencyTypeId: 2, // TRY
                            targetCurrencyTypeId: 1 // to EUR
                        }).then(({ data }) => {
                            if (data?.statusCode == 200) {
                                const tlPrice = data?.data
                                // console.log("tlPrice", tlPrice);

                                const discountAmount = couponData?.discountAmount
                                const finalPrice = tlPrice - discountAmount

                                CurrencyParities().calculateParities({
                                    amount: finalPrice,
                                    sourceCurrencyTypeId: 1, // EUR
                                    targetCurrencyTypeId: 2 // to TRY
                                }).then(({ data }) => {
                                    if (data?.statusCode == 200) {
                                        const eurPrice = data?.data
                                        // console.log("eurPrice", eurPrice);

                                        setTotalPrice(eurPrice.toFixed(2))
                                        setUsedCouponData(couponData)
                                        notification.success({ message: 'Başarılı!', description: `₺${discountAmount} indirim başarıyla uygulandı!` })
                                    }
                                })

                            }
                        })
                    } else {
                        notification.error({ message: 'Hata!', description: `Bu kuponda herhangi bir indirim yok!` })
                    }

                    setApplyingCoupon(false)
                } else {
                    notification.error({ message: 'Hata!', description: 'Bu kuponun süresi doldu!' })

                    setApplyingCoupon(false)
                }
            }
            // console.log("response", data);
        }).catch((error) => {
            notification.error({ message: 'Hata!', description: 'Kupon uygulanırken bir hata oluştu!' })
            setApplyingCoupon(false)
        })
    }
    const isReturnTourActive = !_.isEmpty(selectedArrivalTour)

    const separateTours = (data, isReturnActive) => {
        const departureTour = [];
        const returnTour = [];

        if (!isReturnActive) {
            // Hepsi gidiş turu olarak kabul edilir
            return { departureTour: data, returnTour: [] };
        }

        // Gidiş ve dönüş turlarını ayır
        for (let i = 0; i < data.length; i += 2) {
            departureTour.push(data[i]);
            if (i + 1 < data.length) {
                returnTour.push(data[i + 1]);
            }
        }

        return { departureTour, returnTour };
    };

    const { departureTour, returnTour } = separateTours(reservationData?.passengerPrices, isReturnTourActive);

    const departureChildPassengers = departureTour.filter((data) => data.typeName == "Child")
    const departureAdultPassengers = departureTour.filter((data) => data.typeName == "Adult")
    const returnChildPassengers = returnTour.filter((data) => data.typeName == "Child")
    const returnAdultPassengers = returnTour.filter((data) => data.typeName == "Adult")

    return (
        <div className="continue-container">
            <div className="summary-section">
                <Row className="summary-section-row">
                    <Col md={24}>
                        <TourInfo
                            selectedTour={selectedDepartureTour}
                            wayLabel='Gidiş Seferi'
                        />

                        {
                            isReturnTourActive && (
                                <>
                                    <Divider />

                                    <TourInfo
                                        selectedTour={selectedArrivalTour}
                                        wayLabel='Dönüş Seferi'
                                    />
                                </>
                            )
                        }
                    </Col>


                    <Col md={24} style={{ display: 'grid', alignItems: 'end' }}>
                        <Divider />

                        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
                            {
                                current < 2 && (
                                    <Col span={24}>
                                        <Form layout="horizontal">
                                            <Form.Item disabled={applyingCoupon} label="Kupon kodum var" style={{ marginBottom: 0 }} initialValue={useCoupon}>
                                                <Switch defaultChecked onChange={setUseCoupon} value={useCoupon} />
                                            </Form.Item>
                                        </Form>
                                        {
                                            useCoupon && (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Input placeholder="Kupon Kodunu Girin" style={{ marginRight: '8px' }} value={couponCode} onChange={onChangeCouponCode} />
                                                    <Button loading={applyingCoupon} disabled={!_.isEmpty(usedCouponData) || applyingCoupon} style={{ height: 54 }} type="primary" onClick={applyCoupon}>{applyingCoupon ? 'Uygulanıyor' : 'Uygula'}</Button>
                                                </div>
                                            )
                                        }
                                    </Col>
                                )
                            }

                            <Collapse className="showPriceDetailsCollapse" style={{ width: '100%' }} ghost items={[{
                                key: '1',
                                label: 'Fiyat detayları',
                                children: <>
                                    {
                                        departureAdultPassengers.map((data, index) => (
                                            <Col span={24}>
                                                <div style={{ fontWeight: '600' }}>(Gidiş)</div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 16, color: '#666' }}>
                                                    <div style={{ fontWeight: '500', fontSize: 15 }}>{index + 1}. {data.typeName == "Child" ? 'Çocuk' : 'Yetişkin'}</div>
                                                    <div style={{ fontWeight: '600' }}>{data?.currencyTypeSymbol + data?.totalAmount}</div>
                                                </div>
                                            </Col>
                                        ))
                                    }
                                    {
                                        departureChildPassengers.map((data, index) => (
                                            <Col span={24}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 16, color: '#666' }}>
                                                    <div style={{ fontWeight: '500', fontSize: 15 }}>{index + 1}. {data.typeName == "Child" ? 'Çocuk' : 'Yetişkin'}</div>
                                                    <div style={{ fontWeight: '600' }}>{data?.currencyTypeSymbol + data?.totalAmount}</div>
                                                </div>
                                            </Col>
                                        ))
                                    }

                                    {
                                        isReturnTourActive && (
                                            <>
                                                {
                                                    returnAdultPassengers.map((data, index) => (
                                                        <Col span={24}>
                                                            <div style={{ display: 'block', fontWeight: '600', marginTop: 6 }}>(Dönüş)</div>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 16, color: '#666' }}>
                                                                <div style={{ fontWeight: '500', fontSize: 15 }}>{index + 1}. {data.typeName == "Child" ? 'Çocuk' : 'Yetişkin'}</div>
                                                                <div style={{ fontWeight: '600' }}>{data?.currencyTypeSymbol + data?.totalAmount}</div>
                                                            </div>
                                                        </Col>
                                                    ))
                                                }
                                                {
                                                    returnChildPassengers.map((data, index) => (
                                                        <Col span={24}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 16, color: '#666' }}>
                                                                <div style={{ fontWeight: '500', fontSize: 15 }}>{index + 1}. {data.typeName == "Child" ? 'Çocuk' : 'Yetişkin'}</div>
                                                                <div style={{ fontWeight: '600' }}>{data?.currencyTypeSymbol + data?.totalAmount}</div>
                                                            </div>
                                                        </Col>
                                                    ))
                                                }
                                            </>
                                        )
                                    }

                                    {!_.isEmpty(usedCouponData) && (
                                        <>
                                            <Col span={24}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 16, color: '#666', marginTop: 16 }}>
                                                    <div style={{ fontWeight: '500', fontSize: 15 }}>İndirim öncesi toplam</div>
                                                    <div style={{ fontWeight: '600' }}>€{originalPrice} <span style={{ fontSize: 14 }}>(₺{originalPriceTL})</span></div>
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 16, color: 'forestgreen', marginTop: 16 }}>
                                                    <div style={{ fontWeight: '500', fontSize: 15 }}>
                                                        <Tooltip title={'Kuponu kaldır'}>
                                                            <Button type="link" size="small" style={{ color: 'red' }} icon={<CloseOutlined />}></Button>
                                                        </Tooltip>
                                                        (İNDİRİM) {usedCouponData.code}
                                                    </div>
                                                    <div style={{ fontWeight: '600' }}>
                                                        -{usedCouponData?.discountType > 0 ? '%' : '₺'}{usedCouponData?.discountAmount}
                                                    </div>
                                                </div>
                                            </Col>
                                        </>
                                    )}
                                </>,
                            },]} />

                            <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', fontSize: 18, marginTop: 8 }}>
                                <div style={{ fontWeight: '500', fontSize: 17 }}>Ödenecek tutar <br />{isReturnTourActive && '(Gidiş - Dönüş)'}</div>
                                <div style={{ fontWeight: '600' }}>€{totalPrice} <span style={{ fontSize: 15, color: '#666', fontWeight: '500' }}>(₺{totalPriceTL})</span></div>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            {current > 0 && (
                                <Col span={4} md={4} xs={6}>
                                    <Button
                                        icon={<ArrowLeftOutlined />}
                                        onClick={onPrev}
                                        size='large'
                                        className="w-100 summary-buttons"
                                    />
                                </Col>
                            )}

                            {current === 0 && (
                                <Col span={current == 0 ? 24 : 20} md={current == 0 ? 24 : 20}>
                                    <Button
                                        icon={<ArrowRightOutlined />}
                                        onClick={onNext}
                                        type='primary' size='large'
                                        className="w-100 summary-buttons"
                                    >
                                        Devam et
                                    </Button>
                                </Col>
                            )}

                            {current === 1 && (
                                <Col span={20} md={20} xs={18}>
                                    <Button
                                        onClick={payNow}
                                        type='primary'
                                        size='large'
                                        className="w-100 summary-buttons"
                                    >
                                        Ödeme Yap €{totalPrice}
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}