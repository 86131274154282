import React, { useEffect, useState } from "react";
import "../Login/Login.css";
import { Auth } from "../../services/Auth";
import { notification } from "antd";
import { Token } from "../../services/Token";
import { setItem, getItem } from "../../utils/storage";
import { Navigate, useNavigate } from "react-router-dom";

export const Login = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const userData = getItem("adminUser");
    console.log("userData", userData)
    if (userData) {
      Token().check({
        token: userData?.token
      }).then((response) => {
        // başarılıysa oturum açık demektir.
        if (response.data.status == "success") {
          navigate('/admin')
        } else {
          navigate('/admin/login')
        }
      }).catch((error) => {
        navigate('/admin/login')
      })
    }
  }, [])

  const submit = () => {
    const email = document.querySelector("#username").value;
    const password = document.querySelector("#password").value;

    Auth().login({
      email, password
    }).then((response) => {
      const { data } = response;
      if (data?.status == "success") {
        notification.success({
          message: 'Başarılı',
          description: 'Başarıyla giriş yapıldı!',
          placement: 'topRight'
        })
        setItem("adminUser", data.data)
        console.log("reservations", data);
        navigate('/admin')
      } else {
        console.log("hata", data?.message);
      }
    })
      .catch((error) => {
        notification.error({
          message: 'Hata',
          description: 'Bilgiler geçersiz!',
          placement: 'topRight'
        })
        console.log(error);

      });
  }
  return (
    <>
      <div className="background-container">
        <div className="login-container">
          <h2>Giriş</h2>
          <div className="input-group-login">
            <input type="text" id="username" name="username" placeholder="E-posta" required />
          </div>
          <div className="input-group-login">
            <input type="password" id="password" name="password" placeholder="Şifre" required />
          </div>
          <button type="button" className="login-btn" onClick={submit}>Giriş Yap</button>
        </div>
      </div>
    </>
  );
};
