import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "../../assets/images/logo.png"

const Navbar = () => {
  const [active, setActive] = useState(false);

  const toggleMenu = () => {
    setActive(!active);
  };

  const closeMenu = () => {
    setActive(false);
  };

  // Dışarı tıklamalarda menüyü kapat
  const navRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="Navbar" ref={navRef}>
      <Link to={"/"} className="nav-logo-link">
        <span className="nav-logo">
          <img src={logo} height={150} />
        </span>
      </Link>

      <div>
        <div className={`nav-items ${active && "switch"}`}>
          <Link to={"/bilet-al"} onClick={closeMenu} title="Bilet Al">
            Bilet Al
          </Link>
          <Link to={"/pnr-sorgula"} onClick={closeMenu} title="PNR Sorgula">
            PNR Sorgula
          </Link>
          <Link to={"/hakkimizda"} onClick={closeMenu} title="Hakkımızda">
            Hakkımızda
          </Link>
          <Link to={"/iletisim"} onClick={closeMenu} title="İletişim">
            İletişim
          </Link>
        </div>

        <div className={`nav-toggle ${active && "switch"}`} onClick={toggleMenu}>
          <div className="hamburger"></div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
