import axios from "axios";
import { SERVICE_URL } from "..";

export const Token = () => {
  // token kontrolü
  const check = (params) =>
    axios.get(`${SERVICE_URL}/CheckToken?token=${params.token}`);

  return {
    check,
  };
};
